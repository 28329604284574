import React from 'react';
import styled from 'styled-components';
import {Form, Input, DatePicker, Button} from 'antd';
import dayjs from 'dayjs';

export function PayBillingExtraQueries(props) {
  const {setQueryState, queryState} = props;
  const [payoffStartDate, setPayoffStartDate] = React.useState(null);
  const [payoffEndDate, setPayoffEndDate] = React.useState(null);
  const [createStartDate, setCreateStartDate] = React.useState(null);
  const [createEndDate, setCreateEndDate] = React.useState(null);

  return (
    <Wrapper>
      <div className="field">
        <span style={{marginRight: 10}}>給付日期</span>
        <DatePicker.RangePicker
          placeholder={['開始日期', '結束日期']}
          style={{marginRight: 10}}
          onChange={(dates, dateStrings) => {
            if (dateStrings[0]) {
              setPayoffStartDate(dateStrings[0]);
            } else {
              setPayoffStartDate(null);
            }

            if (dateStrings[1]) {
              setPayoffEndDate(dateStrings[1]);
            } else {
              setPayoffEndDate(null);
            }
          }}
        />
        <Button
          onClick={() => {
            if (!payoffStartDate || !payoffEndDate) {
              setQueryState((prev) => ({
                ...prev,
                extraQueries: {
                  ...prev.extraQueries,
                  payoff_date: undefined,
                },
              }));

              return;
            } else if (payoffStartDate && payoffEndDate) {
              const _extraQueries = {};
              _extraQueries['payoff_date'] = {
                $gte: payoffStartDate,
                $lte: payoffEndDate,
              };

              setQueryState((prev) => {
                const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};
                const filterOutQueryKeys = Object.keys(prev.extraQueries)
                  .map((q) => {
                    if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
                      return q;
                    } else {
                      return null;
                    }
                  })
                  .filter((k) => !!k);

                filterOutQueryKeys.forEach((k) => {
                  delete nextExtraQuery[k];
                });

                return {
                  ...prev,
                  extraQueries: nextExtraQuery,
                };
              });
            }
          }}>
          搜尋
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .field {
    display: flex;
    align-items: center;
  }

  & > .field > label {
    margin-right: 10px;
  }
`;
