import React from 'react';
import styled from 'styled-components';
import {Table, Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as OrderCustomUtil from '../../Utils/OrderCustomUtil';
import {COURSE_INTERNAL_LABELS} from '../../Utils/ProductUtil';
import AdminLinkButton from './AdminLinkButton';

export default function AdminOrderList(props) {
  const {context} = props;
  const {instance} = context;
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    if (instance.id) {
      async function fetchRecords(pid) {
        try {
          const checkouts = await JStorage.fetchDocuments(
            'checkout',
            {
              'items.0.product.id': {$oid: pid},
            },
            ['-created'],
            {offset: 0, limit: 10000},
            {
              order_id: 1,
              order_number: 1,
              buyer_name: 1,
              total: 1,
              payment_subtype: 1,
              created: 1,
            },
          );
          const resp = await OrderCustomUtil.mergeCheckoutJStorageRespWithOrder(
            checkouts,
            {order_id: 1, order_number: 1, buyer_name: 1, payment_status: 1},
          );

          setRecords(resp.results);
        } catch (err) {
          console.warn(err);
        }
      }

      fetchRecords(instance.id);
    }
  }, [instance.id]);

  return (
    <Wrapper>
      <h3 className="title">繳費明細</h3>
      <Table
        columns={[
          {
            title: '訂單編號 / 流水號',
            key: 'order_number',
            dataIndex: 'order_number',
            render: (_, r) => {
              const title = r.order_id ? `${r.order_id}` : `${r.order_number}*`;
              const url = OrderUtil.getAdminUrlFromMergedCheckout(r);

              const backPath = COURSE_INTERNAL_LABELS.includes(
                instance.internal_label,
              )
                ? '/admin/orders-course'
                : '/admin/orders-activity';

              return (
                <AdminLinkButton
                  title={title}
                  url={`${url}&backPath=${backPath}`}
                />
              );
            },
          },
          {
            title: '姓名',
            key: 'buyer_name',
            dataIndex: 'buyer_name',
          },
          {
            title: '應收',
            key: 'total',
            dataIndex: 'total',
          },
          {
            title: '實收',
            key: 'total',
            dataIndex: 'total',
            render: (_, r) => (r.payment_status === 'success' ? r.total : 0),
          },
          {
            title: '付款狀態',
            key: 'payment_status',
            dataIndex: 'payment_status',
            render: (_, r) =>
              r ? Cart.PAYMENT_STATUS_DISPLAY[r.payment_status]?.label : '',
          },
          {
            title: '付款方式',
            key: 'payment_subtype',
            dataIndex: 'payment_subtype',
            render: (_, r) =>
              r ? Cart.PAYMENT_SUBTYPE_DISPLAY[r.payment_subtype]?.label : '',
          },
          {
            title: '報名日期',
            key: 'created',
            dataIndex: 'created',
            render: (_, r) => new Date(r.created).toLocaleDateString('sv'),
          },
        ]}
        dataSource={records}
        pagination={{
          size: 'small',
          showTotal: (total) => `共 ${total} 筆`,
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > .title {
    margin-bottom: 20px;
  }

  & > .container {
  }
`;
