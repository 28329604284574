import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons';

function AdminFormNavigation(props) {
  const {context, path} = props;

  const _lineWidgets = Object.keys(context.formSpec.uiSchema).filter((key) => {
    return context.formSpec.uiSchema[key]['ui:widget'] === 'rev-line';
  });

  const _lineWidgetsByTopCustomFields = [
    {id: 'root_rev-line-system', title: '系統操作'},
    {id: 'root_rev-line-0', title: '基本資料'},
  ];

  const _lineWidgetsByBottomCustomFields = [
    {id: 'root_form-history', title: '簽核紀錄'},
    {id: 'root_form-history-execution', title: '執行紀錄'},
  ];

  if (path && path.indexOf('/admin/executions') > -1) {
    _lineWidgetsByBottomCustomFields.push({
      id: 'root_form-execution',
      title: '表單執行',
    });
  }

  return (
    <Wrapper>
      {_lineWidgetsByTopCustomFields.map((item) => {
        return (
          <Button
            type="text"
            onClick={() => {
              const element = document.getElementById(item.id);
              if (element) {
                const rect = element.getBoundingClientRect();
                window.scrollTo({top: rect.y - 20});
              }
            }}>
            {item.title}
          </Button>
        );
      })}
      {_lineWidgets.map((key) => {
        const title = context.formSpec.schema.properties[key]?.title || key;
        return (
          <Button
            type="text"
            onClick={() => {
              const element = document.getElementById(`root_${key}`);
              if (element) {
                const rect = element.getBoundingClientRect();
                window.scrollTo({top: rect.y - 20});
              }
            }}>
            {title}
          </Button>
        );
      })}
      {_lineWidgetsByBottomCustomFields.map((item) => {
        return (
          <Button
            type="text"
            onClick={() => {
              const element = document.getElementById(item.id);
              if (element) {
                const rect = element.getBoundingClientRect();
                window.scrollTo({top: rect.y - 20});
              }
            }}>
            {item.title}
          </Button>
        );
      })}

      <Button
        onClick={() => {
          window.scrollTo({top: 0});
        }}
        className="back-top-btn"
        size="large"
        shape="circle"
        icon={<ArrowUpOutlined style={{color: '#000000'}} />}
      />
      <Button
        onClick={() => {
          const element = document.getElementById('rev-admin-layout');

          if (element) {
            const rect = element.getBoundingClientRect();
            window.scrollTo({top: rect.height});
          }
        }}
        className="back-bottom-btn"
        size="large"
        shape="circle"
        icon={<ArrowDownOutlined style={{color: '#000000'}} />}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #f1f3f5;

  .back-top-btn {
    position: fixed;
    bottom: 120px;
    left: calc(1100px - 50px);
    z-index: 999;
    border: 1px solid var(--adminBorderColor);
    cursor: pointer;

    @media screen and (max-width: 1100px) {
      bottom: 200px;
      left: calc(100vw - 50px);
    }
  }

  .back-bottom-btn {
    position: fixed;
    bottom: 60px;
    left: calc(1100px - 50px);
    z-index: 999;
    border: 1px solid var(--adminBorderColor);
    cursor: pointer;

    @media screen and (max-width: 1100px) {
      bottom: 140px;
      left: calc(100vw - 50px);
    }
  }
`;

export default AdminFormNavigation;
