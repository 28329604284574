import React from 'react';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {
  initializeReviewStatus,
  appendStaffsProfile,
  appendTypeEmptyValue,
  checkIsRepeatTextNumber,
  updateReceiveCategoryAndRemoveAddText,
} from '../../Utils/FormUtil';
import permissionRequired from '../../Utils/PermissionRequiredUtil';
import AdminLineDivider from '../../Components/AdminLineDivider';
import {showAdminFormMoraleListEditModal} from './AdminFormMoraleListEditModal';

async function onSave({instance, values, extValues, required}) {
  AppActions.setLoading(true);

  try {
    //step1: generate jstorage data values
    let nextValues = appendTypeEmptyValue({
      prevValues: instance,
      currValues: appendStaffsProfile({
        ...values,
        extValues,
      }),
    });

    //step2: check values required
    const isRequired = required.every((r) => {
      return !!nextValues[r];
    });

    if (!isRequired) {
      message.error('請填寫必填欄位');
      AppActions.setLoading(false);
      return;
    }

    //step3: check values "text_number" is repeat or not
    const isRepeatTextNumber = await checkIsRepeatTextNumber({
      type: nextValues.type,
      text_number: nextValues.text_number,
      id: nextValues.id,
    });

    if (isRepeatTextNumber) {
      message.error('發文字號重複');
      AppActions.setLoading(false);
      return;
    }

    //step4: check values "category" have "add_text" or not
    nextValues = await updateReceiveCategoryAndRemoveAddText(nextValues);

    //step5: update values to jstorage
    await JStorage.updateDocument('form', {id: instance.id}, {...nextValues});

    message.success('儲存成功');
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function onSubmitReview({instance, values, extValues, required}) {
  AppActions.setLoading(true);

  try {
    //step1: generate jstorage data values
    let nextValues = appendTypeEmptyValue({
      prevValues: instance,
      currValues: appendStaffsProfile({
        ...values,
        extValues,
      }),
    });

    //step2: check values required
    const isRequired = required.every((r) => {
      return !!nextValues[r];
    });

    if (!isRequired) {
      message.error('請填寫必填欄位');
      AppActions.setLoading(false);
      return;
    }

    //step3: check values "text_number" is repeat or not
    const isRepeatTextNumber = await checkIsRepeatTextNumber({
      type: nextValues.type,
      text_number: nextValues.text_number,
      id: nextValues.id,
    });

    if (isRepeatTextNumber) {
      message.error('發文字號重複');
      AppActions.setLoading(false);
      return;
    }

    //step4: check values "category" have "add_text" or not
    nextValues = await updateReceiveCategoryAndRemoveAddText(nextValues);

    //step5: calc stage over value and stage is "review_1"
    const stage_over =
      (nextValues.review_1 || []).length +
        (nextValues.review_2 || []).length +
        (nextValues.review_3 || []).length +
        (nextValues.review_4 || []).length || 4;

    const stage = 'review_1';

    //step6: update values to jstorage
    await JStorage.updateDocument(
      'form',
      {id: instance.id},
      {...nextValues, stage_over, stage},
    );

    //step7: send form issue, go to review flow
    await AppActionsEx.createFormIssue({
      form_id: instance.id,
      stage_over,
      stage,
    });

    message.success('送出審核成功');
    AppActions.navigate(window.location.pathname);
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function onSubmitExecutionDirect({
  instance,
  values,
  extValues,
  required,
}) {
  AppActions.setLoading(true);

  try {
    //step1: generate jstorage data values
    let nextValues = appendTypeEmptyValue({
      prevValues: instance,
      currValues: appendStaffsProfile({
        ...values,
        extValues,
      }),
    });

    //step2: check values required
    const isRequired = required.every((r) => {
      return !!nextValues[r];
    });

    if (!isRequired) {
      message.error('請填寫必填欄位');
      AppActions.setLoading(false);
      return;
    }

    //step3: check values "text_number" is repeat or not
    const isRepeatTextNumber = await checkIsRepeatTextNumber({
      type: nextValues.type,
      text_number: nextValues.text_number,
      id: nextValues.id,
    });

    if (isRepeatTextNumber) {
      message.error('發文字號重複');
      AppActions.setLoading(false);
      return;
    }

    //step4: check values "category" have "add_text" or not
    nextValues = await updateReceiveCategoryAndRemoveAddText(nextValues);

    //step5: set stage over value 0 and stage is "execution_direct"
    const stage_over = 0;
    const stage = 'execution_direct';

    //step6: update values to jstorage
    await JStorage.updateDocument(
      'form',
      {id: instance.id},
      {...nextValues, stage_over, stage},
    );

    //step7: send form issue, go to execution_direct flow
    await AppActionsEx.createFormIssue({
      form_id: instance.id,
      stage_over,
      stage,
    });

    message.success('逕付執行成功');
    AppActions.navigate(window.location.pathname);
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function onConfirmEdit({instance, values, extValues}) {
  AppActions.setLoading(true);

  try {
    await JStorage.updateDocument(
      'form',
      {id: instance.id},
      {
        ...values,
        ...extValues,
        stage: 'edit',
        review_1: initializeReviewStatus(values, 'review_1'),
        review_2: initializeReviewStatus(values, 'review_2'),
        review_3: initializeReviewStatus(values, 'review_3'),
        review_4: initializeReviewStatus(values, 'review_4'),
      },
    );
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  }
  AppActions.setLoading(false);
  AppActions.navigate(window.location.pathname);
}

function AdminFormActions(props) {
  const [user] = useOutlet('user');
  const {context} = props;

  const required =
    context.values.category !== '新增'
      ? [...context.formSpec.schema.required]
      : [
          ...context.formSpec.schema.required,
          ...context.formSpec.schema.dependencies.category.oneOf[0].required,
        ];

  const isEditMode =
    !context.instance ||
    !context.instance.stage ||
    context.instance.stage === 'edit';

  const isAbortMode = context.instance && context.instance.stage === 'abort';

  const canSave = (() => {
    const STAFF_CAN_SAVE_LIST = [
      {types: ['post', 'receive'], staff: '6530ac4f584293a111c0cec6'}, //public
      {types: ['post', 'receive'], staff: '652f7a5bfcf2af09d28949ea'}, //潘靜怡
      {types: ['receive'], staff: '652f7e890e457dfb9b01a1f1'}, //陳俐蓁
    ];

    if (!context.instance) {
      return true;
    } else if (!context.instance.stage) {
      return true;
    } else if (
      context.instance.stage === 'edit' &&
      context.instance.author === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'edit' &&
      context.instance.implement === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution' &&
      context.instance.author === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution' &&
      context.instance.implement === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution' &&
      context.instance.executor === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution_direct' &&
      context.instance.author === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution_direct' &&
      context.instance.implement === user.username
    ) {
      return true;
    } else if (
      context.instance.stage === 'execution_direct' &&
      context.instance.executor === user.username
    ) {
      return true;
    } else if (
      (context.instance.stage === 'success' ||
        context.instance.stage === 'execution' ||
        context.instance.stage === 'execution_direct') &&
      STAFF_CAN_SAVE_LIST.some(
        (item) =>
          item.staff === user.username &&
          item.types?.some((t) => t === context.instance.type),
      )
    ) {
      return true;
    }

    return false;
  })();

  const canEditMoraleFields = (() => {
    return permissionRequired(
      `/admin/${context.instance.type}s/_write_morale_list`,
    );
  })();

  return (
    <Wrapper>
      <AdminLineDivider id="root_rev-line-system" title="系統操作" />

      <div className="container">
        {canEditMoraleFields && (
          <Button
            onClick={() =>
              showAdminFormMoraleListEditModal({
                instance: context.instance,
                values: context.values,
                extValues: context.extValues,
              })
            }>
            編輯倫理風紀欄位
          </Button>
        )}

        {canSave && (
          <Button
            style={{marginLeft: 15}}
            onClick={() =>
              onSave({
                instance: context.instance,
                values: context.values,
                extValues: context.extValues,
                required,
              })
            }>
            存檔
          </Button>
        )}

        {isEditMode && (
          <Popconfirm
            title="確認逕付執行？"
            okText="確認"
            cancelText="取消"
            onConfirm={() =>
              onSubmitExecutionDirect({
                instance: context.instance,
                values: context.values,
                extValues: context.extValues,
                required,
              })
            }>
            <Button style={{marginLeft: 15}}>逕付執行</Button>
          </Popconfirm>
        )}

        {isEditMode && (
          <Popconfirm
            title="確認送出簽核？"
            okText="確認"
            cancelText="取消"
            onConfirm={() =>
              onSubmitReview({
                instance: context.instance,
                values: context.values,
                extValues: context.extValues,
                required,
              })
            }>
            <Button type="primary" style={{marginLeft: 15}}>
              送出簽核
            </Button>
          </Popconfirm>
        )}

        {isAbortMode && (
          <Popconfirm
            title="確認開啟編輯模式？"
            okText="確認"
            cancelText="取消"
            onConfirm={() =>
              onConfirmEdit({
                instance: context.instance,
                values: context.values,
                extValues: context.extValues,
              })
            }>
            <Button>開啟編輯模式</Button>
          </Popconfirm>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    margin: 10px 0px;
    display: flex;
    justify-content: flex-end;
  }
`;

export default AdminFormActions;
