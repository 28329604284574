import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';

export default function AdminInsuranceField(props) {
  const {value, onChange, schema, formData} = props;

  return (
    <Wrapper>
      <div className="rev-row">
        <label>姓名</label>
        <input
          type="text"
          value={formData.name || ''}
          onChange={(e) => {
            onChange({...formData, name: e.target.value});
          }}
        />
        <label>身分證號</label>
        <input
          type="text"
          value={formData.id_card_number || ''}
          onChange={(e) => {
            onChange({...formData, id_card_number: e.target.value});
          }}
        />
      </div>
      <div className="rev-row">
        <label>身份</label>
        <select
          value={formData.type}
          onChange={(e) => {
            onChange({...formData, type: e.target.value});
          }}>
          <option value={''}>---</option>
          <option value={'member'}>會員</option>
          <option value={'adult'}>眷屬(大人)</option>
          <option value={'child'}>眷屬(小孩)</option>
        </select>
        <label>生日</label>
        <input
          type="date"
          value={formData.birthday}
          onChange={(e) => onChange({...formData, birthday: e.target.value})}
        />
        <label>外國籍</label>
        <input
          checked={formData.is_foreigner}
          type="checkbox"
          onChange={(e) => {
            onChange({...formData, is_foreigner: e.target.checked});
          }}
        />
      </div>
      <div className="rev-row">
        <label>保險受益人</label>
        <input
          type="text"
          value={formData.r_name || ''}
          onChange={(e) => {
            onChange({...formData, r_name: e.target.value});
          }}
        />
        <label>保險受益人身分證</label>
        <input
          type="text"
          value={formData.r_id_card_number || ''}
          onChange={(e) => {
            onChange({...formData, r_id_card_number: e.target.value});
          }}
        />
      </div>
      <div className="row">
        <label>保險受益人生日</label>
        <input
          type="date"
          value={formData.r_birthday || ''}
          onChange={(e) => {
            onChange({...formData, r_birthday: e.target.value});
          }}
        />
      </div>
      <div className="rev-row">
        <label>與受益人關係</label>
        <select
          value={formData.relationship}
          onChange={(e) => {
            onChange({...formData, relationship: e.target.value});
          }}>
          <option value={''}>---</option>
          <option value={'legal_heir'}>法定繼承人</option>
          <option value={'mate'}>配偶</option>
          <option value={'parent'}>父母</option>
          <option value={'child'}>子女</option>
          <option value={'grandparent'}>祖父母</option>
          <option value={'grandson'}>孫子</option>
        </select>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-bottom: 1px solid #ccc;

  .rev-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;

    & label {
      margin-right: 5px;
      &:not(first-child) {
        margin-left: 5px;
      }
    }

    & input[type='text'] {
      width: 100px;
    }
  }
`;
