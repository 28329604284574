import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import qs from 'query-string';
import {Button} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

const defaultQuery = {};

function parseWindowQuery() {
  if (typeof window !== 'undefined') {
    let params = qs.parse(window.location.search);
    let query = defaultQuery;

    try {
      query = JSON.parse(decodeURIComponent(params.query));
    } catch (err) {}

    return query;
  }
}

export function transformQueryElement(formData, users) {
  console.warn('DBG formData', formData);
  console.warn('DBG users', users);
  //案號前綴
  const caseNumberPrefix = !!formData.case_number_prefix && (
    <div>• 案號前綴：{formData.case_number_prefix}</div>
  );

  //案號
  const caseNumber = !!formData.case_number && (
    <div>• 案號：{formData.case_number}</div>
  );

  //案件類型
  const labels = (() => {
    if (Array.isArray(formData.labels) && formData.labels.length > 0) {
      let labelsText = ``;

      for (let i = 0; i < formData.labels.length; i++) {
        labelsText += `${formData.labels[i]}${
          i + 1 === formData.labels.length ? '' : '、'
        }`;
      }

      return <div>• 案件類型：{labelsText}</div>;
    }
  })();

  //受理日期（起）
  const startDateStart = !!formData.start_date_start && (
    <div>• 受理日期（起）：{formData.start_date_start}</div>
  );

  //受理日期（迄）
  const startDateEnd = !!formData.start_date_end && (
    <div>• 受理日期（迄）：{formData.start_date_end}</div>
  );

  //調查小組應提出調查報告期限（起）
  const investigatorReportDeadlineStart = !!formData.investigator_report_deadline_start && (
    <div>
      • 調查小組應提出調查報告期限（起）：
      {formData.investigator_report_deadline_start}
    </div>
  );

  //調查小組應提出調查報告期限（迄）
  const investigatorReportDeadlineEnd = !!formData.investigator_report_deadline_end && (
    <div>
      • 調查小組應提出調查報告期限（迄）：
      {formData.investigator_report_deadline_end}
    </div>
  );

  //延長後之調查報告提出期限（起）
  const investigatorReportDeadlineExtendStart = !!formData.investigator_report_deadline_extend_start && (
    <div>
      • 延長後之調查報告提出期限（起）：
      {formData.investigator_report_deadline_extend_start}
    </div>
  );

  //延長後之調查報告提出期限（迄）
  const investigatorReportDeadlineExtendEnd = !!formData.investigator_report_deadline_extend_end && (
    <div>
      • 延長後之調查報告提出期限（迄）：
      {formData.investigator_report_deadline_extend_end}
    </div>
  );

  //違紀行為時點（粗估供參）（起）
  const occurDateStart = !!formData.occur_date_start && (
    <div>• 違紀行為時點（粗估供參）（起）：{formData.occur_date_start}</div>
  );

  //違紀行為時點（粗估供參）（迄）
  const occurDateEnd = !!formData.occur_date_end && (
    <div>• 違紀行為時點（粗估供參）（迄）：${formData.occur_date_end}</div>
  );

  //結果通知日期（起）
  const endDateStart = !!formData.end_date_start && (
    <div>• 結果通知日期（起）：{formData.end_date_start}</div>
  );

  //結果通知日期（迄）
  const endDateEnd = !!formData.end_date_end && (
    <div>• 結果通知日期（迄）：${formData.end_date_end}</div>
  );

  //案件辦理進度
  const status = (() => {
    if (Array.isArray(formData.status) && formData.status.length > 0) {
      let statusText = '';

      for (let i = 0; i < formData.status.length; i++) {
        statusText += `${formData.status[i]}${
          i + 1 === formData.status.length ? '' : '、'
        }`;
      }

      return <div>• 辦理進度：{statusText}</div>;
    }
  })();

  //進度備註
  const statusNote = !!formData.status_note && (
    <div>• 進度備註：${formData.status_note.replace(/\n/g, ' ')}</div>
  );

  //調查小組類別
  const investigatorType = !!formData.investigator_type && (
    <div>• 調查小組類別：{formData.investigator_type}</div>
  );

  //申訴人（移送機關團體）（立案調查）
  const prosecutorName = !!formData.prosecutor_name && (
    <div>• 申訴人（移送機關團體）（立案調查）：{formData.prosecutor_name}</div>
  );

  //被申訴律師（受調查對象）
  const defendantName = !!formData.defendant_name && (
    <div>• 被申訴律師（受調查對象）：{formData.defendant_name}</div>
  );

  //調查小組：召集人
  const investigatorA = (() => {
    const targetUser = users.find(
      (user) => user.owner === formData.investigator_leader,
    );

    return (
      !!formData.investigator_leader && (
        <div>• 調查小組：召集人：{targetUser ? targetUser.name : ''}</div>
      )
    );
  })();

  //調查小組：調查委員
  const investigatorB = (() => {
    const targetUser = users.find(
      (user) => user.owner === formData.investigators_member,
    );

    return (
      !!formData.investigators_member && (
        <div>• 調查小組：調查委員：{targetUser ? targetUser.name : ''}</div>
      )
    );
  })();

  //處理結果
  const resultTba = !!formData.results_tba && (
    <div>• 本會處理結果：{formData.results_tba}</div>
  );

  //備註
  const note = !!formData.note && (
    <div>• 備註：${formData.note.replace(/\n/g, ' ')}</div>
  );

  return (
    <div>
      {caseNumberPrefix}
      {caseNumber}
      {labels}
      {prosecutorName}
      {defendantName}
      {investigatorA}
      {investigatorB}
      {startDateStart}
      {startDateEnd}
      {investigatorReportDeadlineStart}
      {investigatorReportDeadlineEnd}
      {investigatorReportDeadlineExtendStart}
      {investigatorReportDeadlineExtendEnd}
      {occurDateStart}
      {occurDateEnd}
      {endDateStart}
      {endDateEnd}
      {status}
      {statusNote}
      {investigatorType}
      {resultTba}
      {note}
    </div>
  );
}

export default function AdminMoraleSearchNotification(props) {
  const [users] = useOutlet('users');
  const [showContent, setShowContent] = React.useState(false);
  const queryParams = parseWindowQuery();
  const hasQueryParams = Object.keys(queryParams).length > 0;

  React.useEffect(() => {
    if (hasQueryParams) {
      setShowContent(true);
    }
  }, []);

  return (
    <Wrapper>
      {!showContent && hasQueryParams && (
        <Button className="btn" onClick={() => setShowContent(true)}>
          顯示
        </Button>
      )}
      {showContent && (
        <div className="fixed-container">
          <div className="close-btn" onClick={() => setShowContent(false)}>
            <CloseOutlined size={16} />
          </div>
          <h3 className="title">進階搜尋條件</h3>
          <div className="content">
            {transformQueryElement(queryParams, users)}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .btn {
    margin-left: 5px;
  }

  & > .fixed-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;

    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;

    max-width: 340px;
    width: 100%;

    & > .title {
      margin-bottom: 10px;
    }

    & > .close-btn {
      position: fixed;
      top: 40px;
      right: 40px;
      cursor: pointer;
    }
  }
`;
