import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {message} from 'antd';
import {getStaffValue} from '../../Utils/StaffUtil';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminSelectStaffWidget from './AdminSelectStaffWidget';
import AdminLineDivider from '../../Components/AdminLineDivider';

function AdminFormExecution(props) {
  const [user] = useOutlet('user');
  const {context} = props;

  const isNotExecutor = context.values.executor !== user.sub;

  const rjsfProps = {
    widgets: {
      'admin-select-staff-widget': AdminSelectStaffWidget,
    },
  };

  return isNotExecutor ? null : (
    <Wrapper>
      <AdminLineDivider title="表單執行" id="root_form-execution" />
      <GenericForm
        rjsfProps={rjsfProps}
        instance={context.instance}
        schema={{
          title: '',
          type: 'object',
          required: ['executor_status', 'note'],
          properties: {
            executor_status: {
              type: 'string',
              title: '您的執行狀態',
              default: 'pending',
              enum: ['pending', 'processed', 'cancel'],
              enumNames: ['待處理', '已處理', '已取消'],
            },
            next_executor: {
              type: 'string',
              title: '指定下位執行的工作人員',
            },
            none_executor: {
              type: 'boolean',
              title: '不需指定執行人',
              default: false,
            },
            note: {
              type: 'string',
              title: '您的備註',
            },
          },
          dependencies: {
            none_executor: {
              oneOf: [
                {
                  required: ['next_executor'],
                  properties: {none_executor: {enum: [false]}},
                },
              ],
            },
          },
        }}
        uiSchema={{
          executor_status: {
            'ui:help':
              '已處理：表示相關內容已執行。已取消：表示相關內容非本人執行，可指回上位執行人，或下位執行人。',
          },
          next_executor: {
            'ui:widget': 'admin-select-staff-widget',
          },
          none_executor: {
            'ui:help': '若未指定執行人，收發文狀態將切為「成功」。',
          },
          note: {
            'ui:widget': 'textarea',
          },
        }}
        onSubmit={async (formData, extValues) => {
          const {
            executor_status,
            note,
            next_executor,
            none_executor,
          } = formData;
          const id = context.instance.id;

          if (executor_status === 'pending') {
            message.warning('執行狀態不可為待處裡。');
            AppActions.setLoading(false);
            return;
          }

          AppActions.setLoading(true);

          try {
            if (none_executor) {
              await AppActionsEx.updateFormExecution({
                form_id: id,
                executor_status,
                note,
                next_executor: '',
              });
            } else {
              await AppActionsEx.updateFormExecution({
                form_id: id,
                executor_status,
                note,
                next_executor,
              });
            }
            message.success('表單執行成功送出');
          } catch (err) {
            console.warn(err);
            message.error('表單執行發生錯誤');
            AppActions.setLoading(false);
          }
          AppActions.setLoading(false);
          AppActions.navigate(window.location.pathname);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 50px;

  & > .field-item {
    margin-bottom: 24px;
    & > div {
      padding-bottom: 8px;
    }
  }
`;

export default AdminFormExecution;
