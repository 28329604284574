import React from 'react';
import styled from 'styled-components';
import {Select} from 'antd';
import {getOutlet} from 'reconnect.js';

const SchoolsCategoryDisplayMapOutlet = getOutlet('schoolCategoryDisplayMap');

export default function AdminSelectSchoolWidget(props) {
  return (
    <Wrapper>
      <Select
        style={{width: '100%'}}
        allowClear
        placeholder="請選擇"
        mode="multiple"
        options={Object.keys(SchoolsCategoryDisplayMapOutlet.getValue()).map(
          (school) => ({
            label: school,
            value: school,
          }),
        )}
        value={typeof props.value !== 'undefined' ? [props.value] : []}
        onChange={(value) => {
          props.onChange(value[value.length - 1]);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
