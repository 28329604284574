import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import VideoUploadModal from '../../Components/VideoUploadModal';

export default function AdminVideoUploadWidget(props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Wrapper>
      <div>{!props.value ? '尚未設定' : props.value}</div>
      <Button
        onClick={() => {
          props.onChange('');
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button type="primary" onClick={() => setShowModal(true)}>
        上傳新影片
      </Button>

      <VideoUploadModal
        visible={showModal}
        setVisible={setShowModal}
        onChange={props.onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
