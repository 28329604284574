import React from 'react';
import styled from 'styled-components';
import {Button, Popconfirm, message} from 'antd';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';

function AdminUserBillingsCreateActions(props) {
  const {context} = props;
  const {values, setValues, setExtValues} = context;
  const [showCreateButton, setShowCreateButton] = React.useState(false);

  return (
    <Wrapper>
      <Button
        style={{marginRight: showCreateButton ? 15 : 0}}
        onClick={async () => {
          try {
            message.info('正在計算');
            AppActions.setLoading(true);
            setShowCreateButton(false);

            const resp = await AppActionsEx.calcAnnualFeeByUser({
              annual_year: values.annual_year,
              user_id: values.user_id,
              product_name: values.product_name,
              start_month: values.start_month,
              end_month: values.end_month,
            });

            setValues({
              ...values,
              price: resp.total,
              discount_items: resp.discount_items,
            });

            setShowCreateButton(true);

            message.success('完成計算');
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
            setShowCreateButton(false);
          } finally {
            AppActions.setLoading(false);
          }
        }}>
        自動計算
      </Button>
      {showCreateButton && (
        <Popconfirm
          title={`確認建立嗎？`}
          okText="確認"
          cancelText="取消"
          onCancel={() => 0}
          onConfirm={async () => {
            try {
              message.info('正在創建');

              await AppActionsEx.createAnnualFeeByUser({
                annual_year: values.annual_year,
                user_id: values.user_id,
                product_name: values.product_name,
                price: values.price,
                start_month: values.start_month,
                end_month: values.end_month,
                staff_note: values.staff_note || '',
              });

              message.success('創建成功');
              AppActions.navigate('/admin/user-billings');
            } catch (err) {
              console.warn(err);
              if (err.status === 433) {
                message.error(`${err.response.error}`);
              } else {
                message.error('發生錯誤');
              }
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          <Button type="primary">確認建立</Button>
        </Popconfirm>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;

export default AdminUserBillingsCreateActions;
