import React from 'react';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
const qs = require('query-string');

const ArticlesOutlet = getOutlet('articles');
const CommitteeSummariesOutlet = getOutlet('committeeSummaries');
const ReceivesOutlet = getOutlet('receives');

export default function useAdminResourceInit({location}) {
  const params = qs.parse(location.search) || {};

  React.useEffect(() => {
    // trigget only once
  }, []);

  React.useEffect(() => {
    // trigger when location change

    async function fetchAllReceives() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'form',
          {
            type: 'receive',
          },
          null,
          {
            id: 1,
            paperwork_id: 1,
          },
        );

        ReceivesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    async function fetchAllCommitteeSummaries() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'Committee_Summary',
          {},
          null,
          {
            id: 1,
            NAME: 1,
          },
        );

        CommitteeSummariesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    if (location.pathname.indexOf('/admin/posts') > -1) {
      fetchAllReceives();
    } else if (location.pathname.indexOf('/admin/committees') > -1) {
      fetchAllCommitteeSummaries();
    }
  }, [location]);

  React.useEffect(() => {
    // trigger when location change and list to detail

    if (location.pathname === '/admin/pages/' && params.action === 'detail') {
      setTimeout(() => {
        // to fix
        const submitBtns = document.querySelector('.submit-buttons-bar');
        if (submitBtns) {
          submitBtns.style.position = 'sticky';
          submitBtns.style.bottom = '62px';
          submitBtns.children[0].style.boxShadow =
            '0px 0px 6px 2px var(--primaryColor)';
        }
      }, 1000);
    }
  }, [location, params?.action]);

  return {
    extraElement: null,
  };
}
