import React from 'react';
import styled from 'styled-components';
import {Button, Popconfirm, message} from 'antd';
import {getOutlet, useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import DataJson from '../../../data.json';
import AdminLineDivider from '../../Components/AdminLineDivider';
import {showInsuranceEditModal} from '../../Components/InsuranceEditModal';
import {showVegetarianEditModal} from '../../Components/VegetarianEditModal';
import {showAdminOrderSplitModal} from '../../Components/AdminOrderSplitModal';
import {showAdminOrderCrossDateModal} from '../../Components/AdminOrderCrossDateModal';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import permissionRequired from '../../Utils/PermissionRequiredUtil';

const ACCESS_LIST = [
  '63aea7e98ae07b4d5d89cc6f', //Admin
  '652f781afcf2af09d28949e5', //李淑蘋
];

function canDeleteAnnualFeeOrder(instance, user) {
  if (ACCESS_LIST.includes(user.id)) {
    return true;
  }

  if (!permissionRequired('/admin/checkout/_write_delete')) {
    return false;
  }

  if (!permissionRequired('/admin/order/_write_delete')) {
    return false;
  }

  if (instance.admin_creator !== user.id) {
    return false;
  }

  if (instance.payment_status === 'success') {
    return false;
  }

  return true;
}

function AdminOrderCustomActions(props) {
  const [user] = useOutlet('user');
  const {
    path,
    context: {instance, values, setValues},
  } = props;

  return (
    <Wrapper id="root_custom-system-actions">
      <AdminLineDivider title="系統操作" />

      <div className="container">
        <Button
          icon={<MinusOutlined />}
          disabled={!!instance.order_id}
          onClick={() =>
            getOutlet('AdminOrderModifyPriceModal').update({
              type: 'discount',
              instance,
            })
          }>
          調整金額減項
        </Button>

        <Button
          icon={<PlusOutlined />}
          disabled={!!instance.order_id}
          onClick={() =>
            getOutlet('AdminOrderModifyPriceModal').update({
              type: 'fee',
              instance,
            })
          }>
          調整金額加項
        </Button>

        {(instance.payment_subtype === 'barcode' ||
          instance.payment_subtype === 'cvs') && (
          <Button
            disabled={instance.payment_status !== 'code_generated'}
            target="_blank"
            href={`/print-preview?collection=order&id=${instance.id}&type=payment-code`}>
            列印繳費條碼
          </Button>
        )}

        <Button
          onClick={() => {
            if (
              path.indexOf('checkout') > -1 &&
              !permissionRequired('/admin/checkout/_write_reassign')
            ) {
              message.error('您無此權限');
              return;
            }

            if (
              path.indexOf('order') > -1 &&
              !permissionRequired('/admin/order/_write_reassign')
            ) {
              message.error('您無此權限');
              return;
            }

            getOutlet('AdminReassignOrderModal').update({instance});
          }}>
          線下付款
        </Button>

        {instance.payment_status !== 'success' &&
          instance.items[0].product.name.indexOf('常年會費') > -1 && (
            <Button
              onClick={() => {
                showAdminOrderSplitModal({order: instance});
              }}>
              拆成兩筆
            </Button>
          )}

        {instance.payment_status !== 'success' &&
          instance.items[0].product.name.indexOf('常年會費') > -1 && (
            <Popconfirm
              title="確認設定為免繳？"
              okText="確認"
              cancelText="取消"
              onCancel={() => 0}
              onConfirm={async () => {
                try {
                  AppActions.setLoading(true);
                  await AppActionsEx.adminPostAnnualFeeFree({
                    order_number: instance.order_number,
                  });

                  message.success('設定免繳成功，請重新整理');
                } catch (err) {
                  console.warn(err);
                  message.error('發生錯誤');
                } finally {
                  AppActions.setLoading(false);
                }
              }}>
              <Button>設定免繳</Button>
            </Popconfirm>
          )}

        {instance.items[0].product.name.indexOf('跨區會費') > -1 &&
          (!instance.items[0].config.extra_data?.start_date ||
            !instance.items[0].config.extra_data?.end_date) && (
            <Button
              onClick={() => {
                showAdminOrderCrossDateModal({order: instance});
              }}>
              填寫跨區起迄
            </Button>
          )}

        <Button
          disabled={instance.payment_status !== Cart.PAYMENT_STATUS.success}
          target="_blank"
          href={
            path.indexOf('checkout') > -1
              ? `/print-preview?collection=checkout&id=${instance.id}&type=receipt`
              : `/print-preview?collection=order&id=${instance.id}&type=receipt`
          }>
          開立收據
        </Button>

        {instance.order_id &&
        instance.items[0].product.is_required_insurance ? (
          <Button
            onClick={() =>
              showInsuranceEditModal({order: instance, isAdmin: true})
            }>
            填寫保險資料
          </Button>
        ) : null}

        {instance.order_id &&
        instance.items[0].product.is_required_vegetarian ? (
          <Button
            onClick={() =>
              showVegetarianEditModal({order: instance, isAdmin: true})
            }>
            填寫葷素資料
          </Button>
        ) : null}

        <Popconfirm
          title="確認「取消」訂單嗎?"
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              await ApiUtil.req(
                `${DataJson.apiHost}/order/cancel?token=${
                  getOutlet('user').getValue().token
                }`,
                {
                  method: 'POST',
                  data: {
                    order_number: instance.order_number,
                  },
                },
              );

              message.success('已成功取消訂單');
              setTimeout(() => {
                window.location.reload();
              }, 800);
            } catch (ex) {
              message.error('發生錯誤');
              console.warn(ex);
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          <Button
            danger
            type="primary"
            ghost
            disabled={instance.status === 'canceled'}>
            取消訂單
          </Button>
        </Popconfirm>

        {instance.items[0].product.name.indexOf('常年會費') > -1 && (
          <Popconfirm
            title="確認刪除訂單？"
            okText="確認"
            cancelText="取消"
            disabled={!canDeleteAnnualFeeOrder(instance, user)}
            onConfirm={async () => {
              const canDelete = canDeleteAnnualFeeOrder(instance, user);

              if (canDelete) {
                try {
                  AppActions.setLoading(true);

                  await JStorage.deleteDocument('checkout', {
                    order_number: instance.order_number,
                  });

                  const orderResp = await JStorage.fetchDocuments('order', {
                    order_number: instance.order_number,
                  });

                  if (orderResp.total > 0) {
                    await JStorage.deleteDocument('order', {
                      order_number: orderResp.results[0].order_number,
                    });
                  }

                  message.success('刪除成功');

                  setTimeout(() => {
                    AppActions.navigate('/admin/user-billings');
                  }, 300);
                } catch (ex) {
                  message.error('發生錯誤');
                  console.warn(ex);
                } finally {
                  AppActions.setLoading(false);
                }
              }
            }}>
            <Button
              type="primary"
              danger
              disabled={!canDeleteAnnualFeeOrder(instance, user)}>
              刪除訂單
            </Button>
          </Popconfirm>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  & > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-btn {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
  }

  & > .title {
    margin-bottom: 10px;
  }
`;

export default AdminOrderCustomActions;
