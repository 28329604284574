import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import AdminLineDivider from '../../Components/AdminLineDivider';
import permissionRequired from '../../Utils/PermissionRequiredUtil';
import {showAdminStaffPasswordResetModal} from '../../Components/AdminStaffPasswordResetModal';

function AdminStaffCustomActions(props) {
  const {context} = props;

  return (
    <Wrapper id="root_custom-system-actions">
      <AdminLineDivider title="系統操作" />
      <div className="container">
        <Button
          type="primary"
          ghost
          onClick={() => showAdminStaffPasswordResetModal({context})}>
          重設密碼
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  & > .title {
    margin-bottom: 10px;
  }

  & > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-btn {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
  }
`;

export default AdminStaffCustomActions;
