import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getOutlet} from 'reconnect.js';
import {message} from 'antd';
import {getStaffValue} from './StaffUtil';

const ConfigOutlet = getOutlet('config');

export function initializeReviewStatus(values, key) {
  if (values[key] && values[key].length > 0) {
    return values[key].map((record) => ({
      ...record,
      status: 'pending',
    }));
  }

  return [];
}

export function appendStaffsProfile(currentValues) {
  let nextValues = {...currentValues};

  if (Array.isArray(nextValues.execution) && nextValues.execution.length > 0) {
    nextValues.execution = nextValues.execution.map((e) => {
      return {
        ...e,
        role: getStaffValue(e.user_id, 'role'),
        group: getStaffValue(e.user_id, 'group'),
        name: getStaffValue(e.user_id, 'name'),
      };
    });
  }

  if (nextValues.author) {
    nextValues.author_group = getStaffValue(nextValues.author, 'group');
    nextValues.author_role = getStaffValue(nextValues.author, 'role');
    nextValues.author_name = getStaffValue(nextValues.author, 'name');
  }

  if (nextValues.implement) {
    nextValues.implement_group = getStaffValue(nextValues.implement, 'group');
    nextValues.implement_role = getStaffValue(nextValues.implement, 'role');
    nextValues.implement_name = getStaffValue(nextValues.implement, 'name');
  }

  return nextValues;
}

export function appendTypeEmptyValue({prevValues, currValues}) {
  if (!prevValues) {
    return {...currValues};
  }

  let nextValues = {};
  for (let property in currValues) {
    if (
      currValues[property] !== prevValues[property] &&
      typeof currValues[property] === 'undefined' &&
      typeof prevValues[property] === 'string'
    ) {
      currValues[property] = '';
    }
  }

  return {...currValues, ...nextValues};
}

export async function checkIsRepeatTextNumber({type, text_number, id}) {
  if (type === 'post' && text_number) {
    try {
      const resp = await JStorage.fetchAllDocuments('form', {
        type,
        text_number,
      });

      if (Array.isArray(resp) && resp.length > 0) {
        return resp[0].id !== id;
      }

      return false;
    } catch (err) {
      console.warn(err);
    }
  }

  return false;
}

export async function updateReceiveCategoryAndRemoveAddText(currentValues) {
  if (currentValues.type === 'receive') {
    const receiveCategory = ConfigOutlet.getValue().receive_category || [];
    const rcIdx = receiveCategory.findIndex(
      (c) => c === currentValues.category,
    );

    if (rcIdx === -1) {
      try {
        ConfigOutlet.update(
          await JStorage.updateDocument(
            'site',
            {name: 'config'},
            {
              receive_category: [
                ...receiveCategory,
                currentValues.category_add_text,
              ],
            },
          ),
        );

        currentValues.category = currentValues.category_add_text;
        delete currentValues.category_add_text;
        message.success('收文類別新增成功');

        return currentValues;
      } catch (err) {
        console.warn(err);
        message.error('收文類別新增發生錯誤');
      }
    }
  }

  return currentValues;
}
