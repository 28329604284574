import React from 'react';
import styled from 'styled-components';
import AdminNewsUrlCopyButton from './AdminNewsUrlCopyButton';

function AdminNewsCustomActions(props) {
  return (
    <Wrapper>
      <AdminNewsUrlCopyButton {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
`;

export default AdminNewsCustomActions;
