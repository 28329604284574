import React from 'react';
import {Button} from 'antd';

export default function AdminProductPayPreviewButton(props) {
  const {path, context, style} = props;

  return (
    <Button
      type="link"
      target="_blank"
      href={`/print-preview?collection=product&id=${context.instance.id}&type=pay`}
      style={{
        border: '1px solid var(--primaryColor)',
        cursor: 'pointer',
        ':active': {border: '1px solid var(--primaryColor);'},
        ...style,
      }}
      rel="noreferrer">
      列印勞務報酬簽收單
    </Button>
  );
}
