import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {Table} from 'antd';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';

function generateExistFirstYearsArray() {
  const firstYear = 112;
  const years = [];

  years.push(`${firstYear}`);
  const currentYear = new Date().getFullYear() - 1911;

  if (currentYear > firstYear) {
    for (let i = 0; i < currentYear - firstYear; i++) {
      years.push(`${firstYear + i + 1}`);
    }
  }

  return years;
}

const LEGACY_TABS = {
  fee: {
    label: '會費紀錄',
    columns: [
      {
        title: '年度',
        dataIndex: 'FEE_YEAR',
        key: 'FEE_YEAR',
        width: 100,
        fixed: 'left',
      },
      {
        title: '起',
        dataIndex: 'BMONTH',
        key: 'BMONTH',
      },
      {
        title: '訖',
        dataIndex: 'EMONTH',
        key: 'EMONTH',
      },
      {
        title: '繳費狀況',
        dataIndex: 'PAY_STATUS',
        key: 'PAY_STATUS',
        render: (_, record) => {
          function getLabel() {
            if (record.PAY_STATUS === 'N') {
              return record.REFUND !== 0 ? '退款' : '未繳';
            } else if (record.PAY_STATUS === 'S') {
              return '停繳';
            } else if (record.PAY_STATUS === 'Y') {
              return '已繳';
            } else if (record.PAY_STATUS === 'F') {
              return '免繳';
            } else {
              return '未繳';
            }
          }
          return <div style={{color: 'red'}}>{getLabel()}</div>;
        },
      },
      {
        title: '應收',
        dataIndex: 'RECEIVABLE',
        key: 'RECEIVABLE',
      },
      {
        title: '收款日',
        dataIndex: 'RECEIVE_DATE',
        key: 'RECEIVE_DATE',
        render: (_, record) => {
          if (record.PAY_STATUS === 'Y') {
            return <div>{record.RECEIVE_DATE?.split('T')[0]}</div>;
          } else if (record.PAY_STATUS === 'S') {
            return <div>{record.STOP_DATE?.split('T')[0]}</div>;
          } else {
            return '---';
          }
        },
      },
    ],
  },
  courses: {
    label: '參與課程紀錄',
    columns: [
      {
        title: '課程編號',
        dataIndex: 'class_no',
        key: 'class_no',
        width: 50,
        fixed: 'left',
      },
      {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          if (it._type === 'ecourse') {
            return '影音課程';
          }
          return it.codebase?.CODE_NAME || it.type_code;
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (it.complete_time || '').slice(0, 10);
        },
      },
      {
        title: '是否出席',
        key: 'attend',
        render: (_, it) => {
          if (it._type === 'ecourse') {
            return it.temp_time ? '是' : '否';
          }
          return it.is_present === 'Y' ? '是' : '否';
        },
      },
      {
        title: '核給點數',
        dataIndex: 'get_clocks',
        key: 'get_clocks',
      },
      {
        title: '使用免費點數',
        key: 'use_free',
        render: (_, it) => {
          return it.use_free === 'Y' ? '是' : '否';
        },
      },
      {
        title: '繳費狀況',
        dataIndex: 'pay_progress',
        key: 'pay_progress',
        render: (_, record) => {
          const valueMap = {
            30: '未繳',
            50: '少繳',
            90: '已繳',
            100: '免費',
            105: '自選免費',
            120: '作廢',
            160: '授權失敗',
            165: '逾期取消',
            200: '退款取消',
            0: '',
          };

          return (
            <div style={{color: 'red'}}>
              {valueMap[record.pay_progress] || '---'}
            </div>
          );
        },
      },
    ],
  },
  activities: {
    label: '參與活動紀錄',
    columns: [
      {
        title: '活動編號',
        dataIndex: 'activity_id',
        key: 'activity_id',
        width: 50,
        fixed: 'left',
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          return it.codebase?.CODE_NAME || '';
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (
            <>
              <div>{(it.start_date || '').slice(0, 10)}</div>
              <div>{it.start_time || ''}</div>
            </>
          );
        },
      },
      {
        title: '繳費狀況',
        dataIndex: 'pay_progress',
        key: 'pay_progress',
        render: (_, record) => {
          const valueMap = {
            30: '未繳',
            50: '少繳',
            90: '已繳',
            100: '免費',
            105: '自選免費',
            120: '作廢',
            160: '授權失敗',
            165: '逾期取消',
            200: '退款取消',
            0: '',
          };

          return (
            <div style={{color: 'red'}}>
              {valueMap[record.pay_progress] || '---'}
            </div>
          );
        },
      },
    ],
  },
  meetings: {
    label: '參與會議紀錄',
    columns: [
      {
        title: '會議名稱',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (
            <>
              <div>{(it.start_date || '').slice(0, 10)}</div>
              <div>{it.start_time || ''}</div>
            </>
          );
        },
      },
      {
        title: '地點',
        key: 'location',
        dataIndex: 'location',
      },
    ],
  },
  teaches: {
    label: '授課紀錄',
    columns: [
      {
        title: '課程編號',
        dataIndex: 'class_no',
        key: 'class_no',
        width: 50,
        fixed: 'left',
      },
      {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          return it.codebase?.CODE_NAME || it.type_code;
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (it.session_time || '').slice(0, 10);
        },
      },
      {
        title: '核給點數',
        dataIndex: 'get_clocks',
        key: 'get_clocks',
      },
    ],
  },
};

const TABS = {
  courses: {
    label: '參與課程紀錄',
    columns: [
      {
        title: '場次時間',
        key: 'session',
        dataIndex: 'session',
        width: 150,
        render: (_, r) => {
          const isSession =
            r.session && Object.keys(r.session).length > 0 && r.session.date;

          const sessionText = isSession
            ? `${r.session.date}\n ${
                r.session.start_time
                  ? `${r.session.start_time}-${r.session.end_time}`
                  : ``
              }`
            : `線上自由安排`;

          return <div>{sessionText}</div>;
        },
      },
      {
        title: '編號',
        name: 'serial_number',
        dataIndex: 'serial_number',
        render: (_, r) => <div>{r.serial_number || '---'}</div>,
      },
      {
        title: '名稱',
        key: 'name',
        dataIndex: 'name',
        width: 350,
      },
      {
        title: '核給時數',
        key: 'tba_hours',
        dataIndex: 'tba_hours',
        render: (_, r) => <div>{r.tba_hours} 小時</div>,
      },
    ],
  },
  activities: {
    label: '參與活動紀錄',
    columns: [
      {
        title: '場次時間',
        key: 'session',
        dataIndex: 'session',
        width: 200,
        render: (_, r) => {
          const isSession =
            r.session && Object.keys(r.session).length > 0 && r.session.date;

          const sessionText = isSession
            ? `${r.session.date}\n ${
                r.session.start_time
                  ? `${r.session.start_time}-${r.session.end_time}`
                  : ``
              }`
            : `線上自由安排`;

          return <div>{sessionText}</div>;
        },
      },
      {
        title: '名稱',
        key: 'name',
        dataIndex: 'name',
        width: 350,
      },
      {
        title: '核給時數',
        key: 'tba_hours',
        dataIndex: 'tba_hours',
        render: (_, r) => <div>{r.tba_hours} 小時</div>,
      },
    ],
  },
  meetings: {
    label: '參與會議紀錄',
    columns: [
      {
        title: '名稱',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '會議日期',
        key: 'date',
        dataIndex: 'date',
      },
      {
        title: '會議時間',
        key: 'time',
        dataIndex: 'time',
      },
      {
        title: '會議地點',
        key: 'room',
        dataIndex: 'room',
      },
    ],
  },
  lectures: {
    label: '授課紀錄',
    columns: [
      {
        title: '名稱',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '分類',
        key: 'labels',
        dataIndex: 'labels',
      },
      {
        title: '核給時數',
        key: 'tba_hours',
        dataIndex: 'tba_hours',
      },
    ],
  },
};

function AdminUserAttendList(props) {
  const {path, context} = props;
  const {instance} = context;
  const [records, setRecords] = React.useState([]);

  const hasWindowObj = typeof window !== 'undefined';
  const hasLegacyMemberID = instance && instance.ID;
  const hasUserOwner = instance.owner;

  const isLegacy = hasWindowObj
    ? (() => {
        const {isLegacy} = qs.parse(window.location.search);
        return isLegacy === 'true';
      })()
    : false;
  const tab = hasWindowObj
    ? (() => {
        const {tab} = qs.parse(window.location.search);
        return tab;
      })()
    : '';
  const tabInfo = isLegacy ? LEGACY_TABS[tab] : TABS[tab];

  const fetchLegacyData = React.useCallback(async ({tab, memberId}) => {
    AppActions.setLoading(true);

    try {
      if (tab === 'fee') {
        const resp = await JStorage.fetchAllDocuments(
          'Annual_Fee',
          {
            MBR_ID: memberId,
            // PAY_STATUS: {$nin: ['S', 'N']},
          },
          ['-FEE_YEAR', '-BMONTH'],
        );

        setRecords(resp);
      } else if (tab === 'courses') {
        const courseResp = await JStorage.fetchAllDocuments('Course_History', {
          member_id: memberId,
        });
        const ecourseResp = await JStorage.fetchAllDocuments(
          'ECourse_History',
          {
            member_id: memberId,
          },
        );
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});

        const results = [
          ...courseResp.map((c) => ({_type: 'course', ...c})),
          ...ecourseResp.map((c) => ({_type: 'ecourse', ...c})),
        ]
          .map((c) => {
            const extras = {};

            if (c._type === 'ecourse') {
              extras.complete_time =
                c.complete_time1 || c.complete_time2 || c.complete_time3;
            }

            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);

            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.complete_time > b.complete_time) {
              return -1;
            } else if (a.complete_time < b.complete_time) {
              return 1;
            }
            return 0;
          });

        setRecords(results);
      } else if (tab === 'activities') {
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});
        const results = (
          await JStorage.fetchAllDocuments('Activity_History', {
            member_id: memberId,
          })
        )
          .map((c) => {
            const extras = {};
            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);

            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.start_date > b.start_date) {
              return -1;
            } else if (a.start_date < b.start_date) {
              return 1;
            }
            return 0;
          });
        setRecords(results);
      } else if (tab === 'meetings') {
        const results = (
          await JStorage.fetchAllDocuments('Meeting_History', {
            member_id: memberId,
          })
        ).sort((a, b) => {
          if (a.start_date > b.start_date) {
            return -1;
          } else if (a.start_date < b.start_date) {
            return 1;
          }
          return 0;
        });
        setRecords(results);
      } else if (tab === 'teaches') {
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});
        const results = (
          await JStorage.fetchAllDocuments('Lecture_History', {
            member_id: memberId,
          })
        )
          .map((c) => {
            const extras = {};
            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);
            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.session_time > b.session_time) {
              return -1;
            } else if (a.session_time < b.session_time) {
              return 1;
            }
            return 0;
          });
        setRecords(results);
      }
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  const fetchData = React.useCallback(async ({tab, owner}) => {
    AppActions.setLoading(true);
    try {
      if (tab === 'meetings') {
        const {results} = await JStorage.fetchDocuments(
          'Meeting',
          {
            users: {$in: [{user: owner}]},
          },
          ['-created'],
          {
            //TODO: support pagination
            offset: 0,
            limit: 1000,
          },
        );

        setRecords(results);
      } else {
        let {joins, lectures} = await AppActionsEx.adminGetUserJoinRecords({
          user_id: owner,
          first_years: generateExistFirstYearsArray(),
        });

        joins = joins.map((it) => {
          return {
            id: it._id.$oid,
            ...it,
          };
        });

        if (tab === 'courses') {
          setRecords(
            joins.filter((it) => {
              for (const l of it.labels || []) {
                if (l.indexOf('課程') > -1) {
                  return true;
                }
              }

              if (it.internal_label === '在職進修') {
                return true;
              }

              return false;
            }),
          );
        } else if (tab === 'activities') {
          setRecords(
            joins.filter((it) => {
              for (const l of it.labels || []) {
                if (l.indexOf('活動') > -1) {
                  return true;
                }
              }
              return false;
            }),
          );
        } else if (tab === 'lectures') {
          lectures = lectures.map((it) => {
            return {
              id: it._id.$oid,
              ...it,
            };
          });
          setRecords(lectures);
        }
      }
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (isLegacy && hasWindowObj && hasLegacyMemberID) {
      fetchLegacyData({tab, memberId: instance.ID});
    }

    if (!isLegacy && hasWindowObj && hasUserOwner) {
      fetchData({tab, owner: instance.owner});
    }
  }, [
    fetchLegacyData,
    fetchData,
    hasLegacyMemberID,
    hasWindowObj,
    hasUserOwner,
    isLegacy,
    tab,
    instance?.ID,
    instance?.owner,
  ]);

  return (
    <Wrapper>
      <h3 className="title">
        {isLegacy ? `112年前${tabInfo.label}` : tabInfo.label}
      </h3>

      <div className="container">
        <Table
          key={tab}
          columns={tabInfo?.columns}
          dataSource={records}
          pagination={{
            total: (records || []).length,
            showTotal: (total) => `共 ${total} 筆`,
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > .title {
    margin-bottom: 20px;
  }

  & > .container {
  }
`;

export default AdminUserAttendList;
