import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Table, Input, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {showAdminSearchIntExcModal} from '../../Components/AdminSearchIntExcModal';

export default function AdminSelectIntExcWidget(props) {
  const [selectedIntExc, setSelectedIntExc] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    const fetchRecordById = async (id) => {
      setFetching(true);

      try {
        setSelectedIntExc(
          await JStorage.fetchOneDocument('international_exchange', {
            id,
          }),
        );
      } catch (err) {
        console.warn(err);
      }

      setFetching(false);
    };

    if (props.value) {
      fetchRecordById(props.value);
    }
  }, [props.value]);

  return (
    <Wrapper>
      <div className="info">
        {fetching ? (
          '設定中...'
        ) : !props.value ? (
          '尚未設定'
        ) : !selectedIntExc ? (
          props.value
        ) : (
          <Button
            type="link"
            title="點選開啟新分頁編輯"
            href={`/admin/international_exchange/?action=detail&id=${selectedIntExc.id}`}>
            {selectedIntExc.title}
          </Button>
        )}
      </div>
      {!readonly && (
        <div style={{marginTop: 10}}>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedIntExc(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={() =>
              showAdminSearchIntExcModal({
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }
`;
