import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import AdminLineDivider from '../../Components/AdminLineDivider';

function AdminMoraleCustomActions(props) {
  const {id} = props.context.instance;

  return (
    <Wrapper className="related-resources">
      <AdminLineDivider title="相關會議/相關收發文" />
      <Button
        className="btn"
        type="link"
        target="_blank"
        href={`/admin/related-meetings/?query=${encodeURIComponent(
          JSON.stringify([{'morale_list.morale': id}]),
        )}`}>
        相關會議
      </Button>
      <Button
        className="btn"
        type="link"
        target="_blank"
        href={`/admin/related-forms/?query=${encodeURIComponent(
          JSON.stringify([{'morale_list.morale': id}]),
        )}`}>
        相關收發文
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;

  .btn {
    border: 1px solid var(--primaryColor);
    margin-right: 15px;
    cursor: pointer;

    :active,
    :hover {
      border: 1px solid var(--primaryColor);
    }
  }
`;

export default AdminMoraleCustomActions;
