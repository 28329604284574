import React from 'react';
import styled from 'styled-components';
import {Tag, Table, Button} from 'antd';
import {getStaffValue} from '../../Utils/StaffUtil';
import {formatDateTimeStr} from '../../Utils/TimeUtil';
import AdminLineDivider from '../../Components/AdminLineDivider';
import {showAdminFormHistoryEditModal} from './AdminFormHistoryEditModal';

const HISTIRY_COLUMNS = (canEdit, instance) => {
  const columns = [
    {key: 'group', title: '單位', dataIndex: 'group'},
    {key: 'role', title: '職稱', dataIndex: 'role'},
    {
      key: 'user_id',
      title: '簽核人',
      dataIndex: 'user_id',
      render: (_, record) => getStaffValue(record['user_id'], 'name'),
    },
    {key: 'note', title: '簽核內容', dataIndex: 'note'},
    {
      key: 'status',
      title: '狀態',
      dataIndex: 'status',
      render: (_, record) => {
        const display = (() => {
          switch (record.status) {
            case 'pending':
              return '待簽核';
            case 'success':
              return '通過';
            case 'abort':
              return '退簽';
            default:
              return record.status;
          }
        })();
        return <Tag>{display}</Tag>;
      },
    },
    {
      key: 'created',
      title: '簽核時間',
      dataIndex: 'created',
      render: (_, record) => formatDateTimeStr(new Date(record['created'])),
    },
  ];

  if (canEdit) {
    columns.push({
      key: 'action',
      title: '',
      dataIndex: '',
      render: (_, record, index) => {
        return (
          <Button
            onClick={() =>
              showAdminFormHistoryEditModal({
                type: 'history',
                index,
                instance,
                historyRecord: record,
              })
            }>
            編輯
          </Button>
        );
      },
    });
  }

  return columns;
};

const HISTIRY_EXECUTION_COLUMNS = (canEdit, instance) => {
  const columns = [
    {key: 'group', title: '單位', dataIndex: 'group'},
    {key: 'role', title: '職稱', dataIndex: 'role'},
    {
      key: 'staff',
      title: '執行人',
      dataIndex: 'staff',
      render: (_, record) => getStaffValue(record['staff'], 'name'),
    },
    {key: 'note', title: '執行內容', dataIndex: 'note'},
    {
      key: 'status',
      title: '狀態',
      dataIndex: 'status',
      render: (_, record) => {
        const display = (() => {
          switch (record.status) {
            case 'pending':
              return '待處理';
            case 'processed':
              return '已處理';
            case 'success':
              return '已完成';
            case 'cancel':
              return '已取消';
            default:
              return record.status;
          }
        })();
        return <Tag>{display}</Tag>;
      },
    },
    {
      key: 'created',
      title: '執行時間',
      dataIndex: 'created',
      render: (_, record) => formatDateTimeStr(new Date(record['created'])),
    },
  ];

  if (canEdit) {
    columns.push({
      key: 'action',
      title: '',
      dataIndex: '',
      render: (_, record, index) => {
        return (
          <Button
            onClick={() =>
              showAdminFormHistoryEditModal({
                type: 'history_execution',
                index,
                instance,
                historyRecord: record,
              })
            }>
            編輯
          </Button>
        );
      },
    });
  }

  return columns;
};

function AdminFormHistory(props) {
  const {context, canEdit = false} = props;

  return (
    <Wrapper>
      <div className="container" id="root_form-history">
        <AdminLineDivider title="簽核紀錄" />
        <div className="row">
          {canEdit && (
            <Button
              ghost
              type="primary"
              style={{marginLeft: 10}}
              onClick={() =>
                showAdminFormHistoryEditModal({
                  type: 'history_staff_list',
                  instance: context.instance,
                })
              }>
              修改
            </Button>
          )}
        </div>
        <Table
          pagination={false}
          dataSource={context.instance?.history}
          columns={HISTIRY_COLUMNS(canEdit, context.instance)}
        />
      </div>

      <div className="container" id="root_form-history-execution">
        <AdminLineDivider title="執行紀錄" />
        <div className="row">
          {canEdit && (
            <Button
              ghost
              type="primary"
              style={{marginLeft: 10}}
              onClick={() =>
                showAdminFormHistoryEditModal({
                  type: 'history_execution_staff_list',
                  instance: context.instance,
                })
              }>
              修改
            </Button>
          )}
        </div>
        <Table
          pagination={false}
          dataSource={context.instance?.history_execution}
          columns={HISTIRY_EXECUTION_COLUMNS(canEdit, context.instance)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > div {
    margin: 20px 0px;

    & > div > table {
      width: 100%;
    }

    & > div > table > tr > td {
      padding: 5px 10px;
      text-align: center;
    }
  }

  .container {
    margin-bottom: 40px;
  }

  .row {
    display: flex;
    align-items: center;
  }
`;

export default AdminFormHistory;
