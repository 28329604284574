import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {getStaffValue} from '../../Utils/StaffUtil';
import AdminSelectStaffWidget from './AdminSelectStaffWidget';

function AdminFormCustomFields(props) {
  const [user] = useOutlet('user');

  const {context, isAdmin} = props;
  const {instance, values, setValues, extValues, setExtValues} = context;

  const type = context.formSpec.schema.properties.type.default;
  const readOnly = isAdmin
    ? false
    : instance
    ? instance.stage !== 'edit'
    : false;

  const rjsfProps = {
    widgets: {
      'admin-select-staff-widget': AdminSelectStaffWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={values}
        schema={{
          title: '',
          type: 'object',
          properties: {
            'rev-line-0': {
              type: 'string',
              title: '基本資料',
            },
            paperwork_id: {
              type: 'string',
              title: type === 'post' ? '發文編號' : '收文編號',
              readOnly,
            },
            author: {type: 'string', title: '建立者', readOnly},
            implement: {type: 'string', title: '擬辦人員', readOnly},
            secret: {type: 'boolean', title: '機密文件', readOnly},
          },
        }}
        uiSchema={{
          'rev-line-0': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
          author: {'ui:widget': 'admin-select-staff-widget'},
          implement: {'ui:widget': 'admin-select-staff-widget'},
        }}
        onChange={(formData) => {
          const _formData = {...formData};
          const _instance = instance;

          if (formData.author !== _instance.author) {
            _formData.author_group = getStaffValue(formData.author, 'group');
            _formData.author_role = getStaffValue(formData.author, 'role');
            _formData.author_name = getStaffValue(formData.author, 'name');
          }

          if (formData.implement !== _instance.implement) {
            _formData.implement_group = getStaffValue(
              formData.implement,
              'group',
            );
            _formData.implement_role = getStaffValue(
              formData.implement,
              'role',
            );
            _formData.implement_name = getStaffValue(
              formData.implement,
              'name',
            );
          }

          setValues(_formData);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  & .submit-buttons-bar {
    border: 1px solid red;
    display: none;
  }
`;

export default AdminFormCustomFields;
