import React from 'react';
import styled from 'styled-components';
import {Table, Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

const pageSize = 100;

function AdminBackupEmailDataList(props) {
  const {context, path} = props;
  const {instance} = context;
  const [records, setRecords] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: pageSize,
  });

  React.useEffect(() => {
    async function fetchRecords(id) {
      try {
        AppActions.setLoading(true);
        let {total, results} = await JStorage.fetchDocuments(
          'backup_email_data',
          {
            backup_email_id: id,
          },
          ['-created'],
          pagination,
        );

        setTotal(total);
        setRecords(results);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (instance.id) {
      fetchRecords(instance.id);
    }
  }, [instance.id, pagination]);

  return (
    <Wrapper>
      <Table
        pagination={{
          size: 'small',
          total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆，每頁 ${pageSize} 筆`,
          current: pagination.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['topRight'],
          onChange: (page, pageSize) => {
            console.log('page', page);
            console.log('pageSize', pageSize);
            if (pageSize) {
              setPagination({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
        dataSource={records}
        columns={[
          {title: '姓名', key: 'name', dataIndex: 'name'},
          {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            render: (_, r) => {
              return <div>{r.email || '---'}</div>;
            },
          },
          {
            title: '發送結果',
            key: 'status',
            dataIndex: 'status',
            render: (_, r) => {
              return <div>{!!r.email ? '成功' : '失敗'}</div>;
            },
          },
          {
            title: '信件內容',
            key: '',
            dataIndex: '',
            render: (_, r) => {
              const queries = {
                is_preview: true,
                has_email_data: true,
                sender: instance.template_name,
                owner: r.owner,
                custom_data: r.custom_data,
              };

              return (
                <div>
                  <Button
                    type="link"
                    target="_blank"
                    href={`/print-preview?target=annualFeeNotify&query=${JSON.stringify(
                      queries,
                    )}`}>
                    預覽
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminBackupEmailDataList;
