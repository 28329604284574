import React from 'react';
import styled from 'styled-components';
import {Descriptions} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {formatDateStr} from '../../Utils/TimeUtil';
import {
  getUserTypeLabel,
  getUserStateLabel,
  getUserSubstateLabel,
  getUserJoinDates,
  getUserTransformDate,
} from '../../Utils/UserUtil';

export default function AdminUserBasicInfo(props) {
  const {isUprData, context, user_id} = props;
  const {instance, values, setValues} = context;
  const [member, setMember] = React.useState({
    name: '',
    user_type: '',
    state: null,
    substate: '',
    phone: '',
    email: '',
    id_card_number: '',
    birthday: '',
    age: '',
    seniority: '',
    join_start_date: '',
    join_end_date: '',
    transform_date: '',
    transform_type: '',
  });

  React.useEffect(() => {
    async function fetchMemberByOwner(user_id) {
      try {
        AppActions.setLoading(true);

        let upr, ppr;

        if (isUprData) {
          upr = {...instance};
        } else {
          upr = await JStorage.fetchOneDocument(
            'user_profile',
            {
              owner: user_id,
            },
            {
              state: 1,
              substate: 1,
              user_type: 1,
              status: 1,
              phone: 1,
              email: 1,
              inouts: 1,
              transforms: 1,
            },
          );
        }

        ppr = await JStorage.fetchOneDocument('private_profile', {
          owner: user_id,
        });

        let transformObj = getUserTransformDate(upr.transforms);
        let joinObj = getUserJoinDates(upr.inouts);
        let ageObj = await AppActionsEx.getUserAge({user_id});
        let seniorityObj = await AppActionsEx.getUserSeniority({
          user_id,
        });

        setMember({
          ...ppr,
          ...upr,
          birthday: upr.birthday?.split('T')[0] || '[未填寫生日]',
          join_start_date: joinObj.join_start_date,
          join_end_date: joinObj.join_end_date,
          transform_type: transformObj.transform_type,
          transform_date: transformObj.transform_date,
          age: upr.birthday?.split('T')[0] ? ageObj.age : '[計算發生錯誤]',
          seniority: seniorityObj.seniority,
        });
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (user_id) {
      fetchMemberByOwner(user_id);
    }
  }, [user_id]);

  return (
    <Wrapper>
      <h3 className="title">會員基本資料</h3>
      <Descriptions bordered size="small" style={{marginBottom: 10}}>
        <Descriptions.Item label="身分證字號">
          <p>{member?.id_card_number}</p>
        </Descriptions.Item>
        <Descriptions.Item label="姓名" span={2}>
          <p>
            <a
              target="_blank"
              href={`/admin/users/?action=detail&id=${member?.id}`}
              rel="noreferrer">
              {member.name}
            </a>
          </p>
        </Descriptions.Item>
        <Descriptions.Item label="類別">
          <p>{getUserTypeLabel(member.user_type)}</p>
        </Descriptions.Item>
        <Descriptions.Item label="狀態">
          <p>{getUserStateLabel(member.state)}</p>
        </Descriptions.Item>
        <Descriptions.Item label="次狀態">
          <p>{getUserSubstateLabel(member.substate)}</p>
        </Descriptions.Item>
        <Descriptions.Item label="行動電話">
          <p>{member.phone}</p>
        </Descriptions.Item>
        <Descriptions.Item label="電子信箱">
          <p>{member.email}</p>
        </Descriptions.Item>
        <Descriptions.Item label="生日">
          <p>{member.birthday}</p>
        </Descriptions.Item>
        <Descriptions.Item label="年齡">
          <p>{member.age}</p>
        </Descriptions.Item>
        <Descriptions.Item label="年資">
          <p>{member.seniority}</p>
        </Descriptions.Item>
        <Descriptions.Item label="入會日期">
          <p>{member.join_start_date}</p>
        </Descriptions.Item>
        <Descriptions.Item label="退會日期">
          <p>{member.join_end_date}</p>
        </Descriptions.Item>
        <Descriptions.Item label="轉換日期">
          <p>
            {member.transform_date}{' '}
            {member.transform_type && (
              <span>
                （轉換前：{getUserSubstateLabel(member.transform_type)}）
              </span>
            )}
          </p>
        </Descriptions.Item>
      </Descriptions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > .title {
    margin-bottom: 20px;
  }
`;
