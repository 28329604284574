import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {Button, Modal} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import AdminSelectCompanyWidget from './AdminSelectCompanyWidget';

const routePrefix = '/admin/user-billings';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      buyer_name: {
        type: 'string',
        title: '姓名',
      },
      state: {
        type: 'integer',
        title: '狀態',
        enum: [1, 2, 3, 4, 5, 6],
        enumNames: ['入會', '退會', '其他', '實習律師', '跨區', '停止'],
      },
      substate: {
        type: 'string',
        title: '次狀態',
        enum: ['1', '2', '3', '4', '5', '6', 7],
        enumNames: [
          '主區會員',
          '準會員',
          '兼區會員',
          '一般會員',
          '特別會員',
          '外國法事務律師',
          '準特別會員',
        ],
      },
      LAWYER_NO: {
        type: 'string',
        title: '會員序號',
      },
      id_card_number: {
        type: 'string',
        title: '身分證字號',
      },
      buyer_email: {
        type: 'string',
        title: 'EMAIL',
      },
      buyer_phone: {
        type: 'string',
        title: '手機',
      },
      PHONE1: {
        type: 'string',
        title: '電話1',
      },
      company: {
        type: 'string',
        title: '事務所',
      },
    },
  },
  uiSchema: {
    company: {
      'ui:widget': 'admin-select-company-widget',
    },
  },
};

const defaultQuery = {};

function parseWindowQuery() {
  if (typeof window !== 'undefined') {
    let params = qs.parse(window.location.search);
    let query = defaultQuery;

    try {
      query = JSON.parse(decodeURIComponent(params.query));
    } catch (err) {}

    return query;
  }
}

function setWindowQuery(data) {
  if (!data) {
    AppActions.navigate(`${routePrefix}`);
  } else {
    window.open(
      `${routePrefix}?query=${encodeURIComponent(JSON.stringify(data))}`,
    );
  }
}

export default function AdminUserBillingSearchButton(props) {
  const [modal, setModal] = React.useState({
    visible: false,
  });
  const queryParams = parseWindowQuery();

  return (
    <>
      <Button
        onClick={() => {
          setModal({visible: true});
        }}>
        進階搜尋
      </Button>
      <QueryFormModal
        data={{visible: modal.visible, data: queryParams}}
        setData={({data, visible}) => {
          if (data !== undefined) {
            setWindowQuery(data);
          }
          setModal({visible});
          AppActions.setLoading(false);
        }}
      />
    </>
  );
}

function QueryFormModal(props) {
  const {
    data: {visible},
    setData,
  } = props;
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={800}
      visible={visible}
      onOk={() => {
        setData({
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-company-widget': AdminSelectCompanyWidget,
    },
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1>查詢參數</h1>
        <Button
          type="link"
          onClick={() => {
            setData({visible: true, data: null});
          }}>
          全部清除
        </Button>
      </div>

      <GenericForm
        {...FormSpec}
        rjsfProps={rjsfProps}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
