import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Modal, Steps, Button} from 'antd';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import Config from '../../../data.json';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

export default function VideoUploadModal(props) {
  const {visible, setVisible} = props;

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={450}
      visible={visible}
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {record} = props;
  const [user] = useOutlet('user');
  const [url, setUrl] = React.useState(null);
  const [name, setName] = React.useState(null);
  const fileInputRef = React.useRef(null);
  const [step, setStep] = React.useState(0);

  function onFileSelected() {
    if (fileInputRef?.current?.files) {
      const selectedFile = fileInputRef.current.files[0];
      console.log(selectedFile);
      if (!selectedFile) {
        setUrl(null);
      } else {
        const objUrl = URL.createObjectURL(selectedFile);
        console.log(objUrl);
        setUrl(objUrl);
        setName(selectedFile.name);
        setStep(1);
      }
    }
  }

  async function uploadFile() {
    if (!fileInputRef?.current?.files) {
      return;
    }

    const selectedFile = fileInputRef.current.files[0];
    if (user && selectedFile) {
      AppActions.setLoading(true);
      try {
        const resp = await ApiUtil.req(
          `${Config.apiHost}/mux/presign/url?token=${user.token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              video_id: record.id,
              permission: record.permission || 'public',
              timeout: 3600,
            },
          },
        );

        const {
          data: {url},
        } = resp;

        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'video/mp4',
          },
          body: selectedFile,
        });

        setStep(2);
      } catch (ex) {
        console.warn(ex);
        alert('上傳失敗: 請稍後再試');
      }
      AppActions.setLoading(false);
    }
  }

  const stepElem = (
    <Steps
      direction="horizontal"
      type="navigation"
      responsive={false}
      current={step}
      style={{marginBottom: 20}}>
      <Steps.Step title="選擇檔案" />
      <Steps.Step title="上傳檔案" />
      <Steps.Step title="完成" />
    </Steps>
  );

  return (
    <Wrapper>
      {stepElem}

      <input
        type="file"
        ref={fileInputRef}
        accept="video/mp4"
        onChange={onFileSelected}
        style={{display: step === 0 ? 'block' : 'none'}}
      />

      {step === 1 && url && (
        <>
          <div>{name}</div>
          <div className="action-bar">
            <Button
              onClick={() => {
                setUrl(null);
                setStep(0);
              }}>
              重選檔案
            </Button>
            <Button type="primary" onClick={uploadFile}>
              上傳
            </Button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div id="rev-upload-expected-url">上傳完畢: {name}</div>
          {props.onChange && (
            <Button
              type="primary"
              onClick={() => {
                console.log('DBG clicked');
                // props.onChange(name);
                // props.setVisible(false);
              }}>
              確認
            </Button>
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 40px 20px 20px 20px;

  & > .preview {
    display: block;
    width: 200px;
    height: 200px;
    object-fit: contain;
    background-color: #ccc;
    overflow: hidden;
  }

  & > .action-bar {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      margin-left: 10px;
    }
  }
`;
