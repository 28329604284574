import React from 'react';
import styled from 'styled-components';
import {DatePicker, Button, message} from 'antd';
import * as AppActions from '../../AppActions';

function AdminBackupEmailExtraQueries(props) {
  const {setQueryState, queryState} = props;
  const [dateStrings, setDateStrings] = React.useState(null);

  const onSubmit = async () => {
    if (
      !dateStrings ||
      (Array.isArray(dateStrings) && dateStrings.length === 0)
    ) {
      setQueryState((prev) => ({
        ...prev,
        extraQueries: {
          ...prev.extraQueries,
          created: undefined,
        },
      }));
    } else {
      const _extraQueries = {};

      if (!!dateStrings[0]) {
        _extraQueries['created'] = {
          $gte: new Date(`${dateStrings[0]}T00:00`).getTime(),
        };
      }

      if (!!dateStrings[1]) {
        _extraQueries['created'] = {
          ..._extraQueries['created'],
          $lte: new Date(`${dateStrings[1]}T23:59`).getTime(),
        };
      }

      setQueryState((prev) => {
        const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};

        const filterOutQueryKeys = Object.keys(prev.extraQueries)
          .map((q) => {
            if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
              return q;
            } else {
              return null;
            }
          })
          .filter((k) => !!k);

        filterOutQueryKeys.forEach((k) => {
          delete nextExtraQuery[k];
        });

        return {
          ...prev,
          extraQueries: nextExtraQuery,
        };
      });
    }
  };

  return (
    <Wrapper>
      <div className="field">
        <label>寄送日期：</label>
        <DatePicker.RangePicker
          placeholder={['開始日期', '結束日期']}
          style={{marginRight: 10}}
          onChange={(dates, dateStrings) => {
            setDateStrings(dateStrings);
          }}
        />
        <Button onClick={onSubmit}>搜尋</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .field {
    display: flex;
    align-items: center;
  }

  & > .field > label {
    margin-right: 10px;
  }
`;

export default AdminBackupEmailExtraQueries;
