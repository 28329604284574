import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getOutlet} from 'reconnect.js';
import {
  getUserSubstateLabel,
  getInsuranceTypeLabel,
  getInsuranceRelationshipLabel,
} from './UserUtil';

const UsersOutlet = getOutlet('users');

function generateCourseProductInfoRows(product) {
  const isSession =
    product &&
    product.session &&
    Object.keys(product.session).length > 0 &&
    product.session.date;

  const sessionText = isSession
    ? `上課日期：${product.session.date} ${
        product.session.start_time
          ? `${product.session.start_time}-${product.session.end_time}`
          : ``
      }`
    : '---';

  return [
    [`${product.name}`, '', '', '', '', '', '', '', '', ''],
    [
      `${product.internal_label}${
        product.serial_number ? `(${product.serial_number})` : ``
      }`,
    ],
    [],
    [`${sessionText}`],
  ];
}

function generateCourseJoinHeaders(product) {
  const isSession =
    product &&
    product.session &&
    Object.keys(product.session).length > 0 &&
    product.session.date;

  const sessionText = isSession
    ? `${product.session.date}\n${
        product.session.start_time ? `${product.session.start_time}` : ``
      }`
    : '---';

  return [
    [
      '編號',
      '姓名',
      '狀態',
      '簽到',
      '',
      '繳費',
      '免費時數',
      '退費',
      '收據',
      '素食',
    ],
    ['', '', '', `${sessionText}`, '', '', '', '', '', ''],
  ];
}

function generateCoursePeopleJoinRows(people) {
  return (people || []).map((p) => {
    const upr = UsersOutlet.getValue().find((u) => u.owner === p.user);
    const name = upr ? upr.name : p.name;

    return [p.type, name, '', '', '', '', '', '', '', ''];
  });
}

function generateCourseMemberJoinRows(members) {
  const rows = [];

  if (!members) {
    return rows;
  }

  const _members = members.sort((a, b) =>
    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
  );

  for (let i = 0; i < _members.length; i++) {
    const member = _members[i];
    const upr = UsersOutlet.getValue().find((it) => it.owner === member.owner);

    const substate = member.substate
      ? getUserSubstateLabel(member.substate)
      : upr
      ? getUserSubstateLabel(upr.substate)
      : '';

    rows.push([
      `${i + 1}`,
      `${upr ? upr.name : ''}`,
      `${substate}`,
      '',
      '',
      `${member.total || ''}`,
      `${member.is_points_discount ? '●' : ''}`,
      '',
      '',
      `${
        Array.isArray(member.vegetarians) && member.vegetarians.length > 0
          ? member.vegetarians[0].is_vegetarian
            ? '是'
            : '否'
          : '否'
      }`,
    ]);
  }

  return rows;
}

function generateActivityProductInfoRows(product) {
  const isSession =
    product &&
    product.session &&
    Object.keys(product.session).length > 0 &&
    product.session.date;

  const sessionText = isSession
    ? `活動日期：${product.session.date} ${
        product.session.start_time
          ? `${product.session.start_time}-${product.session.end_time}`
          : ``
      }`
    : '---';

  return [[`${product.name}`, '', '', '', ''], [`${sessionText}`]];
}

function generateActivityJoinHeaders(product) {
  return [['編號', '姓名', '狀態', '簽到', '繳費']];
}

function generateActivityPeopleJoinRows(people) {
  return (people || []).map((p) => {
    const upr = UsersOutlet.getValue().find((u) => u.owner === p.user);

    return [`${p.type}`, `${upr ? upr.name : p.name}`, '', '', ''];
  });
}

function generateActivityMemberJoinRows(members) {
  const rows = [];

  if (!members) {
    return rows;
  }

  const _members = members.sort((a, b) =>
    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
  );

  for (let i = 0; i < _members.length; i++) {
    const member = _members[i];
    const upr = UsersOutlet.getValue().find((it) => it.owner === member.owner);

    const substate = member.substate
      ? getUserSubstateLabel(member.substate)
      : upr
      ? getUserSubstateLabel(upr.substate)
      : '';

    rows.push([
      `${i + 1}`,
      `${upr ? upr.name : ''}\n               眷屬 ${
        member.tba_seat - 1 > 0 ? member.tba_seat : 0
      }`,
      `${substate}`,
      '',
      `${member.total > 0 ? member.total : ''}`,
    ]);
  }

  return rows;
}

function generateParticipantListProductInfoRows(product) {
  const isSession =
    product &&
    product.session &&
    Object.keys(product.session).length > 0 &&
    product.session.date;

  const sessionText = isSession
    ? `活動日期：${product.session.date} ${
        product.session.start_time
          ? `${product.session.start_time}-${product.session.end_time}`
          : ``
      }`
    : '---';

  return [
    [`${product.name}\n參加名單`, '', '', '', '', '', '', '', '', '', ''],
    [`${sessionText}`, '', '', '', '', '', '', '', '', '', ''],
  ];
}

function generateParticipantListJoinHeaders(product) {
  return [
    [
      '序號',
      '姓名',
      '身分別',
      '身分證字號',
      '生日',
      '與保險人關係',
      '受益人',
      '身分證字號',
      '素食',
      '聯絡電話',
      '報名時間',
    ],
  ];
}

function generateParticipantListPeopleJoinRows(people) {
  return [];
}

function generateParticipantListMemberJoinRows(members) {
  const rows = [];

  if (!members) {
    return rows;
  }

  const _members = members.sort((a, b) =>
    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
  );

  for (let i = 0; i < _members.length; i++) {
    const member = _members[i];
    const upr = UsersOutlet.getValue().find((it) => it.owner === member.owner);

    for (let i = 0; i < member.tba_seat || 0; i++) {
      if (i === 0) {
        rows.push([
          ``,
          `${member.insurances[i]?.name || upr ? upr.name : ''}`,
          '會員',
          `${
            member.insurances[i]?.id_card_number || upr
              ? upr.id_card_number
              : ''
          }`,
          `${
            member.insurances[i]?.birthday || upr
              ? upr.birthday?.slice(0, 10)
              : ''
          }`,
          `${
            member.insurances[i]?.relationship
              ? getInsuranceRelationshipLabel(
                  member.insurances[i]?.relationship,
                )
              : '[尚未填寫]'
          }`,
          `${member.insurances[i]?.r_name || '[尚未填寫]'}`,
          `${member.insurances[i]?.r_id_card_number || '[尚未填寫]'}`,
          `${member.vegetarians[i]?.is_vegetarian ? '是' : '否'}`,
          `${upr ? upr.phone : ''}`,
          `${
            member.pay_time
              ? `${new Date(member.pay_time).toLocaleDateString(
                  'sv',
                )}\n${new Date(member.pay_time).toLocaleTimeString('sv')}`
              : ''
          }`,
        ]);
      } else {
        rows.push([
          ``,
          `*${member.insurances[i]?.name || '[尚未填寫]'}`,
          '眷屬',
          `${member.insurances[i]?.id_card_number || ''}`,
          `${member.insurances[i]?.birthday || ''}`,
          `${
            member.insurances[i]?.relationship
              ? getInsuranceRelationshipLabel(
                  member.insurances[i]?.relationship,
                )
              : '[尚未填寫]'
          }`,
          `${member.insurances[i]?.r_name || '[尚未填寫]'}`,
          `${member.insurances[i]?.r_id_card_number || '[尚未填寫]'}`,
          `${member.vegetarians[i]?.is_vegetarian ? '是' : '否'}`,
          `${upr ? upr.phone : ''}`,
          `${
            member.pay_time
              ? `${new Date(member.pay_time).toLocaleDateString(
                  'sv',
                )}\n${new Date(member.pay_time).toLocaleTimeString('sv')}`
              : ''
          }`,
        ]);
      }
    }
  }

  for (let n = 0; n < rows.length; n++) {
    rows[n][0] = `${n + 1}`;
  }

  rows.push([]);
  rows.push([]);
  rows.push([`#共有 ${rows.length - 2} 筆資料`]);

  return rows;
}

export const adminCourseRecordsToAoa = async ({instance, people, members}) => {
  const product = await JStorage.fetchOneDocument('product', {
    id: instance.product_id,
  });

  const productInfoRows = generateCourseProductInfoRows(product);
  const joinHeaders = generateCourseJoinHeaders(product);
  const peopleJoinRows = generateCoursePeopleJoinRows(people);
  const memberJoinRows = generateCourseMemberJoinRows(members);

  return [
    ...productInfoRows,
    ...joinHeaders,
    ...peopleJoinRows,
    ...memberJoinRows,
  ];
};

export const adminCourseUpdateWorkSheet = (ws) => {
  ws['!merges'] = [
    // s = start, r = row, c = col, e = end
    {s: {r: 0, c: 0}, e: {r: 0, c: 9}},
    {s: {r: 1, c: 0}, e: {r: 1, c: 9}},
    {s: {r: 2, c: 0}, e: {r: 2, c: 9}},
    {s: {r: 3, c: 0}, e: {r: 3, c: 9}},

    {s: {r: 4, c: 0}, e: {r: 5, c: 0}},
    {s: {r: 4, c: 1}, e: {r: 5, c: 1}},
    {s: {r: 4, c: 2}, e: {r: 5, c: 2}},

    {s: {r: 4, c: 3}, e: {r: 4, c: 4}},
  ];

  //title

  const titles = [
    'A1',
    'B1',
    'C1',
    'D1',
    'E1',
    'F1',
    'G1',
    'H1',
    'I1',
    'J1',
    'A2',
    'B2',
    'C2',
    'D2',
    'E2',
    'F2',
    'G2',
    'H2',
    'I2',
    'J2',
    'A3',
    'B3',
    'C3',
    'D3',
    'E3',
    'F3',
    'G3',
    'H3',
    'I3',
    'J3',
    'A4',
    'B4',
    'C4',
    'D4',
    'E4',
    'F4',
    'G4',
    'H4',
    'I4',
    'J4',
  ];

  ws['A1'].s = {
    sz: 18,
    bold: true,
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  };

  //subtitle
  ws['A2'].s = {
    sz: 12,
    bold: true,
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  };

  ws['A4'].s = {
    sz: 12,
  };

  //cell style
  const cells = Object.keys(ws);
  const excludeCells = ['!cols', '!merges', '!ref', ...titles];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    const isHeaderMergeCells = ['B5', 'B6'].includes(cell);
    const isHeaderCell = cell.indexOf('B') > -1;

    if (!excludeCells.includes(cell)) {
      if (isHeaderCell && !isHeaderMergeCells) {
        ws[cell].s = {
          ...ws[cell].s,
          bold: true,
          sz: 16,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      } else {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 12,
          alignment: {
            wrapText: true,
            horizontal: 'center',
            vertical: 'center',
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      }
    }
  }

  //col width
  ws['!cols'] = [
    {wpx: 60},
    {wpx: 101},
    {wpx: 75},
    {wpx: 122},
    {wpx: 122},
    {wpx: 59},
    {wpx: 78},
    {wpx: 59},
    {wpx: 45},
    {wpx: 45},
  ];

  //row height
  ws['!rows'] = [
    {hpx: 66},
    {hpx: 26},
    {hpx: 6},
    {hpx: 28},
    {hpx: 26},
    {hpx: 52},
  ];

  const serialNumbers = cells
    .filter((c) => c.indexOf('A') > -1)
    .filter((c) => !['A1', 'A2', 'A3', 'A4', 'A5', 'A6'].includes(c));

  for (let i = 0; i < serialNumbers.length; i++) {
    ws['!rows'].push({hpx: 48});
  }
};

export const adminActivityRecordsToAoa = async ({
  instance,
  people,
  members,
}) => {
  const product = await JStorage.fetchOneDocument('product', {
    id: instance.product_id,
  });

  const productInfoRows = generateActivityProductInfoRows(product);
  const joinHeaders = generateActivityJoinHeaders(product);
  const peopleJoinRows = generateActivityPeopleJoinRows(people);
  const memberJoinRows = generateActivityMemberJoinRows(members);

  return [
    ...productInfoRows,
    ...joinHeaders,
    ...peopleJoinRows,
    ...memberJoinRows,
  ];
};

export const adminActivityUpdateWorkSheet = (ws) => {
  ws['!merges'] = [
    // s = start, r = row, c = col, e = end
    {s: {r: 0, c: 0}, e: {r: 0, c: 4}},
    {s: {r: 1, c: 0}, e: {r: 1, c: 4}},
  ];

  //title
  const titles = ['A1', 'B1', 'C1', 'D1', 'E1'];
  ws['A1'].s = {
    sz: 17,
    bold: true,
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  };

  //subtitle
  const subtitles = ['A2', 'B2', 'C2', 'D2', 'E2'];
  ws['A2'].s = {
    sz: 12,
    alignment: {
      vertical: 'center',
    },
  };

  //col width
  ws['!cols'] = [{wpx: 65}, {wpx: 149}, {wpx: 118}, {wpx: 199}, {wpx: 117}];

  //row height
  ws['!rows'] = [{hpx: 33}, {hpx: 40}, {hpx: 26}, {hpx: 46}, {hpx: 46}];

  //cell style adn row height
  const cells = Object.keys(ws);
  const excludeCells = ['!cols', '!merges', '!ref', ...titles, ...subtitles];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    if (!excludeCells.includes(cell)) {
      ws['!rows'].push({hpx: 46});

      ws[cell].s = {
        ...ws[cell].s,
        sz: 12,
        alignment: {
          wrapText: true,
          horizontal: 'center',
          vertical: 'center',
        },
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        bottom: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        left: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        right: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };
    }
  }
};

export const adminParticipantListRecordsToAoa = async ({
  instance,
  people,
  members,
}) => {
  const product = await JStorage.fetchOneDocument('product', {
    id: instance.product_id,
  });

  const productInfoRows = generateParticipantListProductInfoRows(product);
  const joinHeaders = generateParticipantListJoinHeaders(product);
  // const peopleJoinRows = generateParticipantListPeopleJoinRows(people);
  const memberJoinRows = generateParticipantListMemberJoinRows(members);

  return [
    ...productInfoRows,
    ...joinHeaders,
    // ...peopleJoinRows,
    ...memberJoinRows,
  ];
};

export const adminParticipantListWorkSheet = (ws) => {
  ws['!merges'] = [
    // s = start, r = row, c = col, e = end
    {s: {r: 0, c: 0}, e: {r: 0, c: 10}},
    {s: {r: 1, c: 0}, e: {r: 1, c: 10}},
  ];

  //title
  const titles = [
    'A1',
    'B1',
    'C1',
    'D1',
    'E1',
    'F1',
    'G1',
    'H1',
    'I1',
    'J1',
    'K1',
  ];

  for (let i = 0; i < titles.length; i++) {
    const cell = titles[i];

    if (i === 0) {
      ws[cell].s = {
        sz: 18,
        bold: true,
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        left: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };

      continue;
    }

    if (i + 1 === titles.length) {
      ws[cell].s = {
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        right: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };

      continue;
    }

    ws[cell].s = {
      top: {
        style: 'thin',
        color: {rgb: '000000'},
      },
    };
  }

  //subtitle
  const subtitles = [
    'A2',
    'B2',
    'C2',
    'D2',
    'E2',
    'F2',
    'G2',
    'H2',
    'I2',
    'J2',
    'K2',
  ];

  for (let i = 0; i < subtitles.length; i++) {
    const cell = subtitles[i];

    if (i === 0) {
      ws[cell].s = {
        sz: 10,
        alignment: {
          vertical: 'center',
        },
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        left: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };

      continue;
    }

    if (i + 1 === subtitles.length) {
      ws[cell].s = {
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        right: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };

      continue;
    }

    ws[cell].s = {
      top: {
        style: 'thin',
        color: {rgb: '000000'},
      },
    };
  }

  //cell style
  const cells = Object.keys(ws);
  const excludeCells = ['!cols', '!merges', '!ref', ...titles, ...subtitles];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    if (!excludeCells.includes(cell)) {
      if (ws[cell].v.indexOf('*') === 0) {
        ws[cell].v = `${ws[cell].v.slice(1)}`;
        ws[cell].s = {
          ...ws[cell].s,
          sz: 10,
          alignment: {
            horizontal: 'right',
            vertical: 'center',
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };

        continue;
      }

      if (ws[cell].v.indexOf('#') === 0) {
        ws[cell].v = `${ws[cell].v.slice(1)}`;
        ws[cell].s = {
          ...ws[cell].s,
          sz: 10,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
          },
        };

        continue;
      }

      ws[cell].s = {
        ...ws[cell].s,
        sz: 10,
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
        top: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        bottom: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        left: {
          style: 'thin',
          color: {rgb: '000000'},
        },
        right: {
          style: 'thin',
          color: {rgb: '000000'},
        },
      };
    }
  }

  //col width
  ws['!cols'] = [
    {wpx: 58},
    {wpx: 79},
    {wpx: 83},
    {wpx: 105},
    {wpx: 83},
    {wpx: 107},
    {wpx: 113},
    {wpx: 114},
    {wpx: 83},
    {wpx: 82},
    {wpx: 79},
  ];
};
