import React from 'react';
import {message, Descriptions, Button} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import {getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import OrderField from 'rev.sdk.js/Components/OrderField';
import CartList from 'rev.sdk.js/Components/CartList';
import PriceSummary from 'rev.sdk.js/Templates/OrderPage/PriceSummary';
import * as AppActions from '../../../AppActions';
import * as AppActionsEx from '../../../AppActions/custom';
import {
  renderExtraItemsInfo,
  renderDiscountItemsInfo,
} from '../../../Utils/CustomCheckout';
import AdminLineDivider from '../../../Components/AdminLineDivider';
import {
  getUserTypeLabel,
  getUserStatusLabel,
  getUserStateLabel,
  getUserSubstateLabel,
} from '../../../Utils/UserUtil';
import {formatDateStr} from '../../../Utils/TimeUtil';
import AdminUserBasicInfo from '../AdminUserBasicInfo';
import UpdateOrderSection from './UpdateOrderSection';
import DiscountItemsSection from './DiscountItemsSection';
import ExtraItemsSection from './ExtraItemsSection';

const L = AppActions.L;

function CustomAdminOrderDetailForm(props) {
  const {context} = props;
  const {instance, values, setValues, path} = context;
  const [member, setMember] = React.useState(null);

  const getOrderType = (instance) => {
    if (instance) {
      const itemName = instance.items[0].product.name;
      const itemLabels = instance.items[0].product.labels;
      const itemHasCourseLabel = itemLabels?.some(
        (l) => l.indexOf('課程') > -1,
      );
      const itemHasActivityLabel = itemLabels?.some(
        (l) => l.indexOf('活動') > -1,
      );

      if (itemName.indexOf('入會費') > -1) {
        return {value: 'infee', label: '入會費'};
      } else if (itemName.indexOf('常年會費') > -1) {
        return {value: 'fee', label: '常年會費'};
      } else if (itemName.indexOf('跨區會費') > -1) {
        return {value: 'crossfee', label: '跨區會費'};
      }

      if (itemHasActivityLabel && itemHasCourseLabel) {
        return {value: 'product', label: '課程/活動'};
      } else if (itemHasActivityLabel) {
        return {value: 'activity', label: '活動'};
      } else if (itemHasCourseLabel) {
        return {value: 'course', label: '課程'};
      }
    }
    return {value: null, label: ''};
  };

  return (
    <Wrapper id="rev-AdminOrderDetailForm">
      <div className="buyer-info">
        <AdminUserBasicInfo
          isUprDate={false}
          user_id={instance.owner}
          path={path}
          context={context}
        />
      </div>

      <div className="order-info">
        <AdminLineDivider title="訂單內容" />
        <Descriptions bordered size="small" style={{marginBottom: 10}}>
          <Descriptions.Item label="訂單種類" span={3}>
            {getOrderType(instance).label}
          </Descriptions.Item>
          {getOrderType(instance).value === 'fee' && (
            <>
              <Descriptions.Item label="年度">
                <p>{instance.annual_year || '---'}</p>
              </Descriptions.Item>
              <Descriptions.Item label="起迄">
                <p>
                  {instance.start_month || '---'}月-
                  {instance.end_month || '---'}月
                </p>
              </Descriptions.Item>
            </>
          )}
          {getOrderType(instance).value === 'crossfee' && (
            <>
              <Descriptions.Item label="執業開始日期">
                <p>
                  {instance.items[0].config.extra_data?.start_date || '---'}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="執業結束日期">
                <p>{instance.items[0].config.extra_data?.end_date || '---'}</p>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>

        <CartList
          defaultExpand={
            getOrderType(instance).value !== 'fee' &&
            getOrderType(instance).value !== 'infee'
          }
          cartItems={instance.items}
          disabled={true}
        />
        <PriceSummary
          order={instance}
          renderExtraItemsInfo={renderExtraItemsInfo}
          renderDiscountItemsInfo={renderDiscountItemsInfo}
        />
      </div>

      <div className="receiver-info">
        <AdminLineDivider title={L('receiver_info')} />
        <OrderField name="姓名" value={instance.receiver_name} />
        <OrderField name="行動電話" value={instance.receiver_phone} />
        <OrderField name="電子信箱" value={instance.receiver_email} />
        <OrderField
          name="地址"
          value={`${instance.receiver_zip} ${instance.receiver_city} ${instance.receiver_district} ${instance.receiver_address}`}
        />
        <OrderField
          name="市話"
          value={`${instance.receiver_tel} 分機 ${instance.receiver_tel_ext}`}
        />
      </div>

      <div className="discounts-info">
        <AdminLineDivider title="金額減項" />
        <DiscountItemsSection {...props} />
      </div>

      <div className="fees-info">
        <AdminLineDivider title="金額加項" />
        <ExtraItemsSection {...props} />
      </div>

      <div className="receipt-info">
        <AdminLineDivider title="收據資料" />
        <UpdateOrderSection {...props} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 15px 0px;

  & > div {
    margin-bottom: 40px;

    & > h2 {
      margin-bottom: 10px;
    }
  }
`;

export default CustomAdminOrderDetailForm;
