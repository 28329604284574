import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchArticleModal} from '../../Components/AdminSearchArticleModal/index.js';

export default function AdminSelectArticleWidget(props) {
  const [articles] = useOutlet('articles');
  const [selectedArticle, setSelectedArticle] = React.useState(null);

  React.useEffect(() => {
    if (props.value) {
      setSelectedArticle(
        articles.find((article) => article.id === props.value),
      );
    }
  }, [props.value, articles]);

  return (
    <Wrapper>
      <div>
        {!props.value ? (
          '尚未設定文章'
        ) : !selectedArticle ? (
          props.value
        ) : (
          <Button
            type="link"
            target="_blank"
            title="點選開啟新分頁編輯"
            href={`/admin/articles/?action=detail&id=${selectedArticle.id}`}>{`【 ${selectedArticle.title} 】`}</Button>
        )}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedArticle(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchArticleModal({
            onChange: (id) => {
              props.onChange(id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
