import React from 'react';
import styled from 'styled-components';
import {Alert} from 'antd';

function AdminOrderNotofication(props) {
  const {path, context} = props;

  if (context.instance?.status === 'canceled') {
    return (
      <Wrapper>
        <Alert type="warning" showIcon message="請注意：本訂單已取消。" />
      </Wrapper>
    );
  } else if (context.instance?.status === 'time_canceled') {
    return (
      <Wrapper>
        <Alert
          type="warning"
          showIcon
          message="請注意：本訂單已取消 (超過繳費時間)。"
        />
      </Wrapper>
    );
  } else if (context.instance?.status === 'stock_canceled') {
    return (
      <Wrapper>
        <Alert
          type="warning"
          showIcon
          message="請注意：本訂單已取消 (人數庫存額滿)。"
        />
      </Wrapper>
    );
  }

  return null;
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default AdminOrderNotofication;
