import React from 'react';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import {Button, Popconfirm, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import permissionRequired from '../../Utils/PermissionRequiredUtil';
import {
  COURSE_INTERNAL_LABELS,
  ACTIVITY_INTERMAL_LABELS,
} from '../../Utils/ProductUtil';
import {
  adminCourseRecordsToAoa,
  adminCourseUpdateWorkSheet,
  adminActivityRecordsToAoa,
  adminActivityUpdateWorkSheet,
  adminParticipantListRecordsToAoa,
  adminParticipantListWorkSheet,
} from '../../Utils/SignFormExportUtil';
import DataJson from '../../../data.json';
import AdminLineDivider from '../../Components/AdminLineDivider';
import AdminDownloadExcelButton from '../../Components/AdminDownloadExcelButton';
import {showAdminMuxPermissionApplyModal} from '../../Components/AdminMuxPermissionApplyModal';

const UserOutlet = getOutlet('user');
const ProductsOutlet = getOutlet('products');

function AdminSignFormActions(props) {
  const {context} = props;
  const {instance, values, setValues} = context;
  const targetProduct = ProductsOutlet.getValue().find(
    (p) => p.id === instance.product_id,
  );

  const isCourse =
    targetProduct &&
    COURSE_INTERNAL_LABELS.includes(targetProduct.internal_label);

  const isActivity =
    targetProduct &&
    ACTIVITY_INTERMAL_LABELS.includes(targetProduct.internal_label);

  return (
    <Wrapper>
      <AdminLineDivider title="系統操作" id="root_custom-system-actions" />

      <Button
        style={{marginTop: 5, marginBottom: 5, marginRight: 15}}
        onClick={() => importMembersList(instance)}>
        匯入實體名單
      </Button>

      <Button
        style={{marginTop: 5, marginBottom: 5, marginRight: 15}}
        onClick={() => importOnlineMembersList(instance)}>
        匯入線上名單
      </Button>

      <Popconfirm
        title="確認按照「姓氏筆劃」排序實體及線上簽到表嗎？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          await sortMembersByFirstName(instance, values, setValues);
          await sortOnlineMembersByFirstName(instance, values, setValues);
        }}>
        <Button style={{marginTop: 5, marginBottom: 10, marginRight: 15}}>
          排序簽到表(姓氏)
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認「全選」實體已出席嗎？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          try {
            setValues(
              await JStorage.updateDocument(
                'sign_form',
                {
                  id: instance.id,
                },
                {
                  ...values,
                  members: values.members.map((m) => ({
                    ...m,
                    is_attend: true,
                  })),
                },
              ),
            );

            message.success('實體已出席全選完成');
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
        }}>
        <Button style={{marginTop: 5, marginBottom: 5, marginRight: 15}}>
          全選實體已出席
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認「全選」線上已出席嗎？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          try {
            setValues(
              await JStorage.updateDocument(
                'sign_form',
                {
                  id: instance.id,
                },
                {
                  ...values,
                  online_members: values.online_members.map((m) => ({
                    ...m,
                    is_attend: true,
                  })),
                },
              ),
            );

            message.success('線上已出席全選完成');
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
        }}>
        <Button style={{marginTop: 5, marginBottom: 5, marginRight: 15}}>
          全選線上已出席
        </Button>
      </Popconfirm>

      <Button
        style={{marginTop: 5, marginBottom: 5, marginRight: 15}}
        onClick={() => {
          showAdminMuxPermissionApplyModal(instance);
        }}>
        授權直播觀看權限
      </Button>

      <Button
        style={{marginTop: 5, marginBottom: 5, marginRight: 15}}
        onClick={() =>
          AppActions.navigate(
            `/admin/live_stream_process?action=detail&signform=${instance.id}&product=${targetProduct.id}`,
          )
        }>
        查看直播紀錄
      </Button>

      <Popconfirm
        title="確認實體發放進修時數？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          if (!permissionRequired('/admin/sign_forms/_write_post_hours')) {
            message.warning('您無此權限');
            return;
          }

          await postTbaHoursToMembers(instance, values, setValues);
        }}>
        <Button
          type="primary"
          style={{marginTop: 5, marginBottom: 10, marginRight: 15}}>
          發放實體進修時數
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認發放線上進修時數？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          if (
            !permissionRequired('/admin/sign_forms/_write_online_post_hours')
          ) {
            message.warning('您無此權限');
            return;
          }

          await postTbaHoursToOnlineMembers(instance, values, setValues);
        }}>
        <Button
          type="primary"
          style={{marginTop: 5, marginBottom: 10, marginRight: 15}}>
          發放線上進修時數
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認批次創建給付紀錄？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => await createPayBillingRecords(targetProduct)}>
        <Button
          type="primary"
          style={{marginTop: 5, marginBottom: 10, marginRight: 15}}>
          批次創建給付紀錄
        </Button>
      </Popconfirm>

      <AdminLineDivider title="資料下載" id="root_custom-system-downloads" />

      {isCourse && (
        <AdminDownloadExcelButton
          buttonStyle={{marginTop: 5, marginBottom: 10, marginRight: 15}}
          title="下載實體課程簽到表"
          collection="sign_form"
          query={{id: instance.id}}
          filename={`${targetProduct?.name}_實體課程簽到表.xlsx`}
          updateWorkSheet={(ws) => adminCourseUpdateWorkSheet(ws)}
          recordsToAoa={async (records) =>
            adminCourseRecordsToAoa({
              instance,
              people: records[0].people,
              members: records[0].members,
            })
          }
        />
      )}

      {isActivity && (
        <AdminDownloadExcelButton
          buttonStyle={{marginTop: 5, marginBottom: 10, marginRight: 15}}
          title="下載實體活動簽到表"
          collection="sign_form"
          query={{id: instance.id}}
          filename={`${targetProduct?.name}_實體活動簽到表.xlsx`}
          updateWorkSheet={(ws) => adminActivityUpdateWorkSheet(ws)}
          recordsToAoa={async (records) =>
            adminActivityRecordsToAoa({
              instance,
              people: records[0].people,
              members: records[0].members,
            })
          }
        />
      )}

      <AdminDownloadExcelButton
        buttonStyle={{marginTop: 5, marginBottom: 10, marginRight: 15}}
        title="下載實體參加名單"
        collection="sign_form"
        query={{id: instance.id}}
        filename={`${targetProduct?.name}_參加名單.xlsx`}
        updateWorkSheet={(ws) => adminParticipantListWorkSheet(ws)}
        recordsToAoa={async (records) =>
          adminParticipantListRecordsToAoa({
            instance,
            people: records[0].people,
            members: records[0].members,
          })
        }
      />

      {isCourse && (
        <AdminDownloadExcelButton
          buttonStyle={{marginTop: 5, marginBottom: 10, marginRight: 15}}
          title="下載線上課程簽到表"
          collection="sign_form"
          query={{id: instance.id}}
          filename={`${targetProduct?.name}_線上課程簽到表.xlsx`}
          updateWorkSheet={(ws) => adminCourseUpdateWorkSheet(ws)}
          recordsToAoa={async (records) =>
            adminCourseRecordsToAoa({
              instance,
              people: records[0].people,
              members: records[0].online_members,
            })
          }
        />
      )}

      {isActivity && (
        <AdminDownloadExcelButton
          buttonStyle={{marginTop: 5, marginBottom: 10, marginRight: 15}}
          title="下載線上活動簽到表"
          collection="sign_form"
          query={{id: instance.id}}
          filename={`${targetProduct?.name}_線上活動簽到表.xlsx`}
          updateWorkSheet={(ws) => adminActivityUpdateWorkSheet(ws)}
          recordsToAoa={async (records) =>
            adminActivityRecordsToAoa({
              instance,
              people: records[0].people,
              members: records[0].online_members,
            })
          }
        />
      )}

      <Button
        style={{marginTop: 5, marginBottom: 5, marginRight: 15}}
        href="/downloads/signform-import.xlsx"
        target="_blank"
        download="signform-import.xlsx">
        下載匯入名單模板
      </Button>
    </Wrapper>
  );
}

async function sortMembersByFirstName(instance, values, setValues) {
  const _members = instance.members.sort((a, b) =>
    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
  );

  AppActions.setLoading(true);
  try {
    await JStorage.updateDocument(
      'sign_form',
      {id: instance.id},
      {members: _members},
    );

    setValues({...values, members: _members});
    message.success('按照姓氏筆畫排序完成');
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function sortOnlineMembersByFirstName(instance, values, setValues) {
  const _online_members = instance.online_members.sort((a, b) =>
    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
  );

  AppActions.setLoading(true);
  try {
    await JStorage.updateDocument(
      'sign_form',
      {id: instance.id},
      {online_members: _online_members},
    );

    setValues({...values, online_members: _online_members});
    message.success('按照姓氏筆畫排序完成');
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function postTbaHoursToMembers(instance, values, setValues) {
  AppActions.setLoading(true);

  try {
    const isAttendAndQualifiedUsers = values.members.filter(
      (member) => (member) =>
        member.is_attend &&
        member.user_type === 'member' &&
        (member.substate === '4' || member.substate === '5') &&
        (member.state === 1 || member.state === 4),
    );

    if (isAttendAndQualifiedUsers.length === 0) {
      AppActions.setLoading(false);
      message.error(
        `已出席且符合發放資格（一般會員、特別會員、實習律師）的人數需在 0 人以上。`,
      );
      return;
    }

    await ApiUtil.req(
      `${DataJson.apiHost}/tba/sign_form/close?token=${
        getOutlet('user').getValue().token
      }&sign_form_id=${values.id}`,
      {
        method: 'POST',
        data: {},
      },
    );

    await JStorage.updateDocument(
      'sign_form',
      {id: values.id},
      {...values, is_posted_hours: true},
    );

    setValues({...values, is_posted_hours: true});

    message.success('成功發放實體進修時數');
    setTimeout(() => window.location.reload(), 1000);
  } catch (err) {
    console.warn(err);
    message.err('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function postTbaHoursToOnlineMembers(instance, values, setValues) {
  AppActions.setLoading(true);

  try {
    const isAttendAndQualifiedUsers = values.online_members.filter(
      (member) => (member) =>
        member.is_attend &&
        member.user_type === 'member' &&
        (member.substate === '4' || member.substate === '5') &&
        (member.state === 1 || member.state === 4),
    );

    if (isAttendAndQualifiedUsers.length === 0) {
      AppActions.setLoading(false);
      message.error(
        `已出席且符合發放資格（一般會員、特別會員、實習律師）的人數需在 0 人以上。`,
      );
      return;
    }

    await AppActionsEx.bulkwritePostTbaHours({
      product_id: values.product_id,
      users_id: isAttendAndQualifiedUsers.map((u) => u.owner),
    });

    await JStorage.updateDocument(
      'sign_form',
      {id: values.id},
      {...values, is_online_posted_hours: true},
    );

    setValues({...values, is_online_posted_hours: true});

    message.success('成功發放線上進修時數');
    setTimeout(() => window.location.reload(), 1000);
  } catch (err) {
    console.warn(err);
    message.err('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function createPayBillingRecords(targetProduct) {
  try {
    AppActions.setLoading(true);

    const actions = [];

    const session = targetProduct.session;
    const hasSessionPeople =
      targetProduct.session &&
      Object.keys(targetProduct.session).length > 0 &&
      targetProduct.session.people &&
      targetProduct.session.people.length > 0;

    if (hasSessionPeople) {
      let targetUsers = [];
      let targetCompanyCurrents = [];

      const hasUsers =
        session.people.filter((person) => !!person.user).length > 0;

      if (hasUsers) {
        const owners = session.people
          .filter((p) => !!p.user)
          .map((p) => p.user);

        targetUsers = await JStorage.fetchAllDocuments('user_profile', {
          owner: {$in: owners},
        });

        const hasCompanyCurrents =
          targetUsers.filter((u) => !!u.company_current).length > 0;

        if (hasCompanyCurrents) {
          const ids = targetUsers
            .filter((u) => !!u.company_current)
            .map((u) => ({$oid: u.company_current}));

          targetCompanyCurrents = await JStorage.fetchAllDocuments('Company', {
            _id: {$in: ids},
          });
        }
      }

      for (let person of session.people) {
        let data = {
          product_id: targetProduct.id,
          name: person.name,
          type: person.type || '',
          pay_type: (() => {
            if (person.type === '主持人') {
              return '車馬費';
            } else if (person.type === '與談人') {
              return '車馬費';
            } else if (person.type === '講師') {
              return '講師費';
            } else {
              return '';
            }
          })(),
          content: `${targetProduct.name || ''} ${person.type || ''}`,
          payoff_date: new Date().toLocaleDateString('sv'),
          //TODO: payoff logic confirm
          payoff_amount: 0, //給付總額
          payoff_total: person.pay || 0, //給付淨額
          tax: 0, //扣繳稅額
        };

        if (!!person.user) {
          let targetUser = targetUsers.find((u) => u.owner === person.user);
          let targetCompanyCurrent = null;
          let address = {};

          if (targetUser.company_current) {
            targetCompanyCurrent = targetCompanyCurrents.find(
              (c) => c.id === targetUser.company_current,
            );
          }

          data = {
            ...data,
            user: person.user || '',
            name: targetUser ? targetUser.name : person.name || '',
            id_card_number: targetUser ? targetUser.id_card_number : '',
            admin_creator: UserOutlet.getValue().username,
            admin_updater: UserOutlet.getValue().username,
            bankbook_image: person.bankbook_image || '',
            bank_name: person.bank_name || targetUser?.bankcode_name || '',
            bank_account: person.bank_account || targetUser?.BANK_ACCNO || '',
            bank_account_name:
              person.bank_account_name || targetUser?.BANK_ACCNAME || '',
          };

          const hasMailAddress =
            targetUser.mail_address_zip_code &&
            targetUser.mail_address_city &&
            targetUser.mail_address_detail;

          const hasCompanyAddress =
            targetUser.company_zip_code &&
            targetUser.company_city &&
            targetUser.company_detail;

          const hasCompanyCurrent = !!targetUser.company_current;

          const hasResidenceAddress =
            targetUser.residence_address_zip_code &&
            targetUser.residence_address_city &&
            targetUser.residence_address_detail;

          //律師法第26條陳明收受送達處所
          if (hasMailAddress) {
            address.zip_code = targetUser.mail_address_zip_code;
            address.city = targetUser.mail_address_city;
            address.detail = targetUser.mail_address_detail;

            data = {
              ...data,
              address_type: 'mail',
              mail_address_zip_code: address.zip_code,
              mail_address_city: address.city,
              mail_address_detail: address.detail,
              send_address_zip_code: address.zip_code,
              send_address_city: address.city,
              send_address_detail: address.detail,
            };

            actions.push({
              method: 'insert_one',
              payload: {query: {}, data, option: {}},
              option: {},
            });

            continue;
          }

          //前台事務所顯示
          if (hasCompanyAddress) {
            address.zip_code = targetUser.company_zip_code;
            address.city = targetUser.company_city;
            address.detail = targetUser.company_detail;

            data = {
              ...data,
              address_type: 'company',
              mail_address_zip_code: address.zip_code,
              mail_address_city: address.city,
              mail_address_detail: address.detail,
              send_address_zip_code: address.zip_code,
              send_address_city: address.city,
              send_address_detail: address.detail,
            };

            actions.push({
              method: 'insert_one',
              payload: {query: {}, data, option: {}},
              option: {},
            });

            continue;
          }

          //現在事務所顯示
          if (hasCompanyCurrent) {
            address.zip_code = targetCompanyCurrent.address_zip_code;
            address.city = targetCompanyCurrent.address_city;
            address.detail = targetCompanyCurrent.address_detail;

            data = {
              ...data,
              address_type: 'company_current',
              mail_address_zip_code: address.zip_code,
              mail_address_city: address.city,
              mail_address_detail: address.detail,
              send_address_zip_code: address.zip_code,
              send_address_city: address.city,
              send_address_detail: address.detail,
            };

            actions.push({
              method: 'insert_one',
              payload: {query: {}, data, option: {}},
              option: {},
            });

            continue;
          }

          //戶籍地址
          if (hasResidenceAddress) {
            address.zip_code = targetUser.residence_address_zip_code;
            address.city = targetUser.residence_address_city;
            address.detail = targetUser.residence_address_detail;

            data = {
              ...data,
              address_type: 'residence',
              mail_address_zip_code: address.zip_code,
              mail_address_city: address.city,
              mail_address_detail: address.detail,
              send_address_zip_code: address.zip_code,
              send_address_city: address.city,
              send_address_detail: address.detail,
            };

            actions.push({
              method: 'insert_one',
              payload: {query: {}, data, option: {}},
              option: {},
            });

            continue;
          }
        }

        data = {
          ...data,
          mail_address_zip_code: '',
          mail_address_city: '',
          mail_address_detail: '',
          send_address_zip_code: '',
          send_address_city: '',
          send_address_detail: '',
        };

        actions.push({
          method: 'insert_one',
          payload: {query: {}, data, option: {}},
          option: {},
        });
      }

      await JStorage.bulkWriteDocuments('pay_billing', actions);
      message.success('創建成功');
    }
  } catch (err) {
    console.warn(err);
    message.err('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function importMembersList(instance) {
  getOutlet('admin-import-excel-modal').update({
    title: '匯入實體名單',
    importRecords: async (records) => {
      //step1: check import file have data
      if (records.length === 0) {
        window.alert('匯入新增檔案沒有資料');
      }

      //step2: check import data required
      const FIELDS = {
        name: '姓名',
        id_card_number: '身分證字號',
        is_attend: '已出席',
      };

      const REQUIRED = ['name', 'is_attend'];

      const _records = records.map((r) => {
        const output = {};
        for (const k in FIELDS) {
          output[k] = r[FIELDS[k]];
        }
        return output;
      });

      const isNotRequired =
        _records.filter((it) => {
          for (const k of REQUIRED) {
            if (!it[k]) {
              return true;
            }
          }
          return false;
        }).length > 0;

      if (isNotRequired) {
        const display = REQUIRED.map((r) => FIELDS[r]).join();
        const text = `部分資料格式有誤，動作已暫停！\n${display} 為必填欄位`;
        message.warning(text);
        return;
      }

      //step3: check import data user exists
      const uprs = await JStorage.fetchAllDocuments(
        'user_profile',
        {
          name: {
            $in: _records.map((r) => r.name),
          },
        },
        null,
        {owner: 1, id_card_number: 1, name: 1},
      );

      const importRecords = _records.map((r) => {
        const targetUsers = uprs.filter((u) => u.name === r.name);

        if (targetUsers.length === 1) {
          return {
            ...r,
            owner: targetUsers[0].owner,
            is_attend: r.is_attend === '是',
          };
        }

        if (targetUsers.length > 1 && !r.id_card_number) {
          return {
            ...r,
            owner: targetUsers[0].owner,
            is_attend: r.is_attend === '是',
            is_same_name: true,
          };
        }

        if (targetUsers.length > 1 && r.id_card_number) {
          const targetUser = targetUsers.find(
            (u) => u.id_card_number === r.id_card_number,
          );

          if (targetUser) {
            return {
              ...r,
              owner: targetUser.owner,
              is_attend: r.is_attend === '是',
            };
          }

          return {
            ...r,
            owner: undefined,
            is_attend: r.is_attend === '是',
            is_same_name: true,
          };
        }

        return {...r, is_attend: r.is_attend === '是'};
      });

      const isNotExistsUsers = importRecords.filter((r) => !r.owner);

      if (isNotExistsUsers.length > 0) {
        const display = isNotExistsUsers.map((r) => r.name).join();
        const text = `部分資料有誤，動作已暫停！\n${display} 不是系統會員`;
        message.warning(text);
        return;
      }

      //step4: check import data user name
      const isSameNameUsers = importRecords.filter((r) => r.is_same_name);

      if (isSameNameUsers.length > 0) {
        const display = isSameNameUsers.map((r) => r.name).join();
        const text = `部分資料有誤，動作已暫停！\n${display} 系統有同名同姓的會員，請在匯入檔案內，加上正確的身分證字號辨識`;
        message.warning(text);
        return;
      }

      const confirmText = `確認匯入共 ${_records.length} 筆至實體名單嗎?`;
      const isConfirmImport = window.confirm(confirmText);

      if (isConfirmImport) {
        AppActions.setLoading(true);

        try {
          const nextMembers = [
            ...instance.members,
            ...importRecords.map((r) => ({
              ...r,
              vegetarians: [],
              insurances: [],
              is_points_discount: false,
              points: 0,
              pay_time: 0,
              tba_seat: 1,
              total: 0,
              is_auto_created: true,
            })),
          ];

          await JStorage.updateDocument(
            'sign_form',
            {id: instance.id},
            {members: nextMembers},
          );

          message.success('匯入成功');
          setTimeout(() => window.location.reload(), 1000);
        } catch (err) {
          console.warn(err);
          message.error('發生錯誤');
        } finally {
          AppActions.setLoading(false);
        }
      }
    },
  });
}

async function importOnlineMembersList(instance) {
  getOutlet('admin-import-excel-modal').update({
    title: '匯入線上名單',
    importRecords: async (records) => {
      //step1: check import file have data
      if (records.length === 0) {
        window.alert('匯入新增檔案沒有資料');
      }

      //step2: check import data required
      const FIELDS = {
        name: '姓名',
        id_card_number: '身分證字號',
        is_attend: '已出席',
      };

      const REQUIRED = ['name', 'is_attend'];

      const _records = records.map((r) => {
        const output = {};
        for (const k in FIELDS) {
          output[k] = r[FIELDS[k]];
        }
        return output;
      });

      const isNotRequired =
        _records.filter((it) => {
          for (const k of REQUIRED) {
            if (!it[k]) {
              return true;
            }
          }
          return false;
        }).length > 0;

      if (isNotRequired) {
        const display = REQUIRED.map((r) => FIELDS[r]).join();
        const text = `部分資料格式有誤，動作已暫停！\n${display} 為必填欄位`;
        message.warning(text);
        return;
      }

      //step3: check import data user exists
      const uprs = await JStorage.fetchAllDocuments(
        'user_profile',
        {
          name: {
            $in: _records.map((r) => r.name),
          },
        },
        null,
        {owner: 1, id_card_number: 1, name: 1},
      );

      const importRecords = _records.map((r) => {
        const targetUsers = uprs.filter((u) => u.name === r.name);

        if (targetUsers.length === 1) {
          return {
            ...r,
            owner: targetUsers[0].owner,
            is_attend: r.is_attend === '是',
          };
        }

        if (targetUsers.length > 1 && !r.id_card_number) {
          return {
            ...r,
            owner: targetUsers[0].owner,
            is_attend: r.is_attend === '是',
            is_same_name: true,
          };
        }

        if (targetUsers.length > 1 && r.id_card_number) {
          const targetUser = targetUsers.find(
            (u) => u.id_card_number === r.id_card_number,
          );

          if (targetUser) {
            return {
              ...r,
              owner: targetUser.owner,
              is_attend: r.is_attend === '是',
            };
          }

          return {
            ...r,
            owner: undefined,
            is_attend: r.is_attend === '是',
            is_same_name: true,
          };
        }

        return {...r, is_attend: r.is_attend === '是'};
      });

      const isNotExistsUsers = importRecords.filter((r) => !r.owner);

      if (isNotExistsUsers.length > 0) {
        const display = isNotExistsUsers.map((r) => r.name).join();
        const text = `部分資料有誤，動作已暫停！\n${display} 不是系統會員`;
        message.warning(text);
        return;
      }

      //step4: check import data user name
      const isSameNameUsers = importRecords.filter((r) => r.is_same_name);

      if (isSameNameUsers.length > 0) {
        const display = isSameNameUsers.map((r) => r.name).join();
        const text = `部分資料有誤，動作已暫停！\n${display} 系統有同名同姓的會員，請在匯入檔案內，加上正確的身分證字號辨識`;
        message.warning(text);
        return;
      }

      const confirmText = `確認匯入共 ${_records.length} 筆至線上名單嗎?`;
      const isConfirmImport = window.confirm(confirmText);

      if (isConfirmImport) {
        AppActions.setLoading(true);

        try {
          const nextOnlineMembers = [
            ...instance.online_members,
            ...importRecords.map((r) => ({
              ...r,
              vegetarians: [],
              insurances: [],
              is_points_discount: false,
              points: 0,
              pay_time: 0,
              tba_seat: 0,
              total: 0,
              is_auto_created: true,
            })),
          ];

          await JStorage.updateDocument(
            'sign_form',
            {id: instance.id},
            {online_members: nextOnlineMembers},
          );

          message.success('匯入成功');
          setTimeout(() => window.location.reload(), 1000);
        } catch (err) {
          console.warn(err);
          message.error('發生錯誤');
        } finally {
          AppActions.setLoading(false);
        }
      }
    },
  });
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export default AdminSignFormActions;
