import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Alert} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import AdminSelectUserCustomWidget from './AdminSelectUserCustomWidget';

function getAfterMonthsDate({start, afterMonths}) {
  const startYear = new Date(start).getFullYear();
  const startMonth = new Date(start).getMonth();
  const startDate = new Date(start).getDate();

  const monthTable = [];

  monthTable.push({
    year: startYear,
    month: startMonth + 1,
    date: new Date(startYear, startMonth + 1, 0).getDate() - startDate,
  });

  for (let i = 0; i < afterMonths; i++) {
    if (startMonth + 1 < 12) {
      const year = startYear;
      const month = startMonth + 1 + i + 1;
      const date = new Date(year, month, 0).getDate();

      monthTable.push({year, month, date});
    } else if (startMonth + 1 === 12) {
      const year = startYear + 1;
      const month = 1 + i;
      const date = new Date(year, month, 0).getDate();

      monthTable.push({year, month, date});
    }
  }

  if (monthTable.length > 1) {
    monthTable[monthTable.length - 1].date = startDate - 1;
  }

  const days = monthTable.reduce((acc, cur) => acc + cur.date, 0);
  const totalMS = new Date(start).getTime() + days * 24 * 60 * 60 * 1000;

  return new Date(totalMS).toLocaleDateString('sv');
}

function AdminMoraleCustomFields(props) {
  const [config] = useOutlet('config');
  const moraleStatusCategory = config.morale_status_category || [];
  const {context} = props;
  const {instance, values, setValues} = context;

  const isFinished = instance && instance.status === '已結案';
  const isOccurDateOverThreeYears =
    instance &&
    instance.occur_date &&
    new Date().getTime() >=
      new Date(instance.occur_date).getTime() + 365 * 3 * 24 * 60 * 60 * 1000;
  const isOccurDateOverSevenYears =
    instance &&
    instance.occur_date &&
    new Date().getTime() >=
      new Date(instance.occur_date).getTime() + 365 * 7 * 24 * 60 * 60 * 1000;

  const rjsfProps = {
    widgets: {'admin-select-user-custom-widget': AdminSelectUserCustomWidget},
  };

  return (
    <Wrapper>
      {(isOccurDateOverThreeYears || isOccurDateOverSevenYears) && !isFinished && (
        <Alert
          showIcon
          type="warning"
          description={
            <div>
              本案「違紀行為時點（粗估供參）」時間點，距今已超過
              {isOccurDateOverSevenYears ? '七' : '三'}年。
            </div>
          }
          style={{marginTop: 10, marginBottom: 10}}
        />
      )}

      <GenericForm
        rjsfProps={rjsfProps}
        instance={{
          case_number_prefix: values.case_number_prefix,
          case_number: values.case_number,
          status: values.status,
          status_note: values.status_note,
          prosecutors: values.prosecutors,
          prosecutors_normal: values.prosecutors_normal,
          defendants: values.defendants,
          defendants_normal: values.defendants_normal,
          investigator_type: values.investigator_type,
          investigators: values.investigators,
          start_date: values.start_date,
          investigator_report_deadline: values.investigator_report_deadline,
          investigator_report_deadline_extend:
            values.investigator_report_deadline_extend,
        }}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            'rev-line-0': {
              type: 'string',
              title: '案號',
            },
            case_number_prefix: {
              type: 'string',
              title: '案號前綴',
              enum: ['北律倫調字', '北律倫特調字'],
            },
            case_number: {
              type: 'integer',
              title: '案號',
            },
            'rev-line-1': {
              type: 'string',
              title: '案件進度',
            },
            status: {
              title: '案件辦理進度',
              type: 'string',
              default: '已分案',
              enum: moraleStatusCategory,
            },
            status_note: {
              type: 'string',
              title: '進度備註',
            },
            'rev-line-2': {
              type: 'string',
              title: '當事人',
            },
            prosecutors: {
              title: '申訴人（移送機關團體）（立案調查）會員',
              type: 'array',
              items: {
                type: 'string',
              },
            },
            prosecutors_normal: {
              title: '申訴人（移送機關團體）（立案調查）非會員',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: '姓名',
                  },
                },
              },
            },
            defendants: {
              title: '被申訴律師（受調查對象）',
              type: 'array',
              items: {
                type: 'string',
              },
            },
            defendants_normal: {
              title: '被申訴律師（受調查對象）非會員',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: '姓名',
                  },
                },
              },
            },
            'rev-line-3': {
              type: 'string',
              title: '調查小組',
            },
            investigator_type: {
              title: '調查小組類別',
              type: 'string',
              enum: ['調查小組', '特別調查小組'],
            },
            investigators: {
              title: '調查小組',
              type: 'array',
              items: {
                type: 'object',
                title: '',
                properties: {
                  responsibility: {
                    type: 'string',
                    title: '擔任工作',
                    enum: ['召集人', '調查委員'],
                  },
                  user: {
                    type: 'string',
                    title: '會員',
                  },
                },
              },
            },
            'rev-line-4': {
              type: 'string',
              title: '受理日期暨報告提出期限',
            },
            start_date: {
              title: '受理日期',
              type: 'string',
              format: 'date',
            },
            investigator_report_deadline: {
              title: '調查小組應提出調查報告期限',
              type: 'string',
              format: 'date',
            },
            investigator_report_deadline_extend: {
              title: '延長後之調查報告提出期限',
              type: 'string',
              format: 'date',
            },
          },
        }}
        uiSchema={{
          prosecutors: {
            items: {
              'ui:widget': 'admin-select-user-custom-widget',
            },
          },
          defendants: {
            items: {
              'ui:widget': 'admin-select-user-custom-widget',
            },
          },
          investigators: {
            items: {
              user: {
                'ui:widget': 'admin-select-user-custom-widget',
              },
            },
          },
          investigator_report_deadline: {
            'ui:help': '系統會依「受理日期」自動產出本欄日期，請再確認是否正確',
          },
          investigator_report_deadline_extend: {
            'ui:help': '系統會依「受理日期」自動產出本欄日期，請再確認是否正確',
          },
          status_note: {
            'ui:widget': 'textarea',
          },
          'rev-line-0': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
          'rev-line-1': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
          'rev-line-2': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
          'rev-line-3': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
          'rev-line-4': {
            'ui:widget': 'rev-line',
            'ui:options': {
              label: false,
            },
          },
        }}
        onChange={(formData) => {
          const suggestion_data = {};

          if (formData.start_date) {
            if (values.start_date !== formData.start_date) {
              suggestion_data.investigator_report_deadline = getAfterMonthsDate(
                {start: formData.start_date, afterMonths: 6},
              );

              suggestion_data.investigator_report_deadline_extend = getAfterMonthsDate(
                {start: formData.start_date, afterMonths: 12},
              );
            }
          } else {
            suggestion_data.investigator_report_deadline = '';
            suggestion_data.investigator_report_deadline_extend = '';
          }

          setValues({...values, ...formData, ...suggestion_data});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .submit-buttons-bar {
    border: 1px solid red;
    display: none;
  }
`;

export default AdminMoraleCustomFields;
