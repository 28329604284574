import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';

export default function AdminVegetarianField(props) {
  const {value, onChange, schema, formData} = props;

  return (
    <Wrapper>
      <div className="rev-row">
        <label>姓名</label>
        <input
          type="text"
          value={formData.name || ''}
          onChange={(e) => {
            onChange({...formData, name: e.target.value});
          }}
        />
        <label>素食</label>
        <input
          checked={formData.is_vegetarian}
          type="checkbox"
          onChange={(e) => {
            onChange({...formData, is_vegetarian: e.target.checked});
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-bottom: 1px solid #ccc;

  .rev-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;

    & label {
      margin-right: 5px;
      &:not(first-child) {
        margin-left: 5px;
      }
    }

    & input[type='text'] {
      width: 100px;
    }
  }
`;
