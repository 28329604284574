import React from 'react';
import styled from 'styled-components';
import {Button, Tag} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {useOutlet} from 'reconnect.js';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import {showAdminSearchAnnualFeeOrderModal} from '../../Templates/AdminSearchAnnualFeeOrderModal';

export default function AdminSelectAnnualFeeOrderWidget(props) {
  const [selectedCheckout, setSelectedCheckout] = React.useState(null);
  const [targetUser, setTargetUser] = React.useState(null);
  const [users] = useOutlet('users');

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  const fetchCheckoutByOrderNumber = React.useCallback(
    async (order_number) => {
      try {
        const checkout = await JStorage.fetchOneDocument('checkout', {
          order_number,
        });

        const targetUser = users.find((user) => user.owner === checkout.owner);

        setSelectedCheckout(checkout);
        setTargetUser(targetUser);
      } catch (err) {
        console.warn(err);
      }
    },
    [users],
  );

  React.useEffect(() => {
    if (props.value) {
      fetchCheckoutByOrderNumber(props.value);
    }
  }, [props.value, fetchCheckoutByOrderNumber]);

  return (
    <Wrapper>
      <div className="info">
        {!props.value ? (
          `尚未設定訂單`
        ) : !selectedCheckout || !targetUser ? (
          props.value
        ) : (
          <div>
            <div style={{display: 'flex'}}>
              <Button
                type="link"
                target="_blank"
                title="點選開啟新分頁編輯"
                className="link-button"
                href={`/admin/checkout?action=detail&id=${selectedCheckout.id}&backPath=/admin/orders/export`}>
                {selectedCheckout.order_number}{' '}
                {selectedCheckout.items[0].product.name}
              </Button>
              <span>
                {generatePaymentStatusTag(selectedCheckout.payment_status)}
              </span>
            </div>
            <div>
              {targetUser.id_card_number} {selectedCheckout.buyer_name}{' '}
              {getUserStateLabel(targetUser?.state)}{' '}
              {getUserSubstateLabel(targetUser.substate)}
            </div>
            <div>
              {selectedCheckout.annual_year} 年度{' '}
              {selectedCheckout.start_month || 1}月 -
              {selectedCheckout.end_month || 12}月 {selectedCheckout.total} 元
            </div>
          </div>
        )}
      </div>
      {!readonly && !selectedCheckout && (
        <div>
          <Button
            type="primary"
            style={{margin: 5}}
            onClick={() => {
              showAdminSearchAnnualFeeOrderModal({
                owner: selectedCheckout?.owner || null,
                year: selectedCheckout?.annual_year || '',
                name: selectedCheckout?.buyer_name || '',
                onChange: (order_number) => {
                  props.onChange(order_number);
                },
              });
            }}>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

function generatePaymentStatusTag(value) {
  const display = Cart.PAYMENT_STATUS_DISPLAY[value]?.label;

  if (value === 'success') {
    return <Tag color="success">{display}</Tag>;
  }

  return <Tag>{display}</Tag>;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }

  & > .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: end;
  }

  .link-button {
    max-width: 400px;
    width: 100%;
    white-space: pre-wrap;
    text-align: left;
    padding-left: 0px;
    font-size: 0.9rem;
    font-weight: 400px;
    line-height: 1.5rem;
    height: auto;
    min-height: 32px;
  }
`;
