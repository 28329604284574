import React from 'react';
import styled from 'styled-components';
import {Form, Input} from 'antd';

export function MoraleExtraQueries(props) {
  const {setQueryState, queryState} = props;
  const [caseNumber, setCaseNumber] = React.useState(null);

  const onSubmit = () => {
    if (!caseNumber) {
      setQueryState((prev) => ({
        ...prev,
        extraQueries: {
          ...prev.extraQueries,
          case_number: undefined,
        },
      }));

      return;
    } else {
      const _extraQueries = {};
      _extraQueries['case_number'] = Number(caseNumber);

      setQueryState((prev) => {
        const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};
        const filterOutQueryKeys = Object.keys(prev.extraQueries)
          .map((q) => {
            if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
              return q;
            } else {
              return null;
            }
          })
          .filter((k) => !!k);

        filterOutQueryKeys.forEach((k) => {
          delete nextExtraQuery[k];
        });

        return {
          ...prev,
          extraQueries: nextExtraQuery,
        };
      });
    }
  };

  return (
    <Wrapper>
      <div className="field">
        <label>案號</label>
        <Input.Search
          style={{width: 150}}
          value={caseNumber}
          onChange={(e) => setCaseNumber(e.target.value)}
          onSearch={onSubmit}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .field {
    display: flex;
    align-items: center;
  }

  & > .field > label {
    margin-right: 10px;
  }
`;
