import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons';
import * as AppActions from '../../AppActions';

function AdminMoraleNavigation(props) {
  const {context} = props;

  const _lineWidgetsByCustomFields = [
    {id: 'root_rev-line-0', title: '案號'},
    {id: 'root_rev-line-1', title: '案件進度'},
    {id: 'root_rev-line-2', title: '當事人'},
    {id: 'root_rev-line-3', title: '調查小組'},
    {id: 'root_rev-line-4', title: '受理日期暨報告提出期限'},
  ];

  const _lineWidgets = Object.keys(context.formSpec.uiSchema).filter((key) => {
    return context.formSpec.uiSchema[key]['ui:widget'] === 'rev-line';
  });

  return (
    <Wrapper>
      {_lineWidgetsByCustomFields.map((item) => {
        return (
          <Button
            type="text"
            onClick={() => {
              const element = document.getElementById(item.id);
              if (element) {
                const rect = element.getBoundingClientRect();
                window.scrollTo({top: rect.y - 20});
              }
            }}>
            {item.title}
          </Button>
        );
      })}
      {_lineWidgets.map((key) => {
        const title = context.formSpec.schema.properties[key]?.title || key;
        return (
          <Button
            type="text"
            onClick={() => {
              const element = document.getElementById(`root_${key}`);
              if (element) {
                const rect = element.getBoundingClientRect();
                window.scrollTo({top: rect.y - 20});
              }
            }}>
            {title}
          </Button>
        );
      })}
      <Button
        type="text"
        onClick={() => {
          const element = document.querySelector('.related-resources');

          if (element) {
            const rect = element.getBoundingClientRect();
            window.scrollTo({top: rect.y - 20});
          }
        }}>
        相關會議/相關收發文
      </Button>
      <Button
        onClick={() => {
          window.scrollTo({top: 0});
        }}
        className="back-top-btn"
        size="large"
        shape="circle"
        icon={<ArrowUpOutlined style={{color: '#000000'}} />}
      />
      <Button
        onClick={() => {
          const element = document.getElementById('rev-admin-layout');

          if (element) {
            const rect = element.getBoundingClientRect();
            window.scrollTo({top: rect.height});
          }
        }}
        className="back-bottom-btn"
        size="large"
        shape="circle"
        icon={<ArrowDownOutlined style={{color: '#000000'}} />}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #f1f3f5;

  .back-top-btn {
    position: fixed;
    bottom: 120px;
    left: calc(1100px - 50px);
    z-index: 999;
    border: 1px solid var(--adminBorderColor);
    cursor: pointer;

    @media screen and (max-width: 1100px) {
      bottom: 200px;
      left: calc(100vw - 50px);
    }
  }

  .back-bottom-btn {
    position: fixed;
    bottom: 60px;
    left: calc(1100px - 50px);
    z-index: 999;
    border: 1px solid var(--adminBorderColor);
    cursor: pointer;

    @media screen and (max-width: 1100px) {
      bottom: 140px;
      left: calc(100vw - 50px);
    }
  }
`;

export default AdminMoraleNavigation;
