import React from 'react';
import {Button, message} from 'antd';
import Config from '../../../data.json';

export default function AdminNewsUrlCopyButton(props) {
  const {path, context, style} = props;

  let _copyUrl = async () => {
    let _url = `${Config.webHost}/announcement/?id=${context.instance.id}`;

    try {
      await navigator.clipboard.writeText(_url);
      message.success(`已複製URL連結`);
    } catch (err) {
      console.warn(err);
      message.warn(`無法複製URL連結`);
    }
  };

  if (!context.instance) {
    return null;
  }

  return (
    <Button type="primary" ghost onClick={_copyUrl} style={style}>
      取得URL連結
    </Button>
  );
}
