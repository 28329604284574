import React from 'react';
import styled from 'styled-components';
import {Alert} from 'antd';

function AdminMeetingNotification(props) {
  const {context} = props;

  return (
    <Wrapper>
      {context.instance &&
        context.instance.is_auto_created &&
        context.instance.is_auto_created_product && (
          <Alert
            style={{marginTop: 20, marginBottom: 20}}
            showIcon={true}
            type="warning"
            message={
              <div>
                本記錄由系統自動生成，欲更新會議室資訊請至
                <a
                  style={{marginRight: 5, marginLeft: 5}}
                  target="blank"
                  href={`/admin/products/?action=detail&id=${context.instance.is_auto_created_product}`}>
                  本連結
                </a>
                修改
              </div>
            }
          />
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminMeetingNotification;
