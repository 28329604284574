import React from 'react';
import styled from 'styled-components';
import {Table, Button, Popconfirm} from 'antd';
import {useOutlet} from 'reconnect.js';
import {getStaffValue} from '../../../Utils/StaffUtil';
import {JStorage} from 'rev.sdk.js';

const PAY_TYPE_LABEL = {
  CARD: '信用卡',
  VBA: '代收',
  POST: '劃撥',
  CASH: '現金',
  none: '無',
};

function DiscountItemsSection(props) {
  const [user] = useOutlet('user');
  const {instance, values, setValues} = props.context;

  return (
    <Wrapper>
      <Table
        columns={[
          {key: 'type', title: '減項類型', dataIndex: 'type', width: 90},
          {
            key: 'name',
            title: '減項名稱 / 備註',
            dataIndex: 'name',
            width: 150,
            render: (_, record) => {
              return (
                <div>
                  {record.name} {record.note ? `/ ${record.note}` : ''}
                </div>
              );
            },
          },
          {
            key: 'amount',
            title: '減項金額',
            dataIndex: 'amount',
            render: (_, record) => {
              return <span>- ${record.amount}</span>;
            },
          },
          {
            key: 'paid_info',
            title: '收款資訊',
            dataIndex: 'paid_info',
            render: (_, record) => {
              return (
                <div>
                  <div>
                    線下收款方式：
                    {record.payment_offline_method
                      ? record.payment_offline_method
                      : '---'}
                  </div>
                  <div>
                    收款日期：
                    {record.paid_date ? record.paid_date.split('T')[0] : '---'}
                  </div>
                  <div>收據編號：{record.rcpt_no ? record.rcpt_no : '---'}</div>
                  <div>
                    付款方式：
                    {record.pay_type ? PAY_TYPE_LABEL[record.pay_type] : '---'}
                  </div>
                </div>
              );
            },
          },
          {
            key: 'is_receipt',
            title: '收據',
            dataIndex: 'is_receipt',
            width: 60,
            render: (_, record) => {
              return (
                <span style={{fontSize: 14}}>
                  {record.is_receipt ? '已開' : '未開'}
                </span>
              );
            },
          },
          {
            key: 'action',
            title: '',
            dataIndex: 'action',
            width: 60,
            render: (_, record, index) => {
              return (
                <Popconfirm
                  title="確認開立收據?"
                  okText="確認"
                  cancelText="取消"
                  disabled={record.type !== 'admin'}
                  onConfirm={async () => {
                    try {
                      //step1: this discount_item update "is_receipt" true
                      const nextDiscountItems = [...values.discount_items];
                      nextDiscountItems[index].is_receipt = true;

                      await JStorage.updateDocument(
                        'checkout',
                        {
                          order_number: instance.order_number,
                        },
                        {discount_items: nextDiscountItems},
                      );

                      if (instance.order_id) {
                        await JStorage.updateDocument(
                          'order',
                          {
                            order_number: instance.order_number,
                          },
                          {discount_items: nextDiscountItems},
                        );
                      }

                      setValues({...values, nextDiscountItems});

                      //step2: detect checkout or order has buyer_address or not, if not, fetch user_profile to get address data

                      let upr = {};
                      if (
                        !values.buyer_zip ||
                        !values.buyer_city ||
                        !values.buyer_district ||
                        !values.buyer_address
                      ) {
                        upr = await JStorage.fetchOneDocument('user_profile', {
                          owner: instance.owner,
                        });
                      }

                      //step3: consist params data
                      const params = {
                        id: instance.id,
                        index,
                        staff: JSON.stringify({
                          username: user.username,
                          name: getStaffValue(user.username, 'name'),
                        }),
                        user: JSON.stringify({
                          owner: values.owner,
                          name: values.buyer_name || upr.name || '',
                          zip:
                            values.buyer_zip || upr.mail_address_zip_code || '',
                          city:
                            values.buyer_city && values.buyer_district
                              ? `${values.buyer_city}-${values.buyer_district}`
                              : upr.mail_address_city || '',
                          detail:
                            values.buyer_address ||
                            upr.mail_address_detail ||
                            '',
                        }),
                      };

                      const url = instance.order_id
                        ? `/print-preview?collection=order&id=${params.id}&type=receipt-discount-item&index=${params.index}&staff=${params.staff}&user=${params.user}`
                        : `/print-preview?collection=checkout&id=${params.id}&type=receipt-discount-item&index=${params.index}&staff=${params.staff}&user=${params.user}`;

                      window.open(url);
                    } catch (err) {
                      console.warn(err);
                    }
                  }}>
                  <Button
                    size="small"
                    type="primary"
                    ghost
                    disabled={record.type !== 'admin'}>
                    開立收據
                  </Button>
                </Popconfirm>
              );
            },
          },
        ]}
        dataSource={instance.discount_items}
        pagination={false}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default DiscountItemsSection;
