import React from 'react';
import {Button} from 'antd';
import {showAdminDownloadExcelModal} from './AdminDownloadExcelModal';

export default function DownloadExcelButton(props) {
  return (
    <Button
      style={{...props.buttonStyle}}
      onClick={() => showAdminDownloadExcelModal({...props})}>
      {props.title || '匯出Excel'}
    </Button>
  );
}
