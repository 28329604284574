import React from 'react';
import styled from 'styled-components';
import {Alert} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../../AppActions';

function AdminUserBillingAlert(props) {
  const {path, context} = props;
  const {values} = context;

  const [users] = useOutlet('users');
  const [records, setRecords] = React.useState(null);
  const [member, setMember] = React.useState({name: ''});

  React.useEffect(() => {
    async function fetchRecordsByAnnualYearAndOwner({annual_year, owner}) {
      try {
        AppActions.setLoading(true);

        let records = [];

        const checkouts = await JStorage.fetchAllDocuments(
          'checkout',
          {
            annual_year,
            owner,
          },
          null,
          {
            annual_year: 1,
            owner: 1,
            order_id: 1,
            order_number: 1,
            total: 1,
            payment_status: 1,
            start_month: 1,
            end_month: 1,
            buyer_name: 1,
          },
        );

        if (checkouts.length > 0) {
          const hasOrderIdCheckouts = checkouts.filter(
            (checkout) => !!checkout.order_id,
          );

          const orders = await JStorage.fetchAllDocuments(
            'order',
            {
              order_id: {
                $in: hasOrderIdCheckouts.map((checkout) => checkout.order_id),
              },
            },
            null,
            {
              annual_year: 1,
              owner: 1,
              order_id: 1,
              order_number: 1,
              total: 1,
              payment_status: 1,
              start_month: 1,
              end_month: 1,
              buyer_name: 1,
            },
          );

          for (let i = 0; i < checkouts.length; i++) {
            const order = orders.find(
              (o) => o.order_id === checkouts[i].order_id,
            );

            if (order) {
              records.push({...checkouts[i], ...order});
            } else {
              records.push({...checkouts[i]});
            }
          }
        }

        setRecords(records);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (values.annual_year && values.user_id) {
      fetchRecordsByAnnualYearAndOwner({
        annual_year: values.annual_year,
        owner: values.user_id,
      });
    }

    if (values.user_id) {
      const targetUser = users.find((user) => user.owner === values.user_id);

      if (targetUser) {
        setMember(targetUser);
      }
    }
  }, [values.annual_year, values.user_id, users, setMember]);

  return (
    <Wrapper>
      {records && Array.isArray(records) && records.length > 0 && (
        <Alert
          showIcon
          type="warning"
          description={
            <div>
              <div>
                請注意：{member.name}已有 {values.annual_year}年度
                常年會費訂單。
              </div>
              {records.map((r) => {
                return (
                  <div>
                    {r.order_id || `${r.order_number}*`} {r.annual_year}年{' '}
                    {r.start_month}月 - {r.end_month}月 ${r.total}{' '}
                    {Cart.PAYMENT_STATUS_DISPLAY[r.payment_status]?.label}
                  </div>
                );
              })}
            </div>
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default AdminUserBillingAlert;
