import React from 'react';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import AdminLineDivider from '../../Components/AdminLineDivider';

function AdminPayBillingActions(props) {
  const {values, setValues} = props.context;

  return (
    <Wrapper id="root_custom-system-actions">
      <AdminLineDivider title="系統操作" />
      <Popconfirm
        title="確認導入最新建檔資料？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          try {
            if (!!values.name) {
              const resp = await JStorage.fetchDocuments(
                'pay_billing',
                {
                  name: values.name,
                  id_card_number: {$exists: true},
                  mail_address_zip_code: {$exists: true},
                  mail_address_city: {$exists: true},
                  mail_address_detail: {$exists: true},
                  send_address_zip_code: {$exists: true},
                  send_address_city: {$exists: true},
                  send_address_detail: {$exists: true},
                },
                ['-created'],
                {offset: 0, limit: 1},
              );

              if (resp.total > 0) {
                const r = resp.results[0];

                setValues({
                  ...values,
                  user: r.user ? r.user : '',
                  id_card_number: r.id_card_number,
                  mail_address_zip_code: r.mail_address_zip_code,
                  mail_address_city: r.mail_address_city,
                  mail_address_detail: r.mail_address_detail,
                  send_address_zip_code: r.send_address_zip_code,
                  send_address_city: r.send_address_city,
                  send_address_detail: r.send_address_detail,
                });
              } else {
                message.warning('查無建檔資料');
              }

              return;
            }

            if (!!values.user) {
              const resp = await JStorage.fetchDocuments(
                'pay_billing',
                {
                  user: values.user,
                  id_card_number: {$exists: true},
                  mail_address_zip_code: {$exists: true},
                  mail_address_city: {$exists: true},
                  mail_address_detail: {$exists: true},
                  send_address_zip_code: {$exists: true},
                  send_address_city: {$exists: true},
                  send_address_detail: {$exists: true},
                },
                ['-created'],
                {offset: 0, limit: 1},
              );

              if (resp.total > 0) {
                const r = resp.results[0];

                setValues({
                  ...values,
                  name: r.name,
                  id_card_number: r.id_card_number,
                  mail_address_zip_code: r.mail_address_zip_code,
                  mail_address_city: r.mail_address_city,
                  mail_address_detail: r.mail_address_detail,
                  send_address_zip_code: r.send_address_zip_code,
                  send_address_city: r.send_address_city,
                  send_address_detail: r.send_address_detail,
                });
              } else {
                message.warning('查無建檔資料');
              }

              return;
            }
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
        }}
        onCancel={() => 0}>
        <Button
          style={{marginTop: 5, marginBottom: 10, marginRight: 15}}
          disabled={!values.name && !values.user}>
          導入最新建檔資料
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認導入會員資料？"
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          if (!values.user) {
            message.warn('尚未選擇會員');
            return;
          }

          try {
            let targetUser = await JStorage.fetchOneDocument('user_profile', {
              owner: values.user,
            });

            let address = {};

            if (targetUser) {
              const hasMailAddress =
                targetUser.mail_address_zip_code &&
                targetUser.mail_address_city &&
                targetUser.mail_address_detail;

              const hasCompanyAddress =
                targetUser.company_zip_code &&
                targetUser.company_city &&
                targetUser.company_detail;

              const hasCompanyCurrent = !!targetUser.company_current;

              const hasResidenceAddress =
                targetUser.residence_address_zip_code &&
                targetUser.residence_address_city &&
                targetUser.residence_address_detail;

              //律師法第26條陳明收受送達處所
              if (hasMailAddress) {
                address.zip_code = targetUser.mail_address_zip_code;
                address.city = targetUser.mail_address_city;
                address.detail = targetUser.mail_address_detail;

                setValues({
                  ...values,
                  name: targetUser.name,
                  id_card_number: targetUser.id_card_number,
                  address_type: 'mail',
                  mail_address_zip_code: address.zip_code,
                  mail_address_city: address.city,
                  mail_address_detail: address.detail,
                  send_address_zip_code: address.zip_code,
                  send_address_city: address.city,
                  send_address_detail: address.detail,
                });

                return;
              }

              //前台事務所顯示
              if (hasCompanyAddress) {
                address.zip_code = targetUser.company_zip_code;
                address.city = targetUser.company_city;
                address.detail = targetUser.company_detail;

                setValues({
                  ...values,
                  name: targetUser.name,
                  id_card_number: targetUser.id_card_number,
                  address_type: 'company',
                  mail_address_zip_code: address.zip_code,
                  mail_address_city: address.city,
                  mail_address_detail: address.detail,
                  send_address_zip_code: address.zip_code,
                  send_address_city: address.city,
                  send_address_detail: address.detail,
                });

                return;
              }

              //現在事務所顯示
              if (hasCompanyCurrent) {
                let targetCompanyCurrent = await JStorage.fetchOneDocument(
                  'Company',
                  {id: targetUser.company_current},
                );

                address.zip_code = targetCompanyCurrent.address_zip_code;
                address.city = targetCompanyCurrent.address_city;
                address.detail = targetCompanyCurrent.address_detail;

                setValues({
                  ...values,
                  name: targetUser.name,
                  id_card_number: targetUser.id_card_number,
                  address_type: 'company_current',
                  mail_address_zip_code: address.zip_code,
                  mail_address_city: address.city,
                  mail_address_detail: address.detail,
                  send_address_zip_code: address.zip_code,
                  send_address_city: address.city,
                  send_address_detail: address.detail,
                });

                return;
              }

              //戶籍地址
              if (hasResidenceAddress) {
                address.zip_code = targetUser.residence_address_zip_code;
                address.city = targetUser.residence_address_city;
                address.detail = targetUser.residence_address_detail;

                setValues({
                  ...values,
                  name: targetUser.name,
                  id_card_number: targetUser.id_card_number,
                  address_type: 'residence',
                  mail_address_zip_code: address.zip_code,
                  mail_address_city: address.city,
                  mail_address_detail: address.detail,
                  send_address_zip_code: address.zip_code,
                  send_address_city: address.city,
                  send_address_detail: address.detail,
                });

                return;
              }

              setValues({
                ...values,
                name: targetUser.name,
                id_card_number: targetUser.id_card_number,
              });
            }
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
        }}
        onCancel={() => 0}>
        <Button style={{marginTop: 5, marginBottom: 10, marginRight: 15}}>
          導入會員資料
        </Button>
      </Popconfirm>

      <Popconfirm
        title="確認導入公會地址？"
        okText="確認"
        cancelText="取消"
        onConfirm={() => {
          setValues({
            ...values,
            address_type: 'tba',
            mail_address_zip_code: '100',
            mail_address_city: '台北市-中正區',
            mail_address_detail: '羅斯福路一段7號9樓',
            send_address_zip_code: '100',
            send_address_city: '台北市-中正區',
            send_address_detail: '羅斯福路一段7號9樓',
          });
        }}
        onCancel={() => 0}>
        <Button style={{marginTop: 5, marginBottom: 10, marginRight: 15}} o>
          導入公會地址
        </Button>
      </Popconfirm>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default AdminPayBillingActions;
