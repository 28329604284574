import React from 'react';
import {Button, message, Popconfirm} from 'antd';
import {getOutlet, getNewOutlet} from 'reconnect.js';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {getStaffValue} from '../../Utils/StaffUtil';
import * as CatUtil from '../../Utils/CatUtil';
import * as UserUtil from '../../Utils/UserUtil';
import permissionRequired from '../../Utils/PermissionRequiredUtil';
import {
  ACTIVITY_INTERMAL_LABELS,
  COURSE_INTERNAL_LABELS,
  WORK_AROUND_PRODUCTS,
} from '../../Utils/ProductUtil';
import receiveJson from '../../../content/custom-admin/receive.json';
import postJson from '../../../content/custom-admin/post.json';
import AdminLinkButton from './AdminLinkButton';
import {showAdminWithdrawModal} from '../../Components/AdminWithdrawModal';
import {showAdminStopModal} from '../../Components/AdminStopModal';
import AdminDownloadExcelButton from '../../Components/AdminDownloadExcelButton';
import AdminMoraleSearchButton from './AdminMoraleSearchButton';
import AdminProductSearchButton from './AdminProductSearchButton';
import AdminMeetingSearchButton from './AdminMeetingSearchButton';
import AdminPostSearchButton from './AdminPostSearchButton';
import AdminUserSearchButton from './AdminUserSearchButton';
import AdminUserBillingSearchButton from './AdminUserBillingSearchButton';
import AdminMoraleSearchNotification from './AdminMoraleSearchNotification';
import DataJson from '../../../data.json';

const expertiseCategoryDisplayMapOutlet = getOutlet(
  'expertiseCategoryDisplayMap',
);
const associationCategoryDisplayMapOutlet = getOutlet(
  'associationCategoryDisplayMap',
);
const SchoolsCategoryDisplayMapOutlet = getOutlet('schoolCategoryDisplayMap');
const GroupCategoryDisplayMapOutlet = getOutlet('groupCategoryDisplayMap');
const AreaCategoryDisplayMapOutlet = getOutlet('areaCategoryDisplayMap');
const UsersOutlet = getOutlet('users');
const UserOutlet = getOutlet('user');
const ConfigOutlet = getOutlet('config');
const CompaniesOutlet = getOutlet('companies');

export async function handleExtra({path, pageContext}) {
  if (path === '/admin/users') {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      if (params.query) {
        try {
          let query = JSON.parse(decodeURIComponent(params.query));
          pageContext.resource.predefinedQueries = [query];
        } catch (err) {}
      }
    }

    pageContext.resource.formSpec.schema.properties.expertises.items.properties.name.enum = [
      '',
      ...Object.keys(expertiseCategoryDisplayMapOutlet.getValue()),
    ];
    pageContext.resource.formSpec.schema.properties.expertises.items.properties.name.enumNames = [
      '無',
      ...Object.values(expertiseCategoryDisplayMapOutlet.getValue()),
    ];

    pageContext.resource.formSpec.schema.properties.associations.items.properties.BAR.enum = Object.keys(
      associationCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.associations.items.properties.BAR.enumNames = Object.values(
      associationCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.educations.items.properties.name.enum = Object.keys(
      SchoolsCategoryDisplayMapOutlet.getValue(),
    );

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      const canWithdrawRequest =
        record.user_type === 'member' &&
        record.state === 1 &&
        record.status === 'success';

      const canStopRequest =
        record.user_type === 'member' &&
        (record.state === 5 || record.state === 8) &&
        record.status === 'success';

      if (canWithdrawRequest) {
        return (
          <Popconfirm
            title="確認申請退會？"
            okText="確認"
            cancelText="取消"
            onConfirm={() =>
              showAdminWithdrawModal({
                record: {user_id: record.owner},
                clientCallbacks,
              })
            }>
            <Button>申請退會</Button>
          </Popconfirm>
        );
      } else if (canStopRequest) {
        return (
          <Popconfirm
            title="確認申請停止？"
            okText="確認"
            cancelText="取消"
            onConfirm={() =>
              showAdminStopModal({
                record: {user_id: record.owner},
                clientCallbacks,
              })
            }>
            <Button>申請停止</Button>
          </Popconfirm>
        );
      }

      return null;
    };

    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <>
          <Button
            style={{margin: 10}}
            onClick={() => AppActions.navigate('/admin/users/?action=create')}>
            +
          </Button>
          <AdminUserSearchButton />
        </>
      );
    };
  }

  if (path.indexOf('/admin/users-withdraw-record') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      if (!record.is_closed) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Popconfirm
              title={`確認退會完成？`}
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);

                try {
                  await AppActionsEx.updateProfileInoutRecord({
                    withdraw_id: record.id,
                  });
                  message.success('退會成功，會員狀態、入退會紀錄已更新。');
                  AppActions.setLoading(false);
                  await clientCallbacks.refresh();
                } catch (err) {
                  console.warn(err);
                  message.error('退會失敗。');
                  AppActions.setLoading(false);
                }
              }}>
              <Button ghost type="primary" style={{margin: 5}}>
                退會完成
              </Button>
            </Popconfirm>
            <Popconfirm
              title="確認刪除？"
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);
                try {
                  await JStorage.deleteDocument('withdraw_record', {
                    id: record.id,
                  });

                  await clientCallbacks.refresh();
                  AppActions.setLoading(false);
                } catch (err) {
                  console.warn(err);
                  AppActions.setLoading(false);
                }
              }}>
              <Button danger style={{margin: 5}}>
                刪除
              </Button>
            </Popconfirm>
          </div>
        );
      }

      return null;
    };
  }

  if (path.indexOf('/admin/users-stop-record') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      if (!record.is_closed) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Popconfirm
              title={`確認停止完成？`}
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);

                try {
                  await AppActionsEx.updateProfileCrossRecord({
                    stop_id: record.id,
                  });
                  message.success('停止成功，會員狀態、跨區執業紀錄已更新。');
                  AppActions.setLoading(false);
                  await clientCallbacks.refresh();
                } catch (err) {
                  console.warn(err);
                  message.error('停止失敗。');
                  AppActions.setLoading(false);
                }
              }}>
              <Button ghost type="primary" style={{margin: 5}}>
                停止完成
              </Button>
            </Popconfirm>
          </div>
        );
      }
    };
  }

  if (path.indexOf('/admin/users-join-review') > -1) {
    pageContext.resource.formSpec.schema.properties.expertises.items.properties.name.enum = Object.keys(
      expertiseCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.expertises.items.properties.name.enumNames = Object.values(
      expertiseCategoryDisplayMapOutlet.getValue(),
    );

    pageContext.resource.formSpec.schema.properties.associations.items.properties.BAR.enum = Object.keys(
      associationCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.associations.items.properties.BAR.enumNames = Object.values(
      associationCategoryDisplayMapOutlet.getValue(),
    );

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return (
        <Popconfirm
          title={`確認審查完成？`}
          onConfirm={async () => {
            try {
              AppActions.setLoading(true);

              await AppActionsEx.updateUserStatus({
                user_id: record.owner,
                status: 'success',
              });

              await clientCallbacks.refresh();
            } catch (err) {
              message.err('發生錯誤。');
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          <Button type="primary" ghost>
            審查完成
          </Button>
        </Popconfirm>
      );
    };
  }

  if (path.indexOf('/admin/job-review') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return record.status === 'success' ? (
        <Popconfirm
          title={`確認審核完成？`}
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              await JStorage.updateDocument(
                'review_request',
                {id: record.id},
                {status: 'posted'},
              );

              const targetUser = await JStorage.fetchOneDocument(
                'user_profile',
                {
                  owner: record.user,
                },
                {email: 1},
              );

              await AppActionsEx.sendEmail({
                sender: 'job-review',
                data: {
                  record_id: record.id,
                  review_note: record.review_note || '',
                  name: record.data.name || '',
                  chaired_lawyer: record.data.chaired_lawyer || '',
                  address_firm: record.data.address_firm || '',
                },
                emails: [record.email, targetUser.email],
              });

              await clientCallbacks.refresh();
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}>
          <Button type="primary" ghost>
            審核完成
          </Button>
        </Popconfirm>
      ) : null;
    };
  }

  if (path.indexOf('/admin/room-review') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return record.status === 'success' ? (
        <Popconfirm
          title={`確認審核完成？`}
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              await JStorage.updateDocument(
                'review_request',
                {id: record.id},
                {status: 'posted'},
              );

              const targetUser = await JStorage.fetchOneDocument(
                'private_profile',
                {
                  owner: record.user,
                },
                {email: 1},
              );

              await AppActionsEx.sendEmail({
                sender: 'room-review',
                data: {
                  record_id: record.id,
                  review_note: record.review_note || '',
                  name: record.data.name || '',
                  chaired_lawyer: record.data.chaired_lawyer || '',
                  address_firm: record.data.address_firm || '',
                },
                emails: [record.email, targetUser.email],
              });

              await clientCallbacks.refresh();
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}>
          <Button type="primary" ghost>
            審核完成
          </Button>
        </Popconfirm>
      ) : null;
    };
  }

  if (path.indexOf('/admin/company-reviews') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return record.status === 'success' ? (
        <Popconfirm
          title={`確認審核完成？`}
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              const targetUser = await JStorage.fetchOneDocument(
                'user_profile',
                {owner: record.user},
              );

              const nextCompanyCurrent = record.company_current;
              const nextCompanyCurrentInstance = CompaniesOutlet.getValue().find(
                (c) => c.id === nextCompanyCurrent,
              );

              const prevCompanyCurrent = targetUser.company_current;
              const prevCompanyCurrentInstance = CompaniesOutlet.getValue().find(
                (c) => c.id === prevCompanyCurrent,
              );

              if (!nextCompanyCurrentInstance) {
                window.alert(
                  `錯誤: 查無該事務所資料\nid: ${record.company_current}`,
                );
                throw new Error('查無該事務所資料');
              }

              const nextCompanyHistory = !prevCompanyCurrentInstance
                ? [...(targetUser.company_history || [])]
                : [
                    ...(targetUser.company_history || []),
                    {
                      CONAME: prevCompanyCurrentInstance.CONAME,
                      COPHONE1:
                        targetUser.company_phone ||
                        prevCompanyCurrentInstance.COPHONE1 ||
                        '',
                      COFAX1:
                        targetUser.company_fax ||
                        prevCompanyCurrentInstance.COFAX1 ||
                        '',
                      address_zip_code:
                        targetUser.company_zip_code ||
                        prevCompanyCurrentInstance.address_zip_code ||
                        '',
                      address_city:
                        targetUser.company_city ||
                        prevCompanyCurrentInstance.address_city ||
                        '',
                      address_detail:
                        targetUser.company_detail ||
                        prevCompanyCurrentInstance.address_detail ||
                        '',
                      UPDATE_TIME: record.UPDATE_TIME,
                    },
                  ];

              await AppActionsEx.updateUserUprPpr({
                owner: record.user,
                data: {
                  company_current: nextCompanyCurrent,
                },
                is_stamp: true,
              });

              const updateData = {
                company_current: nextCompanyCurrent,
                company_history: nextCompanyHistory,
                company_zip_code: '',
                company_city: '',
                company_detail: '',
                company_phone: '',
                company_fax: '',
                COMPANY_TYPE: record.COMPANY_TYPE,
                IS_EMPLOYED: record.IS_EMPLOYED,
              };

              const isUpdateUserTwbaData = UserUtil.isUpdateUserTwbaData({
                instance: {...targetUser},
                formData: {...updateData},
              });

              await AppActionsEx.updateUserUpr({
                owner: record.user,
                data: updateData,
                is_stamp: isUpdateUserTwbaData[0],
              });

              await JStorage.updateDocument(
                'review_request',
                {id: record.id},
                {status: 'changed'},
              );

              await AppActionsEx.sendEmail({
                sender: 'company-review',
                data: {
                  review_note: record.review_note || '',
                  company_current_name: nextCompanyCurrentInstance.CONAME,
                },
                emails: [record.email, targetUser.email],
              });

              await clientCallbacks.refresh();
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}>
          <Button type="primary" ghost>
            審核完成
          </Button>
        </Popconfirm>
      ) : null;
    };
  }

  if (path.indexOf('/admin/permission-staffs') > -1) {
    pageContext.resource.formSpec.schema.properties.group.enum = Object.keys(
      GroupCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.group.enumNames = Object.values(
      GroupCategoryDisplayMapOutlet.getValue(),
    );

    const isAdmin =
      getStaffValue(UserOutlet.getValue().username, 'username') === 'admin';

    if (isAdmin) {
      pageContext.resource.renderDeleteButton = undefined;
    }
  }

  if (path.indexOf('/admin/receives') > -1) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      if (params.query) {
        try {
          let query = {type: 'receive'};
          let parseQuery = JSON.parse(decodeURIComponent(params.query));

          if (!!parseQuery.implement) {
            query['implement'] = parseQuery.implement;
          }

          pageContext.resource.predefinedQueries = [query];
        } catch (err) {}
      }
    }

    if (typeof window) {
      const isDetail = window.location.search.indexOf('action=detail') > -1;
      const isExecution =
        window.location.search.indexOf('stage=execution') > -1;

      if (isDetail) {
        const fieldValues = Object.values(
          pageContext.resource.formSpec.schema.properties,
        );

        const fieldConvertArray = Object.keys(
          pageContext.resource.formSpec.schema.properties,
        ).map((key, index) => {
          return {
            key,
            value: {...fieldValues[index]},
          };
        });

        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...receiveJson.formSpec.uiSchema,
        };
      }
    }

    const options = ConfigOutlet.getValue().receive_category || [];
    pageContext.resource.formSpec.schema.properties.category.enum = [
      ...options,
      '新增',
    ];

    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <>
          <Popconfirm
            title="確認創建嗎？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                const resp = await ApiUtil.req(
                  `${DataJson.apiHost}/form/create?token=${
                    getOutlet('user').getValue().token
                  }`,
                  {
                    method: 'POST',
                    data: {
                      type: 'receive',
                      data: {
                        stage: 'edit',
                      },
                    },
                  },
                );

                AppActions.navigate(
                  `/admin/receives/?action=detail&id=${
                    resp._id.$oid || resp._id
                  }&stage=edit`,
                );
              } catch (ex) {
                message.error('API Fail');
                console.warn(ex);
              }
              AppActions.setLoading(false);
            }}>
            <Button style={{margin: 10}}>+</Button>
          </Popconfirm>
        </>
      );
    };

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      const canDelete =
        record.author === UserOutlet.getValue().username
          ? record.stage === 'edit' ||
            (record.stage === 'review_1' &&
              record.review_1[0]?.status === 'pending')
          : false;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <AdminDownloadExcelButton
            collection="form"
            query={{_id: {$oid: record.id}}}
            filename={`收文簿.xlsx`}
            title="匯出"
            buttonStyle={{margin: 5}}
            recordsToAoa={async (records) => {
              const record = records[0];

              const history = Array.isArray(record.history)
                ? record.history.length > 0
                  ? record.history.map((h) => {
                      const statusLabel = (() => {
                        switch (h.status) {
                          case 'pending':
                            return '待簽核';
                          case 'success':
                            return '通過';
                          case 'abort':
                            return '退簽';
                          default:
                            return record.status;
                        }
                      })();
                      const createdLabel = new Date(h.created).toLocaleString(
                        'sv',
                      );

                      return [
                        `${h.role}`,
                        `${h.name}`,
                        `${h.note}`,
                        `${statusLabel}`,
                        `${createdLabel}`,
                      ];
                    })
                  : []
                : [];

              const history_execution = Array.isArray(record.history_execution)
                ? record.history_execution.length > 0
                  ? record.history_execution.map((e) => {
                      const createdLabel = new Date(e.created).toLocaleString(
                        'sv',
                      );
                      return [
                        `${e.role}`,
                        `${e.name}`,
                        `${e.note}`,
                        `${createdLabel}`,
                        '',
                      ];
                    })
                  : []
                : [];

              return [
                ['收文編號：', `${record.paperwork_id}`, '', '', ''],
                ['發文機關：', `${record.organs}`, '', '', ''],
                ['主旨：', `${record.title}`, '', '', ''],
                ['擬辦人員：', `${record.implement_name}`, '', '', ''],
                ['擬辦內容：', `${record.implement_content}`, '', '', ''],
                ['職稱', '簽核人', '簽核內容', '簽核狀態', '簽核時間'],
                ...history,
                ['職稱', '執行人', '執行內容', '執行時間', ''],
                ...history_execution,
              ];
            }}
            updateWorkSheet={(ws) => {
              const merge = [
                // s = start, r = row, c = col, e = end
                {s: {r: 0, c: 1}, e: {r: 0, c: 4}},
                {s: {r: 1, c: 1}, e: {r: 1, c: 4}},
                {s: {r: 2, c: 1}, e: {r: 2, c: 4}},
                {s: {r: 3, c: 1}, e: {r: 3, c: 4}},
                {s: {r: 4, c: 1}, e: {r: 4, c: 4}},
              ];
              ws['!merges'] = merge;

              //title
              const cells = Object.keys(ws);
              const titleValues = [
                '職稱',
                '簽核人',
                '簽核內容',
                '簽核狀態',
                '簽核時間',
                '執行人',
                '執行內容',
                '執行時間',
              ];
              const titles = cells.filter((cell) =>
                titleValues.includes(ws[cell].v),
              );

              for (let i = 0; i < titles.length; i++) {
                const cell = titles[i];

                ws[cell].s = {
                  sz: 14,
                  bold: true,
                };
              }

              //cell style

              const excludeCells = ['!cols', '!merges', '!ref'];

              for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];

                if (!excludeCells.includes(cell)) {
                  ws[cell].s = {
                    ...ws[cell].s,
                    sz: 14,
                    alignment: {
                      wrapText: true,
                      vertical: 'center',
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    bottom: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //col width
              ws['!cols'] = [
                {wpx: 110},
                {wpx: 70},
                {wpx: 300},
                {wpx: 105},
                {wpx: 105},
              ];

              //row height
              ws['!rows'] = [
                {hpx: 46},
                {hpx: 46},
                {hpx: 88},
                {hpx: 46},
                {hpx: 46},
              ];
            }}
          />
          {canDelete && (
            <Popconfirm
              title={`確認刪除「${record.paperwork_id || '---'}」嗎？`}
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);
                try {
                  await JStorage.deleteDocument('form', {id: record.id});
                  await clientCallbacks.refresh();
                  message.success('成功');
                } catch (err) {
                  message.error('發生錯誤');
                  console.warn(err);
                }
                AppActions.setLoading(false);
              }}>
              <Button type="danger" style={{margin: 5}}>
                刪除
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    };
  }

  if (path.indexOf('/admin/posts') > -1) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      if (params.query) {
        try {
          let query = {type: 'post'};
          let parseQuery = JSON.parse(decodeURIComponent(params.query));

          if (!!parseQuery.implement) {
            query['implement'] = parseQuery.implement;
          }

          if (
            !!parseQuery.receives_related &&
            parseQuery.receives_related.length > 0
          ) {
            query['receives_related'] = {$in: query['receives_related']};
          }

          pageContext.resource.predefinedQueries = [query];
        } catch (err) {}
      }
    }

    if (typeof window) {
      const isDetail = window.location.search.indexOf('action=detail') > -1;

      if (isDetail) {
        const fieldValues = Object.values(
          pageContext.resource.formSpec.schema.properties,
        );

        const fieldConvertArray = Object.keys(
          pageContext.resource.formSpec.schema.properties,
        ).map((key, index) => {
          return {
            key,
            value: {...fieldValues[index]},
          };
        });

        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...receiveJson.formSpec.uiSchema,
        };
      }
    }

    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <>
          <Popconfirm
            title="確認創建嗎？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                const resp = await ApiUtil.req(
                  `${DataJson.apiHost}/form/create?token=${
                    getOutlet('user').getValue().token
                  }`,
                  {
                    method: 'POST',
                    data: {
                      type: 'post',
                      data: {
                        stage: 'edit',
                      },
                    },
                  },
                );

                AppActions.navigate(
                  `/admin/posts/?action=detail&id=${
                    resp._id.$oid || resp._id
                  }&stage=edit`,
                );
              } catch (ex) {
                message.error('API Fail');
                console.warn(ex);
              }
              AppActions.setLoading(false);
            }}>
            <Button style={{margin: 10}}>+</Button>
          </Popconfirm>
          <AdminPostSearchButton />
        </>
      );
    };

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      const canDelete =
        record.author === UserOutlet.getValue().username
          ? record.stage === 'edit' ||
            (record.stage === 'review_1' &&
              record.review_1[0]?.status === 'pending')
          : false;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Popconfirm
            title={`確認複製「${record.paperwork_id || '---'}」嗎？`}
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                await ApiUtil.req(
                  // TODO: change api endpoint
                  `${DataJson.apiHost}/form/copy/post?token=${
                    getOutlet('user').getValue().token
                  }`,
                  {method: 'POST', data: {form_id: record.id}},
                );
                await clientCallbacks.refresh();
                message.success('成功');
              } catch (err) {
                message.error('發生錯誤');
                console.warn(err);
              }
            }}>
            <Button style={{margin: 5}}>複製</Button>
          </Popconfirm>
          {canDelete && (
            <Popconfirm
              title={`確認刪除「${record.paperwork_id || '---'}」嗎？`}
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);
                try {
                  await JStorage.deleteDocument('form', {id: record.id});
                  await clientCallbacks.refresh();
                  message.success('成功');
                } catch (err) {
                  message.error('發生錯誤');
                  console.warn(err);
                }
                AppActions.setLoading(false);
              }}>
              <Button danger style={{margin: 5}}>
                刪除
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    };
  }

  if (path.indexOf('/admin/reviews') > -1) {
    pageContext.resource.predefinedQueries.push({
      $or: [
        {
          stage: 'review_1',
          'review_1.user_id': UserOutlet.getValue().username,
          'review_1.status': 'pending',
        },
        {
          stage: 'review_2',
          'review_2.user_id': UserOutlet.getValue().username,
          'review_2.status': 'pending',
        },
        {
          stage: 'review_3',
          'review_3.user_id': UserOutlet.getValue().username,
          'review_3.status': 'pending',
        },
        {
          stage: 'review_4',
          'review_4.user_id': UserOutlet.getValue().username,
          'review_4.status': 'pending',
        },
      ],
    });

    if (typeof window) {
      if (window.location.search.indexOf('type=receive') > -1) {
        const fieldValues = Object.values(
          receiveJson.formSpec.schema.properties,
        );
        const fieldConvertArray = Object.keys(
          receiveJson.formSpec.schema.properties,
        ).map((key, index) => ({
          key,
          value: {...fieldValues[index], readOnly: true},
        }));
        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...receiveJson.formSpec.uiSchema,
        };
      }

      if (window.location.search.indexOf('type=post') > -1) {
        const fieldValues = Object.values(postJson.formSpec.schema.properties);
        const fieldConvertArray = Object.keys(
          postJson.formSpec.schema.properties,
        ).map((key, index) => ({
          key,
          value: {...fieldValues[index], readOnly: true},
        }));
        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...postJson.formSpec.uiSchema,
        };
      }
    }
  }

  if (path.indexOf('/admin/executions') > -1) {
    pageContext.resource.predefinedQueries.push({
      executor: UserOutlet.getValue().username,
      stage: {$in: ['execution_direct', 'execution']},
    });

    if (typeof window !== 'undefined') {
      if (window.location.search.indexOf('type=receive') > -1) {
        const fieldValues = Object.values({
          paperwork_id: {
            type: 'string',
            title: '收文編號',
          },
          ...receiveJson.formSpec.schema.properties,
        });
        const fieldConvertArray = Object.keys({
          paperwork_id: {
            type: 'string',
            title: '收文編號',
          },
          ...receiveJson.formSpec.schema.properties,
        }).map((key, index) => {
          const canUpdateKeys = [
            'date',
            'organs',
            'text_number',
            'category',
            'title',
            'files',
          ];

          if (canUpdateKeys.some((k) => k === key)) {
            return {
              key,
              value: {...fieldValues[index]},
            };
          }

          return {
            key,
            value: {...fieldValues[index], readOnly: true},
          };
        });
        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...receiveJson.formSpec.uiSchema,
        };

        const options = ConfigOutlet.getValue().receive_category || [];
        pageContext.resource.formSpec.schema.properties.category.enum = [
          ...options,
        ];
      }

      if (window.location.search.indexOf('type=post') > -1) {
        const fieldValues = Object.values({
          paperwork_id: {
            type: 'string',
            title: '發文編號',
          },
          ...postJson.formSpec.schema.properties,
        });
        const fieldConvertArray = Object.keys({
          paperwork_id: {
            type: 'string',
            title: '發文編號',
          },
          ...postJson.formSpec.schema.properties,
        }).map((key, index) => {
          const canUpdateKeys = [
            'date',
            'title',
            'description',
            'text_number',
            'files',
          ];

          if (canUpdateKeys.some((k) => k === key)) {
            return {
              key,
              value: {...fieldValues[index]},
            };
          }

          return {
            key,
            value: {...fieldValues[index], readOnly: true},
          };
        });
        let nextValue = {};

        for (const element of fieldConvertArray) {
          nextValue[element.key] = element.value;
        }

        pageContext.resource.formSpec.schema.properties = {
          ...nextValue,
        };
        pageContext.resource.formSpec.uiSchema = {
          ...postJson.formSpec.uiSchema,
        };
      }
    }
  }

  if (path.indexOf('/admin/company-billings') > -1) {
    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <Button
          onClick={() =>
            getOutlet('AdminCompanyBillingModal').update({
              clientCallbacks,
            })
          }>
          ＋
        </Button>
      );
    };

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      if (record.payment_status !== 'success') {
        return (
          <Popconfirm
            title="確認刪除？"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                await JStorage.deleteDocument('company_billing', {
                  id: record.id,
                });

                await clientCallbacks.refresh();
                AppActions.setLoading(false);
              } catch (err) {
                console.warn(err);
                AppActions.setLoading(false);
              }
            }}>
            <Button danger>刪除</Button>
          </Popconfirm>
        );
      }
    };
  }

  if (path.indexOf('/admin/user-billings') > -1) {
    let targetCol = pageContext.resource.columns[9];
    let targetCollection = pageContext.resource.collection;

    pageContext.resource.columns[9] = {
      ...targetCol,
      key: 'order_number',
      dataIndex: 'order_number',
      render: (text, record) => {
        return <div>{record.order_id || `${record.order_number}*`}</div>;
      },
    };

    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <div>
          <Button
            type="primary"
            style={{margin: 10}}
            onClick={() =>
              AppActions.navigate('/admin/user-billings/?action=create')
            }>
            創建單筆常年會費
          </Button>
          <AdminUserBillingSearchButton />
        </div>
      );
    };

    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);

      if (params.query) {
        try {
          let query = {};
          let parseQuery = JSON.parse(decodeURIComponent(params.query));

          if (!!parseQuery.buyer_name) {
            query.buyer_name = {$regex: parseQuery.buyer_name};
          }

          pageContext.resource.predefinedQueries.push(query);
        } catch (err) {
          console.warn(err);
        }
      }
    }
  }

  if (path.indexOf('/admin/sign_forms') > -1) {
    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <Button
          onClick={() =>
            getOutlet('AdminCreateProductSignFormModal').update({
              clientCallbacks,
            })
          }>
          ＋
        </Button>
      );
    };

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      if (record.is_posted_hours && record.is_posted_hours === true) {
        return null;
      }

      if (
        record.is_online_posted_hours &&
        record.is_online_posted_hours === true
      ) {
        return null;
      }

      return (
        <Popconfirm
          title="確認刪除？"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              await JStorage.deleteDocument('sign_form', {
                id: record.id,
              });

              await clientCallbacks.refresh();
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}>
          <Button danger>刪除</Button>
        </Popconfirm>
      );
    };
  }

  if (path.indexOf('/admin/product_category') > -1) {
    const resource = pageContext.resource;
    resource.name = `${AppActions.L('product')}分類`;
  }

  if (path.indexOf('/admin/products') > -1) {
    const resource = pageContext.resource;
    const properties = pageContext.resource.formSpec.schema.properties;
    const uiSchema = pageContext.resource.formSpec.uiSchema;

    //filter
    const cats = CatUtil.getFlattenCategories('categories');
    resource.querySpec.filters[1].options = [
      ...cats.map((cat) => ({value: cat.name, name: cat.display})),
    ];
    const expertiseCats = CatUtil.getFlattenCategories('expertiseCategories');
    resource.querySpec.filters[2].options = [
      ...expertiseCats.map((cat) => ({value: cat.name, name: cat.display})),
    ];

    //columns
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;
        return (
          <div style={{width: '100%', maxWidth: firstCol.width}}>
            <AdminLinkButton url={url} title={record.serial_number || '---'} />
          </div>
        );
      },
    };

    const secondCol = pageContext.resource.columns[1];
    pageContext.resource.columns[1] = {
      ...secondCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;

        return (
          <div>
            <div>{record.name}</div>
            {record.session && Object.keys(record.session).length > 0 ? (
              <div>
                {record.session.date || '[場次日期未設定]'}{' '}
                {record.session.start_time || '[開始時間未設定]'}-
                {record.session.end_time || '[結束時間未設定]'}
              </div>
            ) : null}
          </div>
        );
      },
    };

    const fifthCol = pageContext.resource.columns[5];
    pageContext.resource.columns[5] = {
      ...fifthCol,
      render: (text, record) => {
        return (
          <div style={{width: 70}}>
            {
              pageContext.resource.formSpec.schema.properties.type.enumNames[
                pageContext.resource.formSpec.schema.properties.type.enum.findIndex(
                  (ele) => ele === record.type,
                ) || 0
              ]
            }
          </div>
        );
      },
    };

    pageContext.resource.renderDetailButton = (record, clientCallbacks) => {
      return record.is_old ? null : (
        <Popconfirm
          title={`確定複製「${record.name}」嗎？`}
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            AppActions.setLoading(true);
            try {
              let data = {
                ...record,
                name: `${record.name}*`,
                type:
                  record.type === 'group_root'
                    ? 'group_item'
                    : record.type === 'group_item'
                    ? 'group_item'
                    : 'default',
                group_root_product:
                  record.type === 'group_root'
                    ? record.id
                    : record.type === 'group_item'
                    ? record.group_root_product
                    : '',
                public: false,
                is_publish: false,
                article: '',
                stock_type: 'always',
                stock_sold_amount: undefined,
              };

              delete data.id;
              delete data._id;

              await JStorage.createDocument('product', {
                ...data,
              });
              await clientCallbacks.refresh();
            } catch (err) {
              message.err('發生錯誤。');
            }
            AppActions.setLoading(false);
          }}>
          <Button type="link">複製</Button>
        </Popconfirm>
      );
    };

    //expertises
    pageContext.resource.formSpec.schema.properties.expertises.items.enum = Object.keys(
      expertiseCategoryDisplayMapOutlet.getValue(),
    );
    pageContext.resource.formSpec.schema.properties.expertises.items.enumNames = Object.values(
      expertiseCategoryDisplayMapOutlet.getValue(),
    );

    //text
    resource.name = `${AppActions.L('product')}全覽`;
    properties.name.title = '名稱';
    properties.type.enumNames = ['預設', '集合母項', '集合子項', 'N合一'];
    properties.n_in_1_products.title = `N合1包含的${AppActions.L('product')}`;
    properties.n_in_1_products.items.title = `${AppActions.L('product')}項目`;
    properties.group_root_product.title = `${AppActions.L('product')}集合母項`;
    properties.images.title = '圖片';
    properties.labels.title = '官網/App分類';
    properties.price.title = '價錢';
    properties.original_price.title = '原價';
    properties.intro.title = '介紹';
    properties.spec.title = '詳情';
    properties.variants.title = '加價選項';
    uiSchema.original_price[
      'ui:help'
    ] = `本欄位為行銷顯示，不涉及結帳價錢計算。不需使用設定為 0 元。`;

    delete properties['shipping_extra_price'];
    delete properties['no_global_free_shipping'];

    const typeIndex = resource.querySpec.filters?.findIndex(
      (filter) => filter.name === '類型',
    );

    if (typeIndex > -1) {
      resource.querySpec.filters[typeIndex].options[0].name = '預設';
      resource.querySpec.filters[typeIndex].options[1].name = '集合母項';
      resource.querySpec.filters[typeIndex].options[2].name = '集合子項';
    }

    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button
            style={{margin: 10}}
            onClick={() =>
              AppActions.navigate('/admin/products/?action=create')
            }>
            +
          </Button>
          <AdminProductSearchButton />
        </div>
      );
    };

    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);

      if (params.query) {
        try {
          // see "AdminProductSearchButton.js" about the query format
          const queryParams = JSON.parse(decodeURIComponent(params.query));
          let sessionDateQuery = {};

          if (queryParams.sessionDateStart) {
            sessionDateQuery = {
              $gte: queryParams.sessionDateStart,
            };
          }

          if (queryParams.sessionDateEnd) {
            sessionDateQuery = {
              ...sessionDateQuery,
              $lt: queryParams.sessionDateEnd,
            };
          }

          pageContext.resource.predefinedQueries = [
            {'session.date': sessionDateQuery},
          ];
        } catch (err) {}
      }

      if (params.id) {
        try {
          const removeRequiredIds = [...WORK_AROUND_PRODUCTS];
          const isTargetId = removeRequiredIds.includes(params.id);

          if (isTargetId) {
            pageContext.resource.formSpec.schema.required = [];
          }
        } catch (err) {}
      }
    }
  }

  if (path.indexOf('/admin/stocks') > -1) {
    const resource = pageContext.resource;
    const properties = pageContext.resource.formSpec.schema.properties;

    const firstCol = pageContext.resource.columns[0];
    const secondCol = pageContext.resource.columns[1];

    resource.name = '報名人數管理';
    resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const display = `${record[firstCol['key']] || '---'}`;
        const url = `/admin/stocks/?action=detail&id=${record.id}`;

        return <AdminLinkButton title={display} url={url} />;
      },
    };
    resource.columns[1] = {
      ...secondCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;

        return (
          <div>
            <div>{record.name}</div>
            {record.session && Object.keys(record.session).length > 0 ? (
              <div>
                {record.session.date || '[場次日期未設定]'}{' '}
                {record.session.start_time || '[開始時間未設定]'}-
                {record.session.end_time || '[結束時間未設定]'}
              </div>
            ) : null}
          </div>
        );
      },
    };

    resource.columns[2].title = '類型';
    resource.columns[3].title = '數量';

    resource.querySpec.filters[0].name = '類型';
    resource.querySpec.filters[1].name = '數量';
    resource.querySpec.filters[0].options[1].name = '總量';
    resource.querySpec.filters[0].options[2].name = '期間';

    properties.name.title = `${AppActions.L('product')}名稱`;
    properties.stock_type.title = '類型';
    properties.stock_type.enumNames = ['常備', '總量', '期間'];
  }

  if (path.indexOf('/admin/stock-records') > -1) {
    const resource = pageContext.resource;

    resource.name = '報名人數管理紀錄';
    resource.columns[1].title = `${AppActions.L('product')}名稱`;
    resource.columns[1].width = 300;
    resource.columns[2].title = '總量設定值';
    resource.columns[4].title = '設定前總量';
    resource.columns[5].title = '設定後總量';
    resource.columns[6].title = '已報名';
  }

  if (path.indexOf('/admin/archived_products') > -1) {
    const resource = pageContext.resource;
    resource.name = `封存${AppActions.L('product')}`;
  }

  if (path.indexOf('/admin/archived_meetings') > -1) {
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const display = record.MTNG_NAME;
        const url = `/admin/meetings/?action=detail&id=${record.id}`;

        return <AdminLinkButton url={url} title={display} />;
      },
    };
  }

  if (path.indexOf('/admin/videos') > -1) {
    const properties = pageContext.resource.formSpec.schema.properties;
    properties.product.title = `${AppActions.L('product')}`;
  }

  if (path.indexOf('/admin/live_stream') > -1) {
    const properties = pageContext.resource.formSpec.schema.properties;
    properties.product.title = `${AppActions.L('product')}`;
  }

  if (path.indexOf('/admin/order') > -1) {
    const resource = pageContext.resource;
    const properties = pageContext.resource.formSpec.schema.properties;

    const logisticsStatusColIdx = resource.columns.findIndex(
      (col) => col.key === 'logistics_status',
    );

    logisticsStatusColIdx > -1 &&
      resource.columns.splice(logisticsStatusColIdx, 1);

    const logisticsTypeColIdx = resource.columns.findIndex(
      (col) => col.key === 'logistics_type',
    );

    logisticsTypeColIdx > -1 && resource.columns.splice(logisticsTypeColIdx, 1);

    const logisticsStatusFilterIdx = resource.querySpec.filters.findIndex(
      (filter) => filter.key === 'logistics_status',
    );

    logisticsStatusFilterIdx > -1 &&
      resource.querySpec.filters.splice(logisticsStatusFilterIdx, 1);

    const logisticsTypeFilterIdx = resource.querySpec.filters.findIndex(
      (filter) => filter.key === 'logistics_type',
    );

    logisticsTypeColIdx > -1 &&
      resource.querySpec.filters.splice(logisticsTypeFilterIdx, 1);

    const statusReturnAppliedOptionPropertyIdx = properties.status?.anyOf?.findIndex(
      (option) => option.enum[0] === 'return_applied',
    );

    statusReturnAppliedOptionPropertyIdx > -1 &&
      properties.status.anyOf.splice(statusReturnAppliedOptionPropertyIdx, 1);

    delete properties['logistics_status'];
  }

  if (path.indexOf('/admin/orders-course') > -1) {
    const resource = pageContext.resource;
    const properties = pageContext.resource.formSpec.schema.properties;
    const uiSchema = pageContext.resource.formSpec.uiSchema;

    //predefinedQuery
    resource.predefinedQueries = [
      {internal_label: {$in: [...COURSE_INTERNAL_LABELS]}},
    ];

    //filter

    //columns
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/orders-course/?action=detail&id=${record.id}`;
        return (
          <div style={{width: '100%', maxWidth: 70}}>
            <AdminLinkButton url={url} title={record.serial_number || '---'} />
          </div>
        );
      },
    };
  }

  if (path.indexOf('/admin/orders-activity') > -1) {
    const resource = pageContext.resource;
    const properties = pageContext.resource.formSpec.schema.properties;
    const uiSchema = pageContext.resource.formSpec.uiSchema;

    //predefinedQuery
    resource.predefinedQueries = [
      {internal_label: {$in: [...ACTIVITY_INTERMAL_LABELS]}},
    ];

    //filter

    //columns
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/orders-activity/?action=detail&id=${record.id}`;
        return (
          <div style={{width: '100%', maxWidth: 70}}>
            <AdminLinkButton url={url} title={record.serial_number || '---'} />
          </div>
        );
      },
    };
  }

  if (path.indexOf('/admin/companies') > -1) {
    const properties = pageContext.resource.formSpec.schema.properties;

    properties.address_city.enum = Object.keys(
      AreaCategoryDisplayMapOutlet.getValue(),
    );
    properties.address_city.enumNames = Object.keys(
      AreaCategoryDisplayMapOutlet.getValue(),
    );

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return (
        <div>
          <Popconfirm
            title="確認刪除？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                await JStorage.deleteDocument('Company', {id: record.id});
                await clientCallbacks.refresh();
                message.success('成功');
              } catch (err) {
                message.error('發生錯誤');
                console.warn(err);
              }
              AppActions.setLoading(false);
            }}>
            <Button danger disabled={record.count > 0}>
              刪除
            </Button>
          </Popconfirm>
        </div>
      );
    };
  }

  if (path.indexOf('/admin/morales') > -1) {
    const cats = CatUtil.getFlattenCategories('moraleCategories');

    pageContext.resource.columns[2].title = (
      <div style={{textAlign: 'center'}}>
        <div>申訴人</div>
        <div>（移送機關團體）</div>
        <div>（立案調查）</div>
      </div>
    );

    pageContext.resource.columns[3].title = (
      <div style={{textAlign: 'center'}}>
        <div>被申訴律師</div>
        <div>（受調查對象）</div>
      </div>
    );

    pageContext.resource.columns[4].title = (
      <div style={{textAlign: 'center'}}>
        <div>違紀行為時點</div>
        <div>（粗估供參）</div>
      </div>
    );

    pageContext.resource.querySpec.filters[0].options = [
      ...cats.map((cat) => ({value: cat.name, name: cat.display})),
    ];

    pageContext.resource.formSpec.schema.properties.labels.items = {
      type: 'string',
      enum: [...cats.map((cat) => cat.name)],
      enumNames: [...cats.map((cat) => cat.display)],
    };

    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Button
            style={{margin: 10}}
            onClick={() => {
              AppActions.navigate('/admin/morales/?action=create');
            }}>
            +
          </Button>
          <AdminMoraleSearchButton />
          <AdminMoraleSearchNotification />
        </div>
      );
    };

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 15,
            }}>
            <Popconfirm
              title="確認切換狀態？"
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                if (!permissionRequired('/admin/morale/_write_public')) {
                  window.alert('您無此權限');
                  return;
                }

                if (window.confirm('確定要切換審核狀態嗎?')) {
                  AppActions.setLoading(true);
                  try {
                    await JStorage.updateDocument(
                      'morale',
                      {
                        id: record.id,
                      },
                      {public: !record.public},
                    );
                    window.location.reload();
                  } catch (ex) {
                    console.warn('ex', ex);
                  }
                  AppActions.setLoading(false);
                }
              }}>
              <Button size="small" type="primary">
                切換狀態
              </Button>
            </Popconfirm>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Popconfirm
              title="確認刪除？"
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                AppActions.setLoading(true);
                try {
                  await JStorage.deleteDocument('morale', {id: record.id});
                  await clientCallbacks.refresh();
                  message.success('成功');
                } catch (err) {
                  message.error('發生錯誤');
                  console.warn(err);
                }
                AppActions.setLoading(false);
              }}>
              <Button size="small" danger>
                刪除
              </Button>
            </Popconfirm>
          </div>
        </div>
      );
    };

    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);

      if (params.query) {
        try {
          let query = {};
          let parseQuery = JSON.parse(decodeURIComponent(params.query));

          if (!!parseQuery.case_number) {
            query.case_number = parseQuery.case_number;
          }

          if (!!parseQuery.labels && parseQuery.labels.length > 0) {
            query.labels = {$in: parseQuery.labels};
          }

          if (!!parseQuery.prosecutor_name) {
            const targetUsers = UsersOutlet.getValue().filter(
              (u) => u.name.indexOf(parseQuery.prosecutor_name) > -1,
            );

            if (targetUsers.length === 0) {
              query.prosecutors_normal = {
                $elemMatch: {name: {$regex: parseQuery.prosecutor_name}},
              };
            } else {
              query = {
                ...query,
                $or: [
                  {prosecutors: {$in: targetUsers.map((u) => u.owner)}},
                  {
                    prosecutors_normal: {
                      $elemMatch: {name: {$regex: parseQuery.prosecutor_name}},
                    },
                  },
                ],
              };
            }
          }

          if (!!parseQuery.defendant_name) {
            const targetUsers = UsersOutlet.getValue().filter(
              (u) => u.name.indexOf(parseQuery.defendant_name) > -1,
            );

            if (targetUsers.length === 0) {
              query.defendants_normal = {
                $elemMatch: {name: {$regex: parseQuery.defendant_name}},
              };
            } else {
              query = {
                ...query,
                $or: [
                  {defendants: {$in: targetUsers.map((u) => u.owner)}},
                  {
                    defendants_normal: {
                      $elemMatch: {name: {$regex: parseQuery.defendant_name}},
                    },
                  },
                ],
              };
            }
          }

          if (!!parseQuery.investigator_leader) {
            query.investigators = {
              $elemMatch: {
                responsibility: '召集人',
                user: parseQuery.investigator_leader,
              },
            };
          }

          if (!!parseQuery.investigators_member) {
            if (query.investigators) {
              delete query.investigators;

              query.$or = [
                {
                  investigators: {
                    $elemMatch: {
                      responsibility: '召集人',
                      user: parseQuery.investigator_leader,
                    },
                  },
                },
                {
                  investigators: {
                    $elemMatch: {
                      responsibility: '調查委員',
                      user: parseQuery.investigators_member,
                    },
                  },
                },
              ];
            } else {
              query.investigators = {
                $elemMatch: {
                  responsibility: '調查委員',
                  user: parseQuery.investigators_member,
                },
              };
            }
          }

          if (!!parseQuery.status) {
            query.status = {
              $in: [...parseQuery.status],
            };
          }

          if (!!parseQuery.results_tba) {
            const targetUsers = UsersOutlet.getValue().filter(
              (u) => u.name === parseQuery.results_tba,
            );

            if (targetUsers.length === 0) {
              query = {
                ...query,
                $or: [
                  {
                    results_tba: {
                      $elemMatch: {result: {$regex: parseQuery.results_tba}},
                    },
                  },
                  {
                    results_tba_normal: {
                      $elemMatch: {name: {$regex: parseQuery.results_tba}},
                    },
                  },
                  {
                    results_tba_normal: {
                      $elemMatch: {result: {$regex: parseQuery.results_tba}},
                    },
                  },
                ],
              };
            } else {
              query = {
                ...query,
                $or: [
                  {
                    results_tba: {
                      $elemMatch: {
                        user: {$in: targetUsers.map((u) => u.owner)},
                      },
                    },
                  },
                  {
                    results_tba: {
                      $elemMatch: {
                        result: {$regex: params.results_tba},
                      },
                    },
                  },
                  {
                    results_tba_normal: {
                      $elemMatch: {name: parseQuery.results_tba},
                    },
                  },
                  {
                    results_tba_normal: {
                      $elemMatch: {result: {$regex: params.results_tba}},
                    },
                  },
                ],
              };
            }
          }

          pageContext.resource.predefinedQueries = [query];
        } catch (err) {}
      }
    }
  }

  if (path.indexOf('/admin/admin-morales') > -1) {
    const cats = CatUtil.getFlattenCategories('moraleCategories');

    pageContext.resource.querySpec.filters[0].options = [
      ...cats.map((cat) => ({value: cat.name, name: cat.display})),
    ];

    pageContext.resource.formSpec.schema.properties.labels.items = {
      type: 'string',
      enum: [...cats.map((cat) => cat.name)],
      enumNames: [...cats.map((cat) => cat.display)],
    };
  }

  if (path.indexOf('/admin/related-meetings') > -1) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      let query;

      try {
        query = JSON.parse(decodeURIComponent(params.query));
      } catch (err) {}

      pageContext.resource.predefinedQueries = query;
    }
  }

  if (path.indexOf('/admin/related-forms') > -1) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      let query;
      let name;

      try {
        query = JSON.parse(decodeURIComponent(params.query));
      } catch (err) {}

      pageContext.resource.predefinedQueries = query;
    }
  }

  if (path.indexOf('/admin/library') > -1) {
    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button
            style={{margin: 5}}
            onClick={() =>
              AppActions.navigate('/admin/library/?action=create')
            }>
            +
          </Button>
          <Button
            style={{margin: 5}}
            onClick={() => {
              getOutlet('admin-import-excel-modal').update({
                title: '匯入館藏',
                importRecords: async (records) => {
                  if (records.length === 0) {
                    window.alert('匯入新增檔案沒有資料');
                    return;
                  }

                  const FIELDS = {
                    name: '標題',
                    author: '作者',
                    publisher: '出版者',
                    cabinet_number: '櫃號',
                    reel: '卷',
                    period: '期',
                    isbn: 'ISBN',
                    issn: 'ISSN',
                    isrc: 'ISRC',
                    ismn: 'ISMN',
                    gpn: 'GPN',
                  };

                  const REQUIRED = ['name'];

                  const importRecords = records.map((r) => {
                    const output = {};
                    for (const k in FIELDS) {
                      output[k] = r[FIELDS[k]];
                    }
                    return output;
                  });

                  const isNotRequired =
                    importRecords.filter((it) => {
                      for (const k of REQUIRED) {
                        if (!it[k]) {
                          return true;
                        }
                      }
                      return false;
                    }).length > 0;

                  if (isNotRequired) {
                    const display = REQUIRED.map((r) => FIELDS[r]).join();
                    const message = `部分資料格式有誤，動作已暫停！\n${display} 為必填欄位`;
                    message.warning(message);
                    return;
                  }
                  const message = `確定要匯入共 ${importRecords.length} 筆 嗎?`;
                  const isConfirmImport = window.confirm(message);

                  if (isConfirmImport) {
                    AppActions.setLoading(true);
                    try {
                      await AppActionsEx.updateLibraryInfo({
                        is_delete: true,
                        data: importRecords,
                      });

                      window.alert('匯入成功。');
                      window.location.reload();
                    } catch (err) {
                      console.warn(err);
                    }
                    AppActions.setLoading(false);
                  }
                },
              });
            }}>
            匯入
          </Button>
          <Button
            style={{margin: 5}}
            href="/downloads/library-import.xlsx"
            target="_blank"
            download="library-import.xlsx">
            下載匯入模板
          </Button>
        </div>
      );
    };
  }

  if (path.indexOf('/admin/meetings') > -1) {
    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button
            style={{margin: 5}}
            onClick={() =>
              AppActions.navigate('/admin/meetings/?action=create')
            }>
            +
          </Button>
          <AdminMeetingSearchButton />
        </div>
      );
    };

    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);
      if (params.query) {
        try {
          // see "AdminMeetingSearchButton.js" about the query format
          const queryParams = JSON.parse(decodeURIComponent(params.query));
          let effDateQuery = {};

          if (queryParams.effDateStart) {
            effDateQuery = {
              $gte: queryParams.effDateStart,
            };
          }

          if (queryParams.effDateEnd) {
            effDateQuery = {
              ...effDateQuery,
              $lt: queryParams.effDateEnd,
            };
          }

          pageContext.resource.predefinedQueries = [{EFF_DATE: effDateQuery}];
        } catch (err) {}
      }
    }
  }

  if (path.indexOf('/admin/committees') > -1) {
    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Popconfirm
            title="確認複製嗎？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                const nextRecord = {...record};
                delete nextRecord.id;
                delete nextRecord._id;
                await JStorage.createDocument('Committee', {
                  ...record,
                  NAME: record.NAME + '__copy',
                });
                await clientCallbacks.refresh();
                message.success('複製成功');
              } catch (ex) {
                console.warn('copy committee ex', ex);
                message.error('API Fail');
              }
              AppActions.setLoading(false);
            }}>
            <Button style={{margin: 5}}>複製</Button>
          </Popconfirm>
          <Popconfirm
            title="確認刪除嗎？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                await JStorage.deleteDocument('Committee', {
                  id: record.id,
                });
                await clientCallbacks.refresh();
                message.success('刪除成功');
              } catch (ex) {
                console.warn('delete committee ex', ex);
                message.error('API Fail');
              }
              AppActions.setLoading(false);
            }}>
            <Button type="danger" style={{margin: 5}}>
              刪除
            </Button>
          </Popconfirm>
        </div>
      );
    };
  }

  if (
    path.indexOf('/admin/checkout') > -1 ||
    path.indexOf('/admin/order') > -1
  ) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);

      if (params.backPath) {
        pageContext.resource.backPath = params.backPath;
      }
    }
  }

  if (path.indexOf('/admin/users') > -1) {
    if (typeof window !== 'undefined') {
      let params = qs.parse(window.location.search);

      if (params.backPath) {
        pageContext.resource.backPath = params.backPath;
      }
    }
  }
}
