import React from 'react';
import styled from 'styled-components';
import AdminProductUrlCopyButton from './AdminProductUrlCopyButton';
import AdminProductPayDownloadButton from './AdminProductPayPreviewButton';
import AdminProductConsentPreviewButton from './AdminProductConsentPreviewButton';
import {Button} from 'antd';

function AdminProductCustomActions(props) {
  return (
    <Wrapper>
      {props.context.instance && (
        <>
          <AdminProductUrlCopyButton {...props} style={{marginRight: 15}} />
          <AdminProductPayDownloadButton {...props} style={{marginRight: 15}} />
          <AdminProductConsentPreviewButton {...props} />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
`;

export default AdminProductCustomActions;
