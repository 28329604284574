import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

const routePrefix = '/admin/products/';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      sessionDateStart: {
        title: '活動日期(起)',
        type: 'string',
      },
      sessionDateEnd: {
        title: '活動日期(迄)',
        type: 'string',
      },
    },
  },
  uiSchema: {
    sessionDateStart: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    sessionDateEnd: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
  },
};

const defaultQuery = {};

function parseWindowQuery() {
  if (typeof window !== 'undefined') {
    let params = qs.parse(window.location.search);
    let query = defaultQuery;

    try {
      query = JSON.parse(decodeURIComponent(params.query));
    } catch (err) {}

    return query;
  }
}

function setWindowQuery(data) {
  if (!data) {
    AppActions.navigate(`${routePrefix}`);
  } else {
    window.open(
      `${routePrefix}?query=${encodeURIComponent(JSON.stringify(data))}`,
    );
  }
}

export default function MoraleSearchButton(props) {
  const [modal, setModal] = React.useState({
    visible: false,
  });
  const queryParams = parseWindowQuery();

  return (
    <>
      <Ant.Button
        onClick={() => {
          setModal({visible: true});
        }}>
        進階搜尋
      </Ant.Button>
      <QueryFormModal
        data={{visible: modal.visible, data: queryParams}}
        setData={({data, visible}) => {
          if (data !== undefined) {
            setWindowQuery(data);
          }
          setModal({visible});
          AppActions.setLoading(false);
        }}
      />
    </>
  );
}

function getEnumName(field, value) {
  const idx = FormSpec.schema.properties[field].enum.indexOf(value);
  if (Array.isArray(FormSpec.schema.properties[field].enumNames)) {
    return FormSpec.schema.properties[field].enumNames[idx];
  }
  return value;
}

function QueryFormModal(props) {
  const {
    data: {visible},
    setData,
  } = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setData({
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Ant.Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
  } = props;

  return (
    <div style={{padding: 20}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1>查詢參數</h1>
        <Ant.Button
          type="link"
          onClick={() => {
            setData({visible: true, data: null});
          }}>
          全部清除
        </Ant.Button>
      </div>

      <GenericForm
        {...FormSpec}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </div>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
