import React from 'react';
import styled from 'styled-components';
import {Descriptions, Tag} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

export default function AdminProductBasicInfo(props) {
  const {title, id, context} = props;
  const [product, setProduct] = React.useState(null);

  const isSession = product
    ? product.session &&
      Object.keys(product.session).length > 0 &&
      product.session.date
    : context
    ? context.instance
      ? context.instance.session &&
        Object.keys(context.instance.session).length > 0 &&
        context.instance.session.date
      : false
    : false;

  const sessionText = isSession
    ? product
      ? `${product.session.date} ${
          product.session.start_time ? `${product.session.start_time}` : ``
        }`
      : context.instance
      ? `${context.instance.session.date} ${
          context.instance.session.start_time
            ? `${context.instance.session.start_time}`
            : ``
        }`
      : '---'
    : '---';

  const fetchProductById = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      setProduct(
        await JStorage.fetchOneDocument(
          'product',
          {id: id},
          {serial_number: 1, session: 1, name: 1, labels: 1, internal_label: 1},
        ),
      );
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetchProductById(id);
    }
  }, [id, fetchProductById]);

  return (
    <Wrapper>
      <h3 className="title">{title || '基本資料'}</h3>
      {product && (
        <Descriptions bordered size="small" style={{marginBottom: 10}}>
          <Descriptions.Item label="序號">
            {product.serial_number || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="日期時間">{sessionText}</Descriptions.Item>
          <Descriptions.Item label="時數">
            {product.tba_hours || 0} 小時
          </Descriptions.Item>
          <Descriptions.Item label="名稱" span={3}>
            {product.name || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="公會內部分類" span={3}>
            {product.internal_label || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="官網/App分類" span={3}>
            {product.labels?.map((l) => (
              <Tag>{l}</Tag>
            ))}
          </Descriptions.Item>
        </Descriptions>
      )}

      {context && context.instance && (
        <Descriptions bordered size="small" style={{marginBottom: 10}}>
          <Descriptions.Item label="序號">
            {context.instance.serial_number || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="日期時間">{sessionText}</Descriptions.Item>
          <Descriptions.Item label="時數">
            {context.instance.tba_hours} 小時
          </Descriptions.Item>
          <Descriptions.Item label="名稱" span={3}>
            {context.instance.name}
          </Descriptions.Item>
          <Descriptions.Item label="公會內部分類" span={3}>
            {context.instance.internal_label || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="官網/App分類" span={3}>
            {context.instance.labels?.map((l) => (
              <Tag>{l}</Tag>
            ))}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > .title {
    margin-bottom: 20px;
  }
`;
