import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import {message} from 'antd';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';

function AdminFromReview(props) {
  const [user] = useOutlet('user');
  const {context} = props;

  const hasReviewed =
    context.values[context.values.stage]?.findIndex(
      (r) => r.user_id === user.username && r.status !== 'pending',
    ) > -1;

  return hasReviewed ? null : (
    <Wrapper>
      <h3>表單簽核</h3>
      <GenericForm
        instance={context.instance}
        schema={{
          title: '',
          type: 'object',
          required: ['status', 'note'],
          properties: {
            status: {
              type: 'string',
              title: '狀態',
              default: 'pending',
              enum: ['pending', 'success', 'abort'],
              enumNames: ['待簽核', '成功', '退簽'],
              pattern: '^(success|abort)$',
            },
            note: {
              type: 'string',
              title: '簽核內容',
            },
          },
        }}
        uiSchema={{
          status: {
            'ui:widget': 'radio',
          },
          'ui:submitButtonOptions': {submitText: '確認簽核'},
        }}
        onSubmit={async (formData, extValues) => {
          AppActions.setLoading(true);
          try {
            await AppActionsEx.updateFormStatus({
              form_id: context.instance.id,
              status: formData.status,
              note: formData.note,
            });

            message.success('簽核成功');
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
          AppActions.setLoading(false);
          AppActions.navigate(window.location.pathname);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 50px;

  & > .field-item {
    margin-bottom: 24px;
    & > div {
      padding-bottom: 8px;
    }
  }
`;

export default AdminFromReview;
