import React from 'react';
import styled from 'styled-components';
import {List, Collapse} from 'antd';

function AdminUserBillingDiscountItemsList(props) {
  const {path, context} = props;
  const {values} = context;

  return (
    <Wrapper>
      {values.discount_items.map((item) => (
        <div className="container">
          {Array.isArray(item.backend_note) && item.backend_note.length > 0 && (
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel
                key="1"
                header={<h4>常年會費金額試算結果：${values.price}</h4>}>
                <b>{item.name}，算法如下：</b>
                <ul className="list">
                  {item.backend_note.map((note) => (
                    <li>{note}</li>
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          )}

          {!Array.isArray(item.backend_note) && (
            <Collapse>
              <Collapse.Panel
                key="1"
                collapsible="disabled"
                header={
                  <h4>常年會費金額試算結果：${values.price}</h4>
                }></Collapse.Panel>
            </Collapse>
          )}
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  & .title {
    margin-bottom: 20px;
  }

  & .list {
    line-height: 24px;
  }
`;

export default AdminUserBillingDiscountItemsList;
