import React from 'react';
import {Button, message, InputNumber, Input, Select} from 'antd';
import styled from 'styled-components';
import {MoneyDollarCircle} from '@styled-icons/remix-line/MoneyDollarCircle';
import {SettingOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import * as UserUtil from '../../Utils/UserUtil';
import AdminLineDivider from '../../Components/AdminLineDivider';

function AdminPrivateProfileCustom(props) {
  const [privateProfile, setPrivateProfile] = React.useState(null);
  const [seniority, setSeniority] = React.useState(null);
  const [studyHoursRecords, setStudyHoursRecords] = React.useState([]);

  let {context} = props;
  let {instance, values, setValues} = context;

  React.useEffect(() => {
    async function getUserPrivateProfile() {
      let resp = await JStorage.fetchOneDocument('private_profile', {
        owner: instance.owner,
      });

      setPrivateProfile(resp);
    }

    async function getUserSeniority() {
      let resp = await AppActionsEx.getUserSeniority({
        user_id: instance.owner,
      });

      setSeniority(resp.seniority);
    }

    async function getUserStudyHourRecords() {
      const {total, results} = await JStorage.fetchDocuments(
        'study_hours',
        {
          user_id: instance.owner,
          first_year: {
            $in: [
              new Date().getFullYear() - 1911,
              new Date().getFullYear() - 1912,
            ],
          },
        },
        ['-created'],
        {offset: 0, limit: 2},
      );

      setStudyHoursRecords(results);
    }

    try {
      getUserPrivateProfile();
      getUserSeniority();
      getUserStudyHourRecords();
    } catch (ex) {
      console.warn(ex);
    }
  }, [instance.owner]);

  async function editUserPrivateProfile() {
    AppActions.setLoading(true);
    try {
      delete privateProfile.username;

      const prevData = {
        user_type: instance.user_type,
        tba_hours: instance.tba_hours,
        status: instance.status,
        state: instance.state,
        substate: instance.substate,
        hired_time: instance.hired_time,
        points: instance.points,
      };

      const data = {
        user_type: privateProfile.user_type,
        tba_hours: privateProfile.tba_hours,
        status: privateProfile.status,
        state: privateProfile.state,
        substate: privateProfile.substate,
        hired_time: privateProfile.hired_time,
        points: privateProfile.points,
      };

      const isUpdateUserTwbaData = UserUtil.isUpdateUserTwbaData({
        instance: {...prevData},
        formData: {...data},
      });

      await AppActionsEx.updateUserUprPpr({
        owner: instance.owner,
        data,
        is_stamp: isUpdateUserTwbaData[0],
      });

      setValues({...values, ...data});
      AppActions.setLoading(false);
      message.success('儲存成功！');
    } catch (ex) {
      console.warn(ex);
      message.error('發生錯誤！');
      AppActions.setLoading(false);
    }
  }

  if (privateProfile) {
    return (
      <Wrapper>
        <div className="container" id="root_custom-system">
          <AdminLineDivider title="系統資料" />
          <FieldWrapper row>
            <label>
              <SettingOutlined
                color="#868e96"
                size={20}
                style={{marginRight: 5}}
              />
              身分證字號
            </label>
            <Input value={instance.id_card_number} disabled />
          </FieldWrapper>
          <FieldWrapper row>
            <label>
              <SettingOutlined
                color="#868e96"
                size={20}
                style={{marginRight: 5}}
              />
              姓名
            </label>
            <Input value={instance.name} disabled />
          </FieldWrapper>
          <FieldWrapper row>
            <label>狀態</label>
            <Select
              className="select"
              value={privateProfile.state}
              onChange={(value) =>
                setPrivateProfile({...privateProfile, state: value})
              }>
              <Select.Option value={1}>入會</Select.Option>
              <Select.Option value={2}>退會</Select.Option>
              <Select.Option value={3}>其他</Select.Option>
              <Select.Option value={4}>實習律師</Select.Option>
              <Select.Option value={5}>跨區</Select.Option>
              <Select.Option value={6}>停止</Select.Option>
            </Select>
          </FieldWrapper>
          <FieldWrapper row>
            <label>次狀態</label>
            <Select
              className="select"
              value={privateProfile.substate}
              onChange={(value) =>
                setPrivateProfile({...privateProfile, substate: value})
              }>
              <Select.Option value="">無</Select.Option>
              <Select.Option value="1">主區會員</Select.Option>
              <Select.Option value="2">準會員</Select.Option>
              <Select.Option value="3">兼區會員</Select.Option>
              <Select.Option value="4">一般會員</Select.Option>
              <Select.Option value="5">特別會員</Select.Option>
              <Select.Option value="6">外國法事務律師</Select.Option>
              <Select.Option value="7">準特別會員</Select.Option>
            </Select>
          </FieldWrapper>
          <FieldWrapper row>
            <label>類別</label>
            <Select
              className="select"
              value={privateProfile.user_type}
              onChange={(value) =>
                setPrivateProfile({...privateProfile, user_type: value})
              }>
              <Select.Option value="member">律師</Select.Option>
              <Select.Option value="extra_member">其他</Select.Option>
            </Select>
          </FieldWrapper>
          <FieldWrapper row>
            <label>審核狀態</label>
            <Select
              className="select"
              value={privateProfile.status}
              onChange={(value) =>
                setPrivateProfile({...privateProfile, status: value})
              }>
              <Select.Option value="pending_checkout">入會費待繳</Select.Option>
              <Select.Option value="pending_review">入會待審核</Select.Option>
              <Select.Option value="success">入會</Select.Option>
              <Select.Option value="withdraw" disabled>
                退會
              </Select.Option>
            </Select>
          </FieldWrapper>
          <FieldWrapper row>
            <label>累計年資</label>
            <Input value={seniority} disabled={true} />
          </FieldWrapper>

          <div className="action">
            <Button type="primary" onClick={() => editUserPrivateProfile()}>
              儲存
            </Button>
          </div>
        </div>
        <div className="container" id="root_custom-points">
          <AdminLineDivider title="會員時數" />
          <FieldWrapper row css="margin-bottom: 12px;">
            <label>
              <MoneyDollarCircle
                color="#FFD306"
                size={20}
                style={{marginRight: 5}}
              />
              本年度免費時數
            </label>
            <InputNumber
              className="input-number"
              value={privateProfile.points}
              onChange={(value) => {
                setPrivateProfile({...privateProfile, points: value});
              }}
              addonAfter="小時"
            />
            <Button
              className="btn"
              type="primary"
              ghost
              onClick={() => editUserPrivateProfile()}>
              儲存
            </Button>
          </FieldWrapper>
          {studyHoursRecords.map((record, index) => {
            const isLastYear =
              record.first_year === new Date().getFullYear() - 1912;

            return (
              <FieldWrapper key={index} row css="margin-bottom: 12px;">
                <label>
                  <MoneyDollarCircle
                    color="#FFD306"
                    size={20}
                    style={{marginRight: 5}}
                  />
                  {isLastYear ? '去年度' : '今年度'}進修時數
                </label>
                <InputNumber
                  addonBefore={<span>{record.first_year}年度</span>}
                  className="input-number"
                  value={studyHoursRecords[index].hours}
                  onChange={(value) => {
                    const nextValues = [...studyHoursRecords];
                    nextValues[index].hours = value;

                    setStudyHoursRecords(nextValues);
                  }}
                  addonAfter="小時"
                />
                <Button
                  className="btn"
                  type="primary"
                  ghost
                  onClick={async () => {
                    AppActions.setLoading(true);
                    try {
                      await JStorage.updateDocument(
                        'study_hours',
                        {id: record.id},
                        {hours: studyHoursRecords[index].hours},
                      );
                      message.success('修改成功！');
                      AppActions.setLoading(false);
                    } catch (err) {
                      console.warn(err);
                      message.error('發生錯誤！');
                      AppActions.setLoading(false);
                    }
                  }}>
                  修改
                </Button>
              </FieldWrapper>
            );
          })}
        </div>
      </Wrapper>
    );
  }

  return <Wrapper />;
}

const Wrapper = styled.div`
  & > .container {
    margin-top: 20px;

    & .title {
      margin-bottom: 10px;
    }

    & .action {
      display: flex;
      justify-content: flex-end;
    }

    & .btn {
      background-color: #ffffff;
    }
  }
`;

const FieldWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: ${(props) => props.row || 'column'};
  align-items: center;

  & > label {
    min-width: 120px;
    line-height: 40px;
    ${(props) => (props.row ? 'padding-right: 20px;' : 'padding-bottom: 8px;')}
  }

  & > .input-number {
    width: 250px;
    padding-right: 20px;
  }

  & > .input,
  .select {
    flex: 1;
  }

  ${(props) => props.css || ''};
`;

export default AdminPrivateProfileCustom;
