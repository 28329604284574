import React from 'react';
import styled from 'styled-components';
import {Descriptions, Button, Modal} from 'antd';
import {EditOutlined, CloseOutlined} from '@ant-design/icons';
import {showAdminDescriptionsItemModal} from '../../Components/AdminDescriptionItemModal';

function displayValue({record, key, schema}) {
  if (
    Array.isArray(schema.items.properties[key].enumNames) &&
    schema.items.properties[key].enumNames.length > 0
  ) {
    const optionLabels = schema.items.properties[key].enumNames;
    const optionValues = schema.items.properties[key].enum;
    const optionIndex = optionValues.findIndex((v) => v === record[key]);

    return optionLabels[optionIndex];
  }

  if (schema.items.properties[key].show_date) {
    return record[key] ? record[key].split('T')[0] : record[key];
  }

  return record[key];
}

function AdminDescriptionsField(props) {
  const {name, formData = [], schema, uiSchema, onChange} = props;

  return (
    <Wrapper>
      <div className="buttons">
        <Button
          type="primary"
          ghost
          onClick={() =>
            showAdminDescriptionsItemModal({
              is_created: true,
              name,
              record: {},
              index: 0,
              formData,
              schema,
              uiSchema,
              onChange,
            })
          }>
          新增
        </Button>
      </div>
      {schema.type === 'array' &&
        formData.map((record, index) => {
          return (
            <Descriptions
              key={index}
              bordered
              style={{marginBottom: 10}}
              size="small">
              {Object.keys(schema.items.properties).map((key, idx) => {
                return (
                  <Descriptions.Item
                    key={idx}
                    label={schema.items.properties[key].title}>
                    <p>{displayValue({record, key, schema})}</p>
                  </Descriptions.Item>
                );
              })}
              <Descriptions.Item>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  style={{marginRight: 10}}
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => {
                    showAdminDescriptionsItemModal({
                      is_created: false,
                      name,
                      record,
                      index,
                      formData,
                      schema,
                      uiSchema,
                      onChange,
                    });
                  }}
                />
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  className="btn"
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    const nextValues = [...formData];
                    nextValues.splice(index, 1);
                    onChange(nextValues);
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .buttons {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

export default AdminDescriptionsField;
