import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {Button, Modal} from 'antd';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import AdminSelectStaffWidget from './AdminSelectStaffWidget';
import AdminSelectReceiveWidget from './AdminSelectReceiveWidget';

const routePrefix = '/admin/posts/';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      implement: {
        type: 'string',
        title: '擬辦人員',
      },
      receives_related: {
        type: 'array',
        title: '相關收文編號',
        items: {
          type: 'string',
          title: '',
        },
      },
    },
  },
  uiSchema: {
    implement: {
      'ui:widget': 'admin-select-staff-widget',
    },
    receives_related: {
      items: {
        'ui:widget': 'admin-select-receive-widget',
      },
    },
  },
};

const defaultQuery = {};

function parseWindowQuery() {
  if (typeof window !== 'undefined') {
    let params = qs.parse(window.location.search);
    let query = defaultQuery;

    try {
      query = JSON.parse(decodeURIComponent(params.query));
    } catch (err) {}

    return query;
  }
}

function setWindowQuery(data) {
  if (!data) {
    AppActions.navigate(`${routePrefix}`);
  } else {
    window.open(
      `${routePrefix}?query=${encodeURIComponent(JSON.stringify(data))}`,
    );
  }
}

export default function AdminPostSearchButton(props) {
  const [modal, setModal] = React.useState({
    visible: false,
  });
  const queryParams = parseWindowQuery();

  return (
    <>
      <Button
        onClick={() => {
          setModal({visible: true});
        }}>
        進階搜尋
      </Button>
      <QueryFormModal
        data={{visible: modal.visible, data: queryParams}}
        setData={({data, visible}) => {
          if (data !== undefined) {
            setWindowQuery(data);
          }
          setModal({visible});
          AppActions.setLoading(false);
        }}
      />
    </>
  );
}

function QueryFormModal(props) {
  const {
    data: {visible},
    setData,
  } = props;
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={800}
      visible={visible}
      onOk={() => {
        setData({
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-staff-widget': AdminSelectStaffWidget,
      'admin-select-receive-widget': AdminSelectReceiveWidget,
    },
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1>查詢參數</h1>
        <Button
          type="link"
          onClick={() => {
            setData({visible: true, data: null});
          }}>
          全部清除
        </Button>
      </div>

      <GenericForm
        {...FormSpec}
        rjsfProps={rjsfProps}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
