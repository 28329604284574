import React from 'react';
import styled from 'styled-components';
import {Table, Button} from 'antd';

const COLUMNS = [
  {
    key: 'type',
    title: '加項類型',
    dataIndex: 'type',
    width: 90,
    render: (_, record) => {
      return <div>{record.type || '---'}</div>;
    },
  },
  {
    key: 'name',
    title: '加項名稱 / 備註',
    dataIndex: 'name',
    width: 150,
    render: (_, record) => {
      return (
        <div>
          {record.name} {record.note ? `/ ${record.note}` : ''}
        </div>
      );
    },
  },
  {
    key: 'amount',
    title: '加項金額',
    dataIndex: 'amount',
    render: (_, record) => {
      return <span>+ ${record.amount}</span>;
    },
  },
];

function ExtraItemsListSection(props) {
  const {instance} = props.context;

  return (
    <Wrapper>
      <Table
        columns={COLUMNS}
        dataSource={instance.extra_items}
        pagination={false}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default ExtraItemsListSection;
