import React from 'react';
import styled from 'styled-components';
import {useOutlet, getOutlet} from 'reconnect.js';
import {Descriptions, Tag} from 'antd';

function AdminSignFormBasicInfo(props) {
  const {context} = props;
  const {instance, values, setValues} = context;

  return (
    <Wrapper>
      <Descriptions bordered size="small" style={{marginBottom: 10}}>
        <Descriptions.Item label="實體應到">
          {instance.members.length || 0} 人
        </Descriptions.Item>
        <Descriptions.Item label="實體實到">
          {instance.members.filter((m) => m.is_attend).length || 0} 人
        </Descriptions.Item>
        <Descriptions.Item span={3} label="實體時數">
          {instance.is_posted_hours && <Tag color="gold">已發放</Tag>}
          {!instance.is_posted_hours && <Tag color="purple">未發放</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="線上應到">
          {instance.online_members?.length || 0} 人
        </Descriptions.Item>
        <Descriptions.Item label="線上實到">
          {instance.online_members?.filter((m) => m.is_attend).length || 0} 人
        </Descriptions.Item>
        <Descriptions.Item label="線上時數">
          {instance.is_online_posted_hours && <Tag color="gold">已發放</Tag>}
          {!instance.is_online_posted_hours && <Tag color="purple">未發放</Tag>}
        </Descriptions.Item>
      </Descriptions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  & > .title {
    margin-bottom: 20px;
  }
`;

export default AdminSignFormBasicInfo;
