import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchCommitteeModal} from '../../Components/AdminSearchCommitteeModal';
import {JStorage} from 'rev.sdk.js';

export default function AdminSelectCommitteeWidget(props) {
  const [selectedCommittee, setSelectedCommittee] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    const fetchRecordById = async (id) => {
      setFetching(true);

      try {
        const resp = await JStorage.fetchOneDocument('Committee', {
          _id: {$oid: id},
        });
        setSelectedCommittee(resp);
      } catch (err) {
        console.warn(err);
      }

      setFetching(false);
    };

    if (props.value) {
      fetchRecordById(props.value);
    }
  }, [props.value]);

  return (
    <Wrapper>
      <div>
        {!props.value ? (
          '尚未設定團體'
        ) : !selectedCommittee ? (
          props.value
        ) : (
          <Button
            type="link"
            target="_blank"
            title="點選開啟新分頁編輯"
            href={`/admin/committees/?action=detail&id=${selectedCommittee.id}`}>
            {selectedCommittee.NAME} {selectedCommittee.GROUP_CURRENT}
          </Button>
        )}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedCommittee(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchCommitteeModal({
            onChange: (id) => {
              props.onChange(id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
